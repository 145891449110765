import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../queries/authQueries';
import { usePaginationStore } from '../../store/userStore';
import { Empty } from 'antd';
import $ from "jquery";
import moment from 'moment';
import Swiper from 'swiper';
import PaginationComponent from '../Common/PaginationComponent'
import DashboardTileListingModal from '../Common/DashboardTileListingModal';
import { ArrowUpCircle, Ban, BookOpen, CheckCircle, HelpingHandIcon, PenSquare, Plane, PlaneTakeoff, Scroll, Vote, XCircle } from 'lucide-react';
import AssociatesTile from '../Associates/AssociatesTile';
import LeadStatus from '../Common/LeadStatus';
import ProspectStatusList from '../Common/ProspectStatusList';
import ApplicantViewButton from '../Common/ApplicantViewButton';
import { IconBorderAll } from '@tabler/icons-react';
import InputSearch from '../Common/InputSearch';
import AssociatesMyClients from '../Associates/AssociatesMyClients';

const MarketingAssociateDashboard = () => {
    
    const [result, setResult] = useState([]);
    const [fYear, setFYear] = useState("CURRENT")
    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const associatesCount = authQueries.useAssoiciatesDashboardMutation(
        (response) => {
        }
    )

    useEffect(()=> {
        associatesCount.mutateAsync({fyear: fYear})
    },[fYear])

	const slideArr = [
        {
			color: '#AD88C6',
			title: 'Total Active Clients for all Intakes',
			value: "active",
            icon: <Vote color='#AD88C6' size="20" />,
		},
        {
			color: '#88AB8E',
			title: `Registered for the ${fYear === "CURRENT" ? "Current FY" : fYear === "PREVIOUS" ? "Previous FY" : fYear === "FUTURE" ? "Future FY" : "Custom Date"}`,
			value: "registered",
            fy: true,
            icon: <CheckCircle color='#88AB8E' size="20" />,
		},        
        {
			color: '#78C1F3',
			title: `Preparing Visa for the  ${fYear === "CURRENT" ? "Current FY" : fYear === "PREVIOUS" ? "Previous FY" : fYear === "FUTURE" ? "Future FY" : "Custom Date"}`,
			value: "prepare",
            fy: true,
            icon: <BookOpen color="#78C1F3" size="20" />,
		},
        {
			color: '#FF6666',
			title: `Visa Applied for the  ${fYear === "CURRENT" ? "Current FY" : fYear === "PREVIOUS" ? "Previous FY" : fYear === "FUTURE" ? "Future FY" : "Custom Date"}`,
			value: "visaapplied",
            fy: true,
            icon: <Plane color='#FF6666' size="20" />,
		},
        {
			color: '#B3A492',
			title: `Visa Approved for the ${fYear === "CURRENT" ? "Current FY" : fYear === "PREVIOUS" ? "Previous FY" : fYear === "FUTURE" ? "Future FY" : "Custom Date"}`,
			value: "visaapproved",
            fy: true,
            icon: <PlaneTakeoff color='#B3A492' size="20" />,
		},
        {
			color: '#D6CC99',
			title: `Expected Visa for the ${fYear === "CURRENT" ? "Current FY" : fYear === "PREVIOUS" ? "Previous FY" : fYear === "FUTURE" ? "Future FY" : "Custom Date"}`,
			value: "expectvisa",
            fy: true,
            icon: <ArrowUpCircle color='#D6CC99' size="20" />,
		},
	];


    const slideArr2 = [
        {
			color: '#FAA300',
			title: 'Total Clients',
			value: "totalClient",
            icon: <IconBorderAll color='#FAA300' size="20" />,
		},
        {
			color: '#7071E8',
			title: 'Not Interested',
			value: "notInterested",
            icon: <Ban color='#7071E8' size="20" />,
		},
        {
			color: '#9ADE7B',
			title: 'Not Qualified',
			value: "notQualified",
            icon: <XCircle color='#9ADE7B' size="20" />,
		},
        {
			color: '#50727B',
			title: 'On Hold',
			value: "onHold",
            icon: <HelpingHandIcon color='#50727B' size="20" />,
		},
	];


	const slider = () => {

		new Swiper(".geebee-carousel.followup-pending", {
			slidesPerView: 5,
			spaceBetween: 10,
			speed: 700,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
                "1": {
					slidesPerView: 1,
					spaceBetween: 10,
                },
				'480': {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				'640': {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				'920': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
				'1200': {
					slidesPerView: 6,
					spaceBetween: 10,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next.carousel-next",
				prevEl: ".swiper-button-prev.carousel-prev",
			},
		});

	}

    useEffect(() => {
		slider();
		$('html,body').animate({ scrollTop: 0 }, 'fast');
	}, []);

  return (
    <div>
        <AssociatesTile fYear={fYear} setFYear={setFYear} slideArr={slideArr} slideArr2={slideArr2} data={associatesCount?.data} />
        <hr className="my-4" />
        <AssociatesMyClients />
    </div>
  )
}

export default MarketingAssociateDashboard