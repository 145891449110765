import { Checkbox, Loading, Modal, Popover } from '@nextui-org/react'
import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../queries/authQueries'
import moment from 'moment'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { Autocomplete, TextField } from '@mui/material'
import { userStore } from '../../store/userStore'
import { Button, Empty, Popconfirm } from 'antd'
import { decode } from 'html-entities'
import { useApi } from './ApiProvider'
import LeadStatus from './LeadStatus'
import { IconDeviceFloppy, IconMessage, IconMessagePlus } from '@tabler/icons-react'
import VisaStaffStatusOptions from './VisaStaffStatusOptions'


const FollowUp = ({ item }) => {

    const store = userStore()
    const {setApiDataAndNotify} = useApi()

    const validationRules = yup.object().shape({
		date: yup
			.string('⚠ Please Select Date')
			.required('⚠ Date is required'),
	});

	const commentOnlyValid = yup.object().shape({
		comment: yup
			.string('⚠ Please enter comments')
			.required('⚠ comments is required'),
	});

    const prospectStatusValid = yup.object().shape({
		prospect: yup
			.string('⚠ Please Select Prospect Status')
			.required('⚠ Prospect Status is required'),
	});

    const [visible, setVisible] = useState(false)
    const [applicant, setApplicant] = useState({})
    const [loader, setLoader] = useState(true)
    const [activeFollow, setActiveFollow] = useState(null)
	const [follow, setFollow] = useState("FOLLOW")
    const [today, setToday] = useState(moment().format('YYYY-MM-DD'));
    const [followApp, setFollowApp] = useState([])
    const [followHistory, setFollowHistory] = useState([])
    const [status, setStatus] = useState([])
    const [followupComment, setFollowupComment] = useState("")
    const [users, setUsers] = useState([])
    const [assignee, setAssignee] = useState({
		value: "",
		label: ""
	})
    const [showStatus, setShowStatus] = useState(false)
    const [followDone, setFollowDone] = useState(false)

    const followList = authQueries.useAppointClientList(
		async (response) => {
			setFollowApp(response)
		}
	)

    const followHistoryList = authQueries.useFollowupHistoryList(
		async (response) => {
			setFollowHistory(response)
            setLoader(false)
		}
	)

    const listStatus = authQueries.useStatusOfProspectsRoleList(
        (response) => {
            setStatus(response)
            setLoader(false)
        }
    );

    const handleOpen =()=>{
        setVisible(true)
        setApplicant(item)
        userList.mutateAsync(item?.id)
        followList.mutateAsync(item?.id)
        followHistoryList.mutateAsync(item?.id)
        listStatus.mutateAsync({role: store?.user?.rid, visa: item?.visa_type_id, showstatus: item?.registered_status})
    }

    const {register, handleSubmit, reset, formState: { errors }} = useForm({
		resolver: yupResolver(follow === "FOLLOW" ? validationRules : follow === "GENERAL" ? commentOnlyValid : prospectStatusValid)
	})

    const handleClose =()=> {
		setVisible(false)
		setActiveFollow(null)
        setFollow("FOLLOW")
        setShowStatus(false)
        if(followDone){
            setApiDataAndNotify(followApp)
            setFollowDone(false)
        }
	}

    const handleChange = (event, newValue) => {
		if(newValue){
			setAssignee(newValue)
		}
	}
    
    const followEnable =(value)=> {
		setFollow(value)
	}

    const onSubmit =(data) => {
		const datavalues = {
			applicant_id: applicant?.id,
			assign: assignee?.value ? assignee?.value : store?.user?.id,
			comments: data.comment,
			type: follow === "FOLLOW" ? "FOLLOW-UP" : "GENERAL",
			followupdate: follow === "FOLLOW" ? data.date + " " + data?.time  : null,
            date_enable: data?.time ? "YES" : "NO",
			status: "ACTIVE"
		}
		createFollow.mutateAsync(datavalues);
	}

    const onSubmitProspect =(data) => {
        const datavalues = {
            applicant_id: applicant?.id,
            prospects_id: data.prospect,
            comments: data.comment,
            status: "ACTIVE"
        }
        addProspect.mutateAsync(datavalues);
    }

    const addProspect = authQueries.useProStatusAdd(
        (response) => {
            const dataValues = {
                prospect: '',
                comment: ''
            }
            reset(dataValues)
            setApiDataAndNotify(response)
            followHistoryList.mutateAsync(item?.id)
        }
    );

    const userList = authQueries.useUserFollowMutationList(
        (res) => {
            let dataArr = []
            res?.data?.forEach(element => {
                const data = {
                    value: element?.id,
                    label: element?.userdetail?.firstname + " " + element?.userdetail?.lastname
                }
                dataArr.push(data)
                if(element.id === store.user.id){
                    setAssignee({label: element?.userdetail?.firstname + " " + element?.userdetail?.lastname, value: element.id })
                }
            });
            setUsers(dataArr)
        }
    )

    const createFollow = authQueries.useAppointClientAdd(
		async (response) => {
            setApiDataAndNotify(response)
			followList.mutateAsync(applicant?.id)
			followHistoryList.mutateAsync(applicant?.id)
			reset({
				comment: "",
				date: "",
				time: ""
			})
		}
	)

    const createComment = authQueries.useFollowupCommentCreateMutation(
		(res)=>{
			setFollowupComment("")
			setActiveFollow(null)
			followList.mutateAsync(applicant?.id)
            followHistoryList.mutateAsync(applicant?.id)
		}
	)

    const commentCreate =(id)=> {
		const dataValues = {
			fid: id,
			comments: followupComment
		}
		createComment.mutateAsync(dataValues)
	}   
    
	const confirmCheck = authQueries.useCounselorDashboardConfirmMutation(
		(res)=> {
            setFollowDone(true)
			followList.mutateAsync(applicant?.id)
            followHistoryList.mutateAsync(applicant?.id)
		}
	)
    
    const commentCreateAndClose =async(id)=> {
		const dataValues = {
			fid: id,
			comments: followupComment
		}
		await createComment.mutateAsync(dataValues)
        confirmCheck.mutateAsync({id: id})
	}

    const listProspect = authQueries.useProStatusList(
        (response) => {
        }
    );

    const handleChangeStatus =(e)=>{
        setShowStatus(!showStatus)
        if(e){
            listProspect.mutateAsync(applicant?.id)
        }
    }

  return (
    <div>
        <button onClick={handleOpen} className='btn-customized'>Followup/comments</button>
        <Modal
        open={visible}
        closeButton={false}
        onClose={handleClose}
        className="followup-modal"
        centered
        blur
        animationDuration={1000}
        overlayId="modal-overlay"
        fullScreen
        scroll
        >
            <Modal.Header>
                <h5 className="modal-title">Follow Up / Comments - {applicant?.applicant_name} {applicant?.middlename} {applicant?.lastname}</h5>
                {
                    showStatus ? 
                    <div style={{fontSize:'16px', cursor:'pointer', color:'#00f'}} onClick={()=> handleChangeStatus(false)}>&nbsp; ( {applicant?.status_of_prospect?.name} )</div>:
                    <div style={{fontSize:'16px', cursor:'pointer', color:'#00f'}} onClick={()=> handleChangeStatus(true)}>&nbsp; ( {applicant?.status_of_prospect?.name} )</div>
                }
                <button onClick={handleClose} className='absolute right-6'>Close</button>
            </Modal.Header>
            <Modal.Body>
                <Fragment>
                    {
                        showStatus ?
                        <Fragment>
                            <div className="modal-body">
                                <div className='mb-4'>
                                    <div onClick={()=> {setShowStatus(false)}} style={{display:'flex', alignItems:'center', fontSize:'16px', gap:4, cursor:'pointer'}}><i className='icon-keyboard_arrow_left' style={{fontSize:'22px'}}></i> Back</div>
                                </div>
                                <div className="table-wrpr">
                                    <div className="table-section">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table prospect-table">
                                                    <thead>
                                                    <tr>
                                                        <th width="8%">Date</th>
                                                        <th width="20%">Status</th>
                                                        <th width="62%">Comment</th>
                                                        <th width="10%">Commented By</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody >
                                                    {
                                                        listProspect?.data?.data?.map((prostatus, index) => (
                                                        <tr className="ui-sortable-handle" key={index}>
                                                            <td>{moment(prostatus?.createdAt).format("DD-MM-YYYY")}</td>
                                                            <td>{decode(prostatus?.status_of_prospect?.name)}</td>
                                                            <td>{prostatus?.comments}</td>
                                                            <td>{prostatus?.createdBy?.userdetail?.firstname} {prostatus?.createdBy?.userdetail?.lastname}</td>
                                                        </tr>
                                                        ))
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                        :
                        <Fragment>
                            {
                                loader ?
                                <div style={{display:'flex', justifyContent:'center', flexDirection:'column', height:'100%'}}>
                                    <Loading size='lg' />
                                    <span style={{marginTop:'10px', textAlign:'center', fontSize:'12px', color:'#888'}}>Loading...</span>
                                </div>
                                :
                                <div className="grid grid-cols-12 gap-4">
                                    <div className="col-span-3 max-[1500px]:col-span-4 max-[916px]:col-span-12">
                                        <div className='add-general-comment-wrpr card'>
                                            <div className="card-body">
                                                <div className='bg-white text-[12px] grid grid-cols-12 max-[1065px]:grid-cols-4 max-[916px]:grid-cols-12 p-[5px] rounded-md text-center mb-3 shadow-md border border-slate-100' >
                                                    <div
                                                    onClick={()=>followEnable("FOLLOW")}
                                                    className={`col-span-4 ${follow === "FOLLOW" && 'custom-color text-white'} p-[7px] rounded-lg`}
                                                    >Followup</div>
                                                    <div
                                                    onClick={()=>followEnable("GENERAL")}
                                                    className={`col-span-4 ${follow === "GENERAL" && 'custom-color text-white'} p-[7px] rounded-lg`}
                                                    >General</div>
                                                    <div
                                                    onClick={()=>followEnable("PROSPECT")}
                                                    className={`col-span-4 ${follow === "PROSPECT" && 'custom-color text-white'} p-[7px] rounded-lg`}
                                                    >Status</div>
                                                </div>
                                                <form onSubmit={handleSubmit(follow === "PROSPECT" ? onSubmitProspect:onSubmit)}>
                                                    <div className="row setfollowup">
                                                        {
                                                            follow === "FOLLOW" &&
                                                            <Fragment>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label for="">Assigned to:</label><br />
                                                                        <Autocomplete
                                                                            disablePortal
                                                                            id="combo-box-demo"
                                                                            getOptionLabel={(option) => option.label}
                                                                            onChange={handleChange}
                                                                            value={assignee}
                                                                            options={users}
                                                                            sx={{ width: 300 }}
                                                                            renderInput={(params) =>
                                                                                <TextField
                                                                                    className='auto-follow'
                                                                                    {...params}
                                                                                    label=""
                                                                                    sx={{
                                                                                        '& legend': { display: 'none' },
                                                                                        '& fieldset': { top: 0 },
                                                                                    }}
                                                                                    variant="outlined"
                                                                                />
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label for="">Date:</label><br />
                                                                        <input 
                                                                        {...register('date')} 
                                                                        min={today}
                                                                        name="date" 
                                                                        id="arrival_date3" 
                                                                        type="date" 
                                                                        className="form-control applicant-date" 
                                                                        />
                                                                        <div className="mt-2" style={{color:'#f00', fontSize:"14px"}}>{errors.date?.message}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label for="">Time:</label><br />
                                                                        <input 
                                                                        {...register('time')} 
                                                                        name="time" 
                                                                        id="arrival_date3" 
                                                                        type="time" 
                                                                        className="form-control applicant-date" 
                                                                        />
                                                                        <div className="mt-2" style={{color:'#f00', fontSize:"14px"}}>{errors.time?.message}</div>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        }
                                                        {
                                                            follow === "PROSPECT" &&
                                                            <div className="col-md-12">
                                                                <div className="flex flex-col gap-1">
                                                                    <div className='text-[12px] h-4 font-[500]'>Prospect Status</div>
                                                                        <select 
                                                                        {...register('prospect')} 
                                                                        name="prospect" 
                                                                        className="bg-white mb-0 border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
                                                                        >
                                                                        <option value="">Select Status</option>
                                                                        {store?.user?.role !== "VISASTAFF" ?
                                                                            (status?.data?.map((pros, index) => {
                                                                                if(pros?.id === 2 && item?.registered_status === "YES" && store?.user?.role === "COUNSELOR"){
                                                                                    return
                                                                                }
                                                                                return(
                                                                                    <option value={pros?.id} key={index}>{decode(pros?.name)}</option>
                                                                                )
                                                                            })):
                                                                            <VisaStaffStatusOptions status={status?.data} current={item?.pros_status} />
                                                                        }
                                                                        </select>
                                                                    <div style={{color:'#f00', fontSize:"13px"}} className='mb-2'>{errors?.prospect?.message}</div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="col-md-12">
                                                            <textarea {...register('comment')} name="comment" className="form-control" placeholder="Enter comments" style={{ minHeight: "160px" }}></textarea>
                                                            <div className="mt-1 mb-2" style={{color:'#f00', fontSize:"14px"}}>{errors.comment?.message}</div>
                                                        </div>
                                                        <div className="col-md-12">
                                                        {
                                                            (follow === "PROSPECT" && store?.user?.role === 'COUNSELOR' && item?.assignUser?.counselor !== store?.user?.id) ?
                                                            <button type="button" disabled className='btn-customized-large opacity-70 cursor-not-allowed'>Add Prospect Status</button>:
                                                            <button 
                                                            type="submit" 
                                                            className="btn-customized-large w-full gap-2">
                                                                <IconMessagePlus size="16" />
                                                                {
                                                                    follow === "GENERAL" ?
                                                                    "Add General Comment" : 
                                                                    follow === "FOLLOW" ? 
                                                                    "Add Followup":
                                                                    "Add Prospect Status"
                                                                }
                                                            </button>
                                                        }
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className='add-general-comment-wrpr card mt-4'>
                                            <div className="card-body">
                                                <div style={{display:'flex', flexDirection:'column', gap:10}}>
                                                    <div style={{display:'flex', fontSize:'12px', justifyContent:'flex-start', marginBottom: '10px'}}>
                                                        <div style={{fontWeight: 700, fontSize:'14px', textDecoration:'underline'}}>Applicant Details</div>
                                                    </div>
                                                    <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                        <div>Profile Status</div>
                                                        <LeadStatus item={applicant} />											
                                                    </div>
                                                    <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                        <div>Applicant Name</div>
                                                        <div style={{fontWeight: 700}}>{applicant?.applicant_name} {applicant?.middlename} {applicant?.lastname}</div>
                                                    </div>
                                                    <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                        <div>Email</div>
                                                        <div style={{fontWeight: 700}}>{applicant?.email}</div>
                                                    </div>
                                                    <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                        <div>Mobile</div>
                                                        <div style={{fontWeight: 700}}>{applicant?.mobile_no}</div>
                                                    </div>
                                                    <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                        <div>Whatsapp</div>
                                                        <div style={{fontWeight: 700}}>{applicant?.whatsapp_no}</div>
                                                    </div>
                                                    <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                        <div>Landline</div>
                                                        <div style={{fontWeight: 700}}>{applicant?.landline_no}</div>
                                                    </div>
                                                    <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                        <div>Visa Type</div>
                                                        <div style={{fontWeight: 700}}>{applicant?.visatype?.description}</div>
                                                    </div>
                                                    <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                        <div>Office</div>
                                                        <div style={{fontWeight: 700}}>{applicant?.office?.name}</div>
                                                    </div>
                                                    <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                        <div>Status</div>
                                                        <div style={{fontWeight: 700}}>{applicant?.status_of_prospect?.name}</div>
                                                    </div>
                                                    {applicant?.registered_status === "YES" &&
                                                        <div style={{display:'flex', fontSize:'12px', justifyContent:'space-between'}}>
                                                            <div>Registered Date</div>
                                                            <div style={{fontWeight: 700}}>{applicant?.registered_date && moment(applicant?.registered_date).format('DD-MM-YYYY') }</div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-span-9 max-[1500px]:col-span-8 max-[916px]:col-span-12">
                                        <Fragment>
                                        {
                                            (followApp?.data?.length > 0 || followHistory?.data?.length > 0) ?
                                                <div>
                                                    {followApp?.data?.map((item, index) => {
                                                        if(item?.type === "FOLLOW-UP" && item?.followStatus === "PENDING")
                                                        return(
                                                            <div className="card followup-card" key={index}>
                                                                <div className="card-body text-[12px]">

                                                                    <div className="grid grid-cols-10 max-[1382px]:grid-cols-8 max-[972px]:grid-cols-6 max-[558px]:grid-cols-4 max-[404px]:grid-cols-2 max-[404px]:text-center gap-2">
                                                                        <div className="col-span-2">
                                                                            <label>Created Date</label>
                                                                            <div className='font-semibold'>
                                                                                {item?.createdAt && moment(item?.createdAt).format('DD-MM-YYYY')} &nbsp;
                                                                                {item?.createdAt && moment(item?.createdAt).format('hh:mm A')}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-span-2">
                                                                            <label>Followup Date</label>
                                                                            <div className='font-semibold'>
                                                                                {item?.followupdate && moment(item?.followupdate).format('DD-MM-YYYY')} &nbsp;
                                                                                {(item?.followupdate && item?.date_enable === "YES") && moment(item?.followupdate).format('hh:mm A')}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-span-2">
                                                                            <label>Type</label>
                                                                            <div className='font-semibold'>{item?.type}</div>
                                                                        </div>
                                                                        <div className="col-span-2">
                                                                            <label>Assign to</label>
                                                                            <div className='font-semibold'>
                                                                                {item?.assignId?.userdetail?.firstname} {item?.assignId?.userdetail?.lastname}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-span-2">
                                                                            <label>Created By</label>
                                                                            <div className='font-semibold'>{item?.createdBy?.userdetail?.firstname} {item?.createdBy?.userdetail?.lastname}</div>
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        activeFollow === item.id && 
                                                                        <div className="flex flex-col items-end mt-4 gap-1">
                                                                            <div className='w-full'>
                                                                                <textarea
                                                                                value={followupComment}
                                                                                onChange={(e)=> {setFollowupComment(e.target.value)}}
                                                                                className="bg-white border border-solid border-gray-300 rounded-md w-full h-10 p-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 font-poppins min-h-[100px]" 
                                                                                />
                                                                                <div className='text-[10px] italic text-[#f00]'>Comment minimum 10 characters needed</div>
                                                                            </div>
                                                                            <div className='flex gap-2'>
                                                                                <button
                                                                                disabled={followupComment?.length < 10 ? true:false} 
                                                                                style={{opacity: followupComment?.length < 10 ? 0.7:1}}
                                                                                onClick={()=> {commentCreate(item?.id)}}  
                                                                                className="flex items-center gap-1 btn-customized-large h-8 w-[100px]">
                                                                                <IconDeviceFloppy size="16" />
                                                                                Save</button> 
                                                                                <button
                                                                                disabled={followupComment?.length < 10 ? true:false} 
                                                                                style={{opacity: followupComment?.length < 10 ? 0.7:1}}
                                                                                onClick={()=> {commentCreateAndClose(item?.id)}}  
                                                                                className="flex items-center gap-1 btn-customized-large h-8 w-[180px]">
                                                                                <IconDeviceFloppy size="16" />
                                                                                Save & Close Comment</button>
                                                                            </div>
                                                                        </div>
                                                                    }


                                                                    {
                                                                        activeFollow !== item.id && 
                                                                        <div className='flex justify-between mt-4'>
                                                                            <div className='font-semibold'>
                                                                                {
                                                                                    (item?.assignid === store?.user?.id && item?.followupComments?.find((f)=> f.primary === "NO")) ?
                                                                                    <ConfirmCheck data={item} setFollowApp={setFollowApp} setFollowDone={setFollowDone} setFollowHistory={setFollowHistory} /> :
                                                                                    <div>
                                                                                        <Checkbox aria-label='' isDisabled size="lg">
                                                                                            <div className='text-[12px] font-normal'>
                                                                                                Followup Done
                                                                                            </div>
                                                                                        </Checkbox>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                <button 
                                                                                disabled={item?.assignid !== store?.user?.id}
                                                                                style={{cursor: item?.assignid !== store?.user?.id && 'not-allowed', opacity:item?.assignid !== store?.user?.id && 0.7}}
                                                                                onClick={()=>setActiveFollow(item.id)} 
                                                                                className="btn-customized-large w-[130px] gap-2 h-8">
                                                                                    <IconMessage size="16" /> 
                                                                                    Add Comment
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    }


                                                                    {item?.followupComments?.length > 0 &&
                                                                        <div className='mt-3'>
                                                                            <div className="table-wrpr mb-0">
                                                                                <div className="table-section">
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <table className="table prospect-table">
                                                                                                <thead>
                                                                                                    <tr className="ui-sortable-handle follow-comment">
                                                                                                        <th width="200">Commented Date</th>
                                                                                                        <th>Comment</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody style={{background:'#fff'}} className="ui-sortable">
                                                                                                    {item?.followupComments?.map((items, indexes) => (
                                                                                                        <tr key={index} className={`ui-sortable-handle ${(items?.primary === "YES" && item.type === "FOLLOW-UP") && "general-comment"} follow-comment`}>
                                                                                                            <th className="">{moment(items?.createdAt).format('DD-MM-YYYY hh:mm A')}</th>
                                                                                                            <td>{items?.comments}</td>
                                                                                                        </tr>
                                                                                                    ))}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div> :
                                                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                    <Empty />
                                                </div>
                                        }
                                        </Fragment>
                                        
                                        <div className='mt-4'>
                                            <FollowupHistory data={followHistory?.data} />
                                        </div>
                                    </div>

                                </div>
                            }
                        </Fragment>
                    }
                </Fragment>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    </div>
  )
}

export default FollowUp



const FollowupHistory =({data})=> {
	return(
		<Fragment>
			{data?.length > 0 &&
			<Fragment>
                <div className='mb-3 flex justify-between max-[1331px]:flex-col items-center'>
                    <div>Comment history</div>
                    <div className='text-[12px] flex max-[1151px]:flex-col max-[1151px]:w-full max-[910px]:flex-row gap-2'>
                        <div className='flex gap-1'><div className='w-4 h-4 rounded-md bg-[#BBE2EC]'/>Prospect Comment</div>
                        <div className='flex gap-1'><div className='w-4 h-4 rounded-md bg-[#FFB0B0]'/>Followup Create Comment</div>
                        <div className='flex gap-1'><div className='w-4 h-4 rounded-md bg-[#F3CCF3]'/>Followup Comments</div>
                        <div className='flex gap-1'><div className='w-4 h-4 rounded-md bg-[#F2F1EB]'/>General Comment</div>
                    </div>
                </div>
                <div className="table-wrpr">
                    <div className="table-section">
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table prospect-table">
                                    <thead>
                                        <tr className="ui-sortable-handle follow-comment">
                                            <th width="12%">Commented Date</th>
                                            <th width="10%">Followup Date</th>
                                            <th width="8%">Type</th>
                                            <th width="10%">Assign to</th>
                                            <th width="10%">Created By</th>
                                            <th width="13%">Status</th>
                                            <th width="37%">Comment</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{background:'#fff'}} className="ui-sortable">
                                        {data?.map((item, index) => (
                                            <tr key={index} className="ui-sortable-handle"
                                            style={{
                                            backgroundColor: item?.type === "PROSPECT" ? "#BBE2EC" :
                                            (item?.type === "FOLLOW-UP" && item?.primary === "YES") ? "#FFB0B0" :
                                            (item?.type === "FOLLOW-UP" && item?.primary === "NO") ? "#F3CCF3" :
                                            "#F2F1EB"
                                            }}>
                                                <td>
                                                    {moment(item?.createdAt).format('DD-MM-YYYY')}
                                                    <div>{moment(item?.createdAt).format('hh:mm A')}</div>
                                                </td>
                                                <td>
                                                    {item?.followupdate && moment(item?.followupdate).format('DD-MM-YYYY')}
                                                    {
                                                        item?.date_enable === "YES" &&
                                                        <div>{item?.followupdate && moment(item?.followupdate).format('hh:mm A')}</div>
                                                    }
                                                </td>
                                                <td>{item?.type}</td>
                                                <td>{item?.assign?.userdetail?.firstname} {item?.assign?.userdetail?.lastname}</td>
                                                <td>{item?.commentedBy?.userdetail?.firstname} {item?.commentedBy?.userdetail?.lastname}</td>
                                                <td>{item?.status?.name}</td>
                                                <td>{item?.comment}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
			</Fragment>
			}
		</Fragment>
	)
}


const ConfirmCheck = ({data, setFollowApp, setFollowDone, setFollowHistory}) => {

	const [checked, setChecked] = useState(false)

	useEffect(()=>{
		setChecked(data?.followStatus === "DONE" ? true : false)
	},[])

	const handleChange = (e) => {
		confirmCheck.mutateAsync({id: data?.id})
		setChecked(!checked)
	}

	const confirmCheck = authQueries.useCounselorDashboardConfirmMutation(
		(res)=> {
            setFollowDone(true)
			followList.mutateAsync(data.applicant_id)
            followHistoryList.mutateAsync(data.applicant_id)
		}
	)

	const followList = authQueries.useAppointClientList(
		async (response) => {
			setFollowApp(response)
		}
	)

    const followHistoryList = authQueries.useFollowupHistoryList(
		async (response) => {
			setFollowHistory(response)
		}
	)

	return(
		<div>
			<Checkbox aria-label='' onChange={handleChange} isSelected={checked} size="lg">
                <div className='text-[12px] font-normal'>
                    Followup Done
                </div>
            </Checkbox>
		</div>
	)
}
