import { yupResolver } from '@hookform/resolvers/yup'
import React,{useState, useEffect, Fragment} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import authQueries from '../../queries/authQueries'
import * as yup from 'yup'
import { message as messages } from 'antd'
import { Checkbox, Loading, Switch } from '@nextui-org/react'
import { userStore } from '../../store/userStore'
import moment from 'moment'
import {AutoCompleteAntd, AutoCompleteAntdOffice, AutoCompleteChild, AutoCompleteChilds, AutoCompleteIntake}  from '../Clients/AddClients/AutoComplete'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import LoaderCommon from '../Common/LoaderCommon'
import { IconBackspace } from '@tabler/icons-react'
import { IconDeviceFloppy } from '@tabler/icons-react'


const AssociatesForm = ({lead_status}) => {

    const appStore = userStore();

    const [resultVisa, setResultVisa] = useState([]);
    const [intakeResult, setIntakeResult] = useState([]);

    const [name, setName] = useState('');
    const [mName, setMName] = useState('')
    const [lName, setLName] = useState('')
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [visaid, setVisaId] = useState(null);
    const [image, setImage] = useState(appStore?.appAllData?.photo)
    const [loader, setLoader] = useState(true)
    const [emailExist, setEmailExist] = useState('')
    const [mobileExist, setMobileExist] = useState('')

    const [visaData, setVisaData] = useState('');
    const [intakeData, setIntakeData] = useState('');
    const [officeData, setOfficeData] = useState('');
    const [visaidErr, setVisaIdErr] = useState(false)

    const visaType = authQueries.useVisaTypeMutationList(
        (response) => {
            setVisaData(appStore?.appAllData?.visa_type_id)
            let dataArray = []
            for(let datas of response?.data?.items){
                const dataValues = {
                    label:datas?.description,
                    value:datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }
            setResultVisa(dataArray)
        }
    );


    const inTake = authQueries.useIntakeVisaMutationList(
        (response) => {
            let data = response?.data?.find(v => v.id === appStore?.appAllData?.intake_id)
            if(data){
                setIntakeData(data?.id) 
                setValue('intake_id', data?.id)
            }else{
                for(let datas of response?.data){
    
                    if(datas?.active_status === "ACTIVE"){
                        setIntakeData(datas?.id) 
                        setValue('intake_id', datas?.id)
                    }
    
                }
            }
            let dataArray = []
            for(let datas of response?.data){
                const dataValues = {
                    label:datas?.intake,
                    value:datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }
            setIntakeResult(dataArray)
        }

    );


    const refferalView = authQueries.useRefferalMutationView(
        (response) => {
            setValue('referral_id', appStore?.user?.refid)
        }
    );

    const counselorList = authQueries.useOfficeCounselorAssociatesMutation(
        (response) => {
            setLoader(false)
        }   
    );

    const masterCall = async ()=> {
        await reset({...appStore?.applicant?.appAllData})
        await refferalView.mutateAsync(appStore?.user?.refid)
        await visaType.mutateAsync({search: '', status: '', page:0, size:500});
        counselorList.mutateAsync({search:'', role:3, ref: appStore?.user?.refid})
    }

    useEffect(()=>{  
        masterCall();
    },[])

    useEffect(()=> {
        inTake.mutateAsync(visaid ? visaid : appStore.appAllData.visa_type_id);
    }, [visaid])


    const applicantSchema = yup.object({
        visa_type_id: yup
            .string('⚠ Please enter visa type')
            .required('⚠ Visa type is required'),
        intake_id: yup
            .string('⚠ Please enter intake')
            .required('⚠ Intake is required'),
        applicant_name: yup
            .string('⚠ Please enter applicant firstname')
            .required('⚠ Applicant firstname is required'),     
        assign: yup
            .string('⚠ Please select counselor')
            .required('⚠ Counselor is required'),
    });
      
    const navigate = useNavigate();

    const { register, reset, control, handleSubmit, formState:{ errors }, setValue, getValues, setError} = useForm({
         resolver: yupResolver(applicantSchema)
    });

    const addApplication = authQueries.useApplicantMutationAdd(
        (response) => {
            if(response?.message === "Success"){ 
                navigate('/dashboard')
                messages.success("successfully created")
            }
        }   
    );

    const onSubmit = (data) => {

        if(visaid === null){
            setVisaIdErr(true)
        }else{
            setVisaIdErr(false)
        }

        data.lead_status = lead_status
        data.photo = image
        addApplication.mutateAsync(data);
    }


    const editApplication = authQueries.useApplicantMutationEdit(
        (response) => {
            if(response?.message === 'Error'){
                const mobError = response?.error?.find(item => item.mobile_no)
                if(mobError){
                    console.log(mobError?.mobile_no)
                    setError('mobile_no', {message: mobError?.mobile_no})
                }
            }
            if(response?.message === "Success"){ 
                appStore.setApplication(null)
                navigate('/dashboard')
            }
        }   
    );

    const onEdit = (data) => {  
        data.lead_status = lead_status
        data.photo = image
        editApplication.mutateAsync(data);
    }

    const emailValidation =({email, visa})=>{
        if(email){
            emailValidApplicant.mutateAsync({email: email, visa:visa, appId:appStore.appid?appStore.appid:null})
        }
    }

    const emailValidApplicant = authQueries.useEmailValidApplicant(
        (response) => {
            if(response?.message === "Email already exist"){
                setEmailExist("Email already exist")
            }else{
                setEmailExist('')
            }
        }   
    );

    const mobileValidation =({mobile, visa})=>{
        if(mobile){
            mobileValidApplicant.mutateAsync({mobile: mobile, visa:visa, appId:appStore.appid?appStore.appid:null})
        }
    }

    const mobileValidApplicant = authQueries.useMobileValidApplicant(
        (response) => {
            if(response?.message === "Mobile already exist"){
                setMobileExist("Mobile number already exist")
            }else{
                setMobileExist('')
            }
        }   
    );


    const handleChange =(e)=>{
        const data = new FormData();
        data.append('file', e)
        imageUploadCreate.mutateAsync(data)
    }


    const imageUploadCreate = authQueries.useFileUploadCommon(
        (response) => {
            setImage(response?.file)
        }   
    );


  return (

    <div>

    {loader ? 
        <LoaderCommon /> :
        <Fragment>
            <div className="card">
                <div className="card-header">
                    <a className="card-link" data-toggle="collapse" href="#applicant-details">
                        Applicant Details -
                        <span className="applicant-details-wrpr">
                            <span>{name?<><i className="icon-user"></i>&nbsp;{name}</>:<><i className="icon-user"></i>&nbsp;{appStore?.appAllData?.applicant_name}</>}&nbsp;
                            {mName?mName:appStore?.appAllData?.middlename}&nbsp;
                            {lName?lName:appStore?.appAllData?.lastname}</span>
                            {email?<em ><i className="icon-envelope3"></i> {email}</em>:<span><i className="icon-envelope3"></i> {appStore?.appAllData?.email}</span>}
                            {mobile?<em href="#"><i className="icon-phone1"></i>&nbsp;{mobile}</em>:<span><i className="icon-phone1"></i> + {appStore?.appData?.whatsapp_no}</span>}
                        </span>
                    </a>
                    {appStore.appId?
                    <div data-fieldspending={"3"} className="tab-info fields-notcompleted">
                        <span className="icon-cancel1"></span>
                    </div>:null
                    }
                </div>
                <div id="applicant-details" className="collapse show" data-parent="#accordion">
                <form  onSubmit={handleSubmit(appStore.appid === ''?onSubmit:onEdit)}>
                    <div className="card-body">
                        <div className="row">
                            <div className='col-md-12' style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                <div className="form-group include-spouse">
                                </div>
                                <div className="profile-wrpr" style={{marginLeft: 0, marginRight: 0}}>
                                    <div id="image-preview" className='profile-img' style={{backgroundSize:"cover", 
                                    backgroundImage:image?
                                    `url(${image})`:`url(${require('../../assets/images/profile-avatar.jpg')})`
                                    }}>
                                        <label htmlFor="image-upload" id="image-label">Choose File</label>
                                        <input onChange={(e)=>{handleChange(e.target.files[0])}} type="file" name="image" id="image-upload" /> 
                                    </div>
                                </div>
                            </div>
                        <div className="col-md-4">
                            <div>
                                <label htmlFor="typevisa">Type of Visa:<em className="mandatory">*</em></label>
                                    <AutoCompleteChilds
                                        control={control} 
                                        data={resultVisa} 
                                        name={"visa_type_id"} 
                                        value={visaData} 
                                        emailValid={emailValidation} 
                                        mobileValid={mobileValidation}
                                        email={email}
                                        mobile={mobile}
                                        setVisaId={setVisaId}
                                        error={errors.visa_type_id}
                                    />
                                    <div className='error-msg'>{errors.visa_type_id?.message}</div>
                                </div>
                        </div>
                        <div className="col-md-4">
                            <div>
                                <label htmlFor="intake">Intake:<em className="mandatory">*</em></label>
                                <AutoCompleteAntd 
                                    control={control} 
                                    data={intakeResult} 
                                    name={"intake_id"} 
                                    value={intakeData} 
                                    error={errors.intake_id}
                                />
                                <div className="error-msg">{errors.intake_id?.message}</div>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div>
                                <label htmlFor="reffered-by">Reffered By:<em className="mandatory">*</em></label>
                                <input
                                    disabled
                                    value={refferalView?.data?.data?.refferal}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className={errors.applicant_name?"form-group error":"form-group"}>
                                <label htmlFor="applicant_name">First Name:<em className="mandatory">*</em></label>
                                <input
                                    {...register('applicant_name', {
                                        onChange: (e) => {setName(e.target.value)},
                                        onBlur: (e) => {},
                                    })}
                                    name='applicant_name' 
                                    id="applicant_name" 
                                    type="text" 
                                    className="form-control" /> 
                                <div className="error-msg">{errors.applicant_name?.message}</div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <label htmlFor="applicant_name">Middle Name</label>
                                <input
                                    {...register('middlename', {
                                        onChange: (e) => {setMName(e.target.value)},
                                        onBlur: (e) => {},
                                    })}
                                    name='middlename' 
                                    id="applicant_name" 
                                    type="text"
                                    className="form-control" /> 
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="form-group">
                                <label htmlFor="applicant_name">Last Name:</label>
                                <input
                                    {...register('lastname', {
                                        onChange: (e) => {setLName(e.target.value)},
                                        onBlur: (e) => {},
                                    })}
                                    name='lastname' 
                                    id="applicant_name" 
                                    type="text"
                                    className="form-control" /> 
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label htmlFor="spouse_name">Email Address:</label>
                                <input
                                    {...register('email', {
                                        onChange: (e) => {setEmail(e.target.value); emailValidation({email:e.target.value, visa:visaid});},
                                        onBlur: (e) => {},
                                    })}
                                    id="spouse_name" 
                                    type="email" 
                                    className="form-control" 
                                    name="email" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label htmlFor="spouse_name">Mobile Number:</label>
                                    <input
                                    {...register('mobile_no', {
                                        onChange: (e) => {setMobile(e.target.value); mobileValidation({mobile:e.target.value, visa:visaid});},
                                        onBlur: (e) => {},
                                    })}
                                    id="spouse_name" 
                                    type="number" 
                                    className="form-control" 
                                    name="mobile_no" />
                                <div className="error-msg">{errors.mobile_no?.message} {mobileExist}</div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className={errors.mobile_no?"form-group error":"form-group"}>
                                <label htmlFor="spouse_name">Counselors:<em className="mandatory">*</em></label>
                                    <select
                                    {...register('assign')}
                                    id="spouse_name" 
                                    type="number" 
                                    className="form-control" 
                                    name="assign">
                                        <option value="">Select Counselor</option>
                                        {
                                            counselorList?.data?.data?.map((item, index) => (  
                                                <option key={index} value={item?.user?.id}>{item?.user?.userdetail?.firstname} {item?.user?.userdetail?.lastname}</option>
                                        ))}
                                    </select>
                                <div className="error-msg">{errors.assign?.message}</div>
                            </div>
                        </div>


                        <div className="col-md-12 text-center my-4" style={{display:'flex', gap: 12, justifyContent:'center'}}>
                            <button 
                            type='button' 
                            onClick={()=>{navigate(-1)}} 
                            className="btn-customized-colorless bg-purple-700 w-[120px] h-[40px] gap-2"
                            ><IconBackspace size="18" />Go Back</button>
                            <button
                            disabled={addApplication.isLoading}
                            type='submit' 
                            className="btn-customized-large w-[120px] h-[40px] gap-2"
                            >
                                {
                                    addApplication.isLoading ?
                                    <Loading size='xs' color="white" />:
                                    <IconDeviceFloppy size="18" />
                                }
                            Save</button>
                        </div>             
                        
                        </div>
                    </div>
                </form>

                </div>
            </div>
        </Fragment>
    }
    

    </div>

  )
}

export default AssociatesForm