import React, { Fragment, useEffect, useState } from "react";

const VisaStaffStatusOptions =({status, current})=> {

    const [result, setResult] = useState([])
  
    useEffect(()=>{
        console.log(status, current)
      if(current === 15){
        const newDatas = status?.filter(element => [16, 21, 13].includes(element.id));
        setResult(newDatas)
      }else if(current === 16){
        const newDatas = status?.filter(element => [17, 18].includes(element.id));
        setResult(newDatas)
      }else if(current === 18){
        const newDatas = status?.filter(element => [13, 21].includes(element.id));
        setResult(newDatas)
      }else if(current === 13){
        const newDatas = status?.filter(element => element.id === 20 )
        setResult(newDatas)
      }else if(current !== 13 && current !== 15 && current !== 16 && current !== 17 && current !== 18 && current !== 20 && current !== 21){
        const newDatas = status?.filter(element => [15].includes(element.id));
        setResult(newDatas)
      }else{
        setResult([])
      }
    },[current])
  
    return(
      <Fragment>
        {
          result?.map((item, index)=> (
            <option key={index} value={item?.id}>{item?.name}</option>
          ))
        }
      </Fragment>
    )
}

export default VisaStaffStatusOptions