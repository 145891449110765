import { persist } from "zustand/middleware";
import create from "zustand";

export const useColorScheme = create(
    persist(
      (set) => ({
        bgColor: '#323a51',
        activeTextColor: '#FC97A4',
        setBg: (bg) => set({ bgColor: bg }),
        setActiveTextColor: (color) => set({ activeTextColor: color })
      }),
      { name: "colorScheme", getStorage: () => localStorage }
))