import { Modal } from 'antd'
import React, { Fragment, useState } from 'react'
import InputUseForm from '../Common/InputUseForm'
import { useForm } from 'react-hook-form'
import InputSelectForm from '../Common/InputSelectForm'
import InputTextArea from '../Common/InputTextArea'
import { yupResolver } from '@hookform/resolvers/yup'
import { generateValidationSchema } from '../Common/validationSchema'
import authQueries from '../../queries/authQueries'
import toast from 'react-hot-toast'
import { useApi } from '../Common/ApiProvider'

const ApplicationCreateModal = () => {

    const [open, setOpen] = useState(false)
    const [result, setResult] = useState([])

    const {setApiDataAndNotify} = useApi()

    const handleClose =()=>{
        setOpen(false)
    }

    const handleOpen =()=>{
        setOpen(true)
        officeList.mutateAsync({size:100000, magoffice:"YES"})
    }

    const officeList = authQueries.useOfficeMutationList(
        (response) => {
            setResult(response)
        }
    )
    
    const inputFields = [
        {
            name: "name",
            required: true,
            label: "Applicant Name",
            size: 4,
            type: InputUseForm
        },
        {
            name: "mobile",
            required: true,
            label: "Mobile",
            size: 4,
            type: InputUseForm
        },
        {
            name: "email",
            required: true,
            label: "Email",
            size: 4,
            type: InputUseForm
        },
        {
            name: "place",
            label: "Place",
            size: 4,
            type: InputUseForm
        },
        {
            name: "office_id",
            label: "Office",
            size: 4,
            type: InputSelectForm,
            optionProps:{name: "name", value: "id"},
            options: result?.data?.items
        },
        {
            name: "reference",
            label: "Reference",
            size: 4,
            type: InputSelectForm,
            optionProps:{name: "name", value: "id"},
            options: [
                {
                    name: "News paper",
                    value: "Google"
                },
                {
                    name: "Social Media",
                    value: "Facebook"
                },
                {
                    name: "Website",
                    value: "Instagram"
                },
                {
                    name: "Geebee Associates",
                    value: "Facebook"
                },
                {
                    name: "Others",
                    value: "Instagram"
                }
            ]
        },
        {
            name: "note",
            label: "Note",
            size: 4,
            type: InputTextArea
        }
    ]

    const {register, handleSubmit, formState: {errors}, control, reset} = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    })

    const createApplication = authQueries.useFairApplicationCreate(
        (response) => {
            if(response?.message === "success"){
                toast.success("Student created Sucessfully.");
                handleClose()
                setApiDataAndNotify(response)
                reset({
                    name: "",
                    mobile: "",
                    email: "",
                    place: "",
                    office_id: "",
                    reference: "",
                    note: ""
                })
            }else{
                toast.error("Something went wrong");
            }
        }
    );

    const createNextApplication = authQueries.useFairApplicationCreate(
        (response) => {
            if(response?.message === "success"){
                toast.success("Student created Sucessfully.");
                setApiDataAndNotify(response)
                reset({
                    name: "",
                    mobile: "",
                    email: "",
                    place: "",
                    office_id: "",
                    reference: "",
                    note: ""
                })
            }else{
                toast.error("Something went wrong");
            }
        }
    );

    const onSubmit =(data)=>{
        createApplication.mutateAsync(data)
    }

    const onSubmitNext =(data)=>{
        createNextApplication.mutateAsync(data)
    }



  return (
    <Fragment>
        <div className='w-full flex justify-end'>
            <div className='w-[100px]'>
                <button onClick={handleOpen} className='btn-customized-large h-[34px] text-[12px]'>
                    Add Client
                </button>
            </div>
        </div>
        <Modal
        open={open}
        onCancel={handleClose}
        footer={null}
        width={1000}
        >
            <div className='flex flex-col gap-6 font-poppins'>
                <div className='text-[14px] font-medium custom-textcolor'>
                    Create Application
                </div>
                <form>
                    <div className='flex flex-col justify-center gap-6'>
                        <div className={`grid grid-cols-12 gap-x-6 gap-y-3 max-[768px]:grid-cols-8 max-[502px]:grid-cols-4`}>
                            {
                                inputFields?.map((item, index)=>(
                                    <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                        <item.type item={item} register={register} errors={errors} control={control} options={item?.options} optionProps={item?.optionProps} />
                                    </div>
                                ))
                            }
                        </div>
                        <div className='flex max-[316px]:flex-col justify-end gap-5'>
                            <button 
                            onClick={handleClose} 
                            className='bg-red-500 text-white px-8 py-[8px] rounded-md text-[12px] font-semibold' 
                            type='button'
                            >
                            Cancel
                            </button>
                            <button
                            disabled={createApplication.isLoading}
                            onClick={handleSubmit(onSubmit)}
                            className='bg-primary text-white px-8 py-[8px] rounded-md text-[12px] font-semibold' 
                            type='submit'
                            >
                            Save
                            </button>
                            <button
                            disabled={createApplication.isLoading}
                            onClick={handleSubmit(onSubmitNext)}
                            className='bg-primary text-white px-8 py-[8px] rounded-md text-[12px] font-semibold' 
                            type='submit'
                            >
                            Save & Next
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    </Fragment>
  )
}

export default ApplicationCreateModal