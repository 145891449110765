import React, { Fragment } from 'react'

const RegisteredCheckSearch = ({item}) => {
  return (
    <Fragment>
        <div className="text-[12px] h-4 font-[500]">{item?.label}</div>
        <div className='w-full flex justify-between items-center bg-zinc-200 rounded-md text-[14px] p-[6px] min-h-[44px] h-full'>
            <button className={`w-full h-full flex justify-center items-center font-medium ${item.state === "ALL" && "custom-color rounded-md text-white"}`} 
            onClick={(e) => { item.setState("ALL")}}>
              All
            </button>
            <button className={`w-full h-full flex justify-center items-center font-medium ${item.state === "YES" && "custom-color rounded-md text-white"}`}  
            onClick={(e) => { item.setState("YES")}}>
              Yes
            </button>
            <button className={`w-full h-full flex justify-center items-center font-medium ${item.state === "NO" && "custom-color rounded-md text-white"}`} 
            onClick={(e) => { item.setState("NO")}}>
              No
            </button>
        </div>
    </Fragment>
  )
}

export default RegisteredCheckSearch
