import create from "zustand";
import { persist } from "zustand/middleware";

export const usePaginationStore = create((set) => ({
  currentPage: 0,
  setCurrentPage: (page) => set({ currentPage: page }),
  resetPage: () => set({ currentPage: 0 }),
}));

export const useLoaderStore = create((set) => ({
  loader: true,
  setLoader: (load) => set({ loader: load }),
}));

export const useDashboardTile = create((set) => ({
  value: null,
  data: [],
  setValue: (id) => set({ value: id }),
  setData: (data)=> set({data: data})
}));

export const userStore = create(
  persist(
    (set) => ({
      user: {
          userId: "",
          accessToken: "",
      },
    setUser: (userDetails) => {
      set({ user: userDetails });
    },
    setMaster: (id) => {
      set({ master: id });
    }, 
    setApplicantData: (applicantDetails) => {
      set({ appData: applicantDetails });
    },
    setApplication: (appDetails) => {
      set({ appid: appDetails });
    },
    setRoleId: (roleData) => {
      set({ rid: roleData });
    },
    setUserVisaId: (userData) => {
      set({ uvid: userData });
    },
    setUserOfficeId: (userData) => {
      set({ uoid: userData });
    },
    setApplicationAllData: (appData) => {
      set({ appAllData : appData });
    },
    setBranch: (data) => {
      set({ branchData : { branch: data.branch, counselor: data.counselor, status: data.status } });
    },
    setNotification: (data) => {
      set({ notificationData : data });
    },
    setNotificationCount: (data) => {
      set({ notificationCount : data });
    },
    setVisaData: (data) => {
      set({ visaData : data });
    },
    setEventData: (data) => {
      set({ eventData : data });
    }, 
    setPagination: (data) => {
      set({pagination:{ page : data.page, limit: data.limit }});
    },
    setUserId: (data) => {
      set({uid: {uid: data.uid, role: data.role}});
    },
    setTarget: (data) => {
      set({target: data});
    },
    setToggleMenu: (data) => {
      set({toggle: data});
    },
    setActiveTab: (data) => {
      set({activeTab: data});
    },
    })
  )
);

export const useApplicationFee = create(
  persist(
    (set) => ({
      program: '',
      setProgramId: (id) => set({ program: id })
    }),
    { name: "application_fee", getStorage: () => localStorage }
))

export const useViewProfile = create(
  persist(
    (set) => ({
      appid: '',
      setAppId: (id) => set({ appid: id })
    })
))