import React, { Fragment, useEffect, useState } from 'react'
import LoaderCommon from '../Common/LoaderCommon'
import { decode } from 'html-entities'
import moment from 'moment'
import { Empty } from 'antd'
import authQueries from '../../queries/authQueries'
import PaginationComponent from '../Common/PaginationComponent'
import { usePaginationStore, userStore } from '../../store/userStore'
import LeadStatus from '../Common/LeadStatus'
import ProspectStatusList from '../Common/ProspectStatusList'

const NotInterestedListing = () => {

    const today = new Date()
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);

    const store = userStore()

    const [result, setResult] = useState([]);
    const [name, setName] = useState('')
    const [mobile, setMobile] = useState('')
    const [email, setEmail] = useState('')
    const [visa, setVisa] = useState('')
    const [office, setOffice] = useState('')
    const [refferal, setRefferal] = useState('')
    const [counselor, setCounselor] = useState('')
    const [registered, setRegistered] = useState('ALL')
    const [interestOn, setInterestedOn] = useState(true)
    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const listApplicants = authQueries.useAllNotInterestOrQualifiedListMutation(
        (response) => {
            setInterestedOn(false)
            setResult(response)
        }
    )

    const visaTypeList = authQueries.useVisaTypeMutationList(
        (res)=>{   
        }
    )
    const officeList = authQueries.useOfficeMutationList(
        (res)=>{   
        }
    )
    const refferalList = authQueries.useRefferalMutationList(
        (res)=>{   
        }
    )

    const counselorList = authQueries.useRoleWiseListingUsersMutation(
        (res) => {
        }   
    )

    useEffect(()=> {
        listApplicants.mutateAsync({
            id: 2, 
            size: 25, 
            page: currentPage,
            name: name,
            mobile: mobile,
            email: email,
            visa: visa,
            office: office,
            refferal: refferal,
            counselor: counselor,
            interestedOn: interestOn,
            registered: registered,
            from: from,
            to: to
        })
    },[currentPage])

    const onSearch =()=> {
        setCurrentPage(0)
        listApplicants.mutateAsync({
            id: 2, 
            size: 25, 
            page: currentPage,
            name: name,
            mobile: mobile,
            email: email,
            visa: visa,
            office: office,
            refferal: refferal,
            counselor: counselor,
            interestedOn: interestOn,
            registered: registered,
            from: from,
            to: to
        })
    }

    useEffect(()=> {
        visaTypeList.mutateAsync({size: 10000, page: 0})
        officeList.mutateAsync({size: 10000, page: 0})
        refferalList.mutateAsync({size: 10000, page: 0})
        counselorList.mutateAsync(3)
    },[])

    const ReassignCounselor = authQueries.useReAssignCounselorMutation(
        (res)=> {
            onSearch()
        }
    )

    const captureApplicant = authQueries.useCaptureApplicantTelecallerMutation(
        (res)=> {
            onSearch()
        }
    )

    const handleReAssign =(item)=> {
        if(store?.user?.role === "TELECALLER"){
            captureApplicant.mutateAsync(item?.id)
        }else{
            ReassignCounselor.mutateAsync(item?.id)
        }
    }

  return (
    <div>
        <div className="add_clients">
            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr">
                    <i className="appointments-icon">
                        <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <h2><span>Not Interested</span>Clients</h2>
                </div>
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>
            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header">
                        <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                            Not Interested Clients
                        </a>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">
                            <div className="table-list-search-wrpr">
                                <div className='grid grid-cols-12 gap-4 max-[1214px]:grid-cols-10 max-[958px]:grid-cols-8 max-[824px]:grid-cols-6 max-[622px]:grid-cols-4 max-[374px]:grid-cols-2 !w-full mb-4'>
                                    <div className='col-span-2'>
                                        <label>Applicant Name</label>
                                        <input placeholder='Applicant Name' value={name} onChange={(e)=>{setName(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]' />
                                    </div>
                                    <div className='col-span-2'>
                                        <label>Contact number</label>
                                        <input placeholder='Contact number' value={mobile} onChange={(e)=> {setMobile(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]' />
                                    </div>          
                                    <div className='col-span-2'>
                                        <label>Email</label>
                                        <input placeholder='Email' value={email} onChange={(e)=> {setEmail(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]' />
                                    </div>
                                    <div className='col-span-2'>
                                        <label>Visa Type</label>
                                        <select  value={visa} onChange={(e)=> {setVisa(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]'>
                                            <option value="">Select option</option>
                                            {
                                                visaTypeList?.data?.data?.items?.map((item, index) => (
                                                    <option key={index} value={item.id}>{item?.description}</option>
                                                ))
                                            }
                                        </select>
                                    </div>          
                                    <div className='col-span-2'>
                                        <label>Office Name</label>
                                        <select value={office} onChange={(e)=> {setOffice(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]'>
                                            <option value="">Select option</option>
                                            {
                                                officeList?.data?.data?.items?.map((item, index) => (
                                                    <option key={index} value={item.id}>{item?.name }</option>
                                                ))
                                            }
                                        </select>                                    
                                    </div>
                                    <div className='col-span-2'>
                                        <label>Reference</label>
                                        <select value={refferal} onChange={(e)=> {setRefferal(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]'>
                                            <option value="">Select option</option>
                                            {
                                                refferalList?.data?.data?.items?.map((item, index) => (
                                                    <option key={index} value={item.id}>{item?.refferal}</option>
                                                ))
                                            }
                                        </select>                                    
                                    </div>
                                    <div className='col-span-2'>
                                        <label>Counselor</label>
                                        <select value={counselor} onChange={(e)=> {setCounselor(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]'>
                                            <option value="">Select option</option>
                                            {
                                                counselorList?.data?.data?.map((item, index) => {
                                                    const element = item?.userdetail
                                                    return(
                                                        <option key={index} value={item.id}>{element?.firstname} {element?.lastname}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-span-2'>
                                        <label>Registration Status</label>
                                        <div className='grid grid-cols-3 text-xs border-[1px] border-solid !min-h-[38px] rounded-md border-[#ced4da]'>
                                            <button onClick={()=>setRegistered('ALL')} className={`${registered === "ALL" && "bg-[blue] text-white" } col-span-1 rounded-sm`}>All</button>
                                            <button onClick={()=>setRegistered('YES')} className={`${registered === "YES" && "bg-[blue] text-white" } col-span-1 rounded-sm`}>Yes</button>
                                            <button onClick={()=>setRegistered('NO')} className={`${registered === "NO" && "bg-[blue] text-white" } col-span- rounded-sm`}>No</button>
                                        </div>
                                    </div>
                                    <div className='col-span-2'>
                                        <label>From Date</label>
                                        <input value={from} onChange={(e)=> {setFrom(e.target.value)}} type='date' className='form-control form-control-sm !w-full !min-h-[38px]' />
                                    </div>
                                    <div className='col-span-2'>
                                        <label>To Date</label>
                                        <input value={to} onChange={(e)=> {setTo(e.target.value)}} type='date' className='form-control form-control-sm !w-full !min-h-[38px]' />
                                    </div>
                                    <div className='col-span-1 flex flex-col'>
                                        <label>&nbsp;</label>
                                        <button onClick={onSearch} className='mt-[3px] rounded-md text-[12px] !w-full !min-h-[38px] custom-color border-none text-white'>
                                            Search
                                        </button>
                                    </div>  
                                </div>
                                <div className='flex flex-col justify-between'>
                                    <div className="search-results-count-wrpr">
                                        {
                                        result?.data?.totalItems > 0 ?
                                        `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                        `O results found`
                                        }
                                    </div>
                                    <div className='text-[12px] italic mb-2 text-red-500'>
                                    Not interested data is shown for the last 3 months. You can use the search function if you need older data
                                    </div>
                                </div>
                                {
                                    listApplicants.isLoading ?
                                    <LoaderCommon />:
                                    result?.data?.totalItems > 0 ?
                                    <div className="table-wrpr">
                                        <div className="table-section">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table prospect-table">
                                                        <thead>
                                                            <tr className='alt-colors'>
                                                                <th width="30">#</th>
                                                                <th>Profile Status</th>
                                                                <th>Office</th>
                                                                <th>Date Created</th>
                                                                <th>Applicant Name</th>
                                                                <th>Contact Number</th>
                                                                <th>Visa Type</th>
                                                                <th>Intake</th>
                                                                <th>Reg. Date</th>
                                                                <th>Prospect Status</th>
                                                                <th>Counselor</th>
                                                                <th>Reffered By</th>
                                                                {
                                                                    (store?.user?.role === "COUNSELOR" || store?.user?.role === "TELECALLER") &&
                                                                    <th width="70">&nbsp;</th>
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody className="ui-sortable">
                                                            {result?.data?.items?.map((item, index) => (
                                                                <Fragment key={index}>
                                                                    <tr>
                                                                        <th className="">{(index + 1) + (result.data?.currentPage * 25)}</th>
                                                                        <td>
                                                                            <LeadStatus item={item} />
                                                                        </td>
                                                                        <td>{item?.office?.name}</td>
                                                                        <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                        <td>{decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)}</td>
                                                                        <td>
                                                                            <div className="email-mobile flex flex-col">
                                                                                <div>{item.mobile_no}</div>
                                                                                <div>{item.landline_no}</div>
                                                                                <div>{item.whatsapp_no}</div>
                                                                            </div>
                                                                        </td>
                                                                        <td>{item?.visatype?.description}</td>
                                                                        <td>{item?.intake?.intake}</td>
                                                                        <td>
                                                                            {(item?.registered_status === "YES" && item?.registered_date) ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                                        </td>
                                                                        <td>
                                                                            <ProspectStatusList defaultStatus={"Not interested"} item={item} readonly={true}/>
                                                                        </td>
                                                                        <td>
                                                                            {userFilter(item?.assignUser)}
                                                                        </td>
                                                                        <td>{item?.refferal?.refferal}</td>
                                                                        {
                                                                            (store?.user?.role === "COUNSELOR" || store?.user?.role === "TELECALLER") &&
                                                                            <td className='action-icons action-buttons flex justify-end'>
                                                                                <button
                                                                                disabled={ReassignCounselor.isLoading ? true : false}
                                                                                onClick={()=> {handleReAssign(item)}}
                                                                                className="btn-visadata !min-w-[80px] !w-[80px]">
                                                                                    Capture
                                                                                </button>
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                </Fragment>

                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className='mt-24 mb-24'>
                                        <Empty
                                        description={<div className="font-sans text-sm text-blue-700">Clients not found!</div>}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NotInterestedListing

const userFilter =(user)=>{
    if(!user) return null
    return user?.CounselorAssign?.userdetail?.firstname + ' ' + user?.CounselorAssign?.userdetail?.lastname
}