import React, { Fragment, useEffect, useState } from 'react'
import { Empty } from 'antd'
import { useNavigate } from 'react-router-dom';
import authQueries from '../../queries/authQueries';
import { useApplicationFee, usePaginationStore, userStore } from '../../store/userStore';
import Swiper from 'swiper';
import 'swiper/css';
import moment from 'moment';
import $ from 'jquery'
import CountUp from 'react-countup';
import LoaderCommon from '../Common/LoaderCommon';
import FollowUp from '../Common/FollowUp';
import PaginationComponent from '../Common/PaginationComponent';
import FollowupPending from '../Common/FollowupPending';
import FollowupToday from '../Common/FollowupToday';
import ApplicantViewButton from '../Common/ApplicantViewButton';
import ApplicantEditButton from '../Common/ApplicantEditButton';
import LeadStatus from '../Common/LeadStatus';
import ProspectStatusList from '../Common/ProspectStatusList';
import AssignModal from '../Common/AssignModal';
import { useApi } from '../Common/ApiProvider';
import ApplicationStatusModal from '../Common/ApplicationStatusModal';
import LoginDetailsModal from '../Common/LoginDetailsModal';
import ApplicationStatusCreateModal from '../Common/ApplicationStatusCreateModal';
import DocumentUploadModal from '../Common/DocumentUploadModal';
import RouteConstants from '../../Constants/RouteConstans';
import { useAdvancedSearch } from '../../store/advancedSearchStore';
import { Vote } from 'lucide-react';
import { IconCalendarMonth, IconLayout } from '@tabler/icons-react';


const AdmissionDashboard = () => {

	const navigate = useNavigate()

    const [count, setCount] = useState({})
	const [loader, setLoader] = useState(true);

	const setActiveTab = userStore((state)=> state.setActiveTab)
	const resetAll = useAdvancedSearch((state) => state.resetAll);
	const setFinancialYear = useAdvancedSearch((state) => state.setFinancialYear)
	const setFulldocappmoved = useAdvancedSearch((state) => state.setFulldocappmoved)
	const setAppStatus = useAdvancedSearch((state) => state.setAppStatus)
	const setFundReady = useAdvancedSearch((state) => state.setFundReady)
	const setNotProceeding = useAdvancedSearch((state) => state.setNotProceeding)

    const counts = authQueries.useAdmissionDashboardCountMutation(
		async (response) => {
      	if(response?.message === "success")
		setLoader(false)
      	setCount(response)
    });

    useEffect(() => {
        counts.mutateAsync()
	}, [])

	const slideArr = [
		{
			color: '#98ABEE',
			title: 'Total Active Clients',
			value: "TOTAL",
			icon: <Vote color='#98ABEE' size="20" /> ,
			count: count?.total?.active,
		},
		{
			color: '#FC6736',
			title: 'Full Doc No Application Moved',
			value: "FULLDOC",
			icon: <Vote color='#FC6736' size="20" />,
			count: count?.total?.fullDoc,
		},
		{
			color: '#85586F',
			title: 'Awaiting Offer',
			value: "AWAITING",
			icon: <Vote color='#85586F' size="20" />,
			count: count?.total?.awaiting,
		},
		{
			color: '#0D9276',
			title: 'Conditional Offer Received',
			value: "COND",
			icon: <Vote color='#0D9276' size="20" />,
			count: count?.total?.conditional,
		},
	];

	const slideArr2 = [
		{
			color: '#D63484',
			title: 'Un Conditional Offer Received',
			value: "UNCOND",
			icon: <Vote color='#D63484' size="20" /> ,
			count: count?.total?.unConditional,
		},
		{
			color: '#365486',
			title: 'Offer Accepted',
			value: "OFFER",
			icon: <Vote color='#365486' size="20" />,
			count: count?.total?.offer,
		},
		{
			color: '#76453B',
			title: 'No Proceeding Offer/Application',
			value: "NOTPRO",
			icon: <Vote color='#76453B' size="20" />,
			count: count?.total?.notProceeding,
		},
		{
			color: '#DC84F3',
			title: 'Fund Ready Students',
			value: "FUND",
			icon: <Vote color='#DC84F3' size="20" />,
			count: count?.total?.fundReady,
		},
	];

	const slideArr3 = [
		{
			color: '#A4CE95',
			title: 'Total Active Clients',
			value: "ACTIVE",
			icon: <Vote color='#A4CE95' size="20" /> ,
			count: count?.current?.activeCurrent,
		},
		{
			color: '#AD88C6',
			title: 'Full Doc No Application Moved',
			value: "FULLDOC",
			icon: <Vote color='#AD88C6' size="20" />,
			count: count?.current?.fullDocCurrent,
		},
		{
			color: '#EE4266',
			title: 'Awaiting Offer',
			value: "AWAITING",
			icon: <Vote color='#EE4266' size="20" />,
			count: count?.current?.awaitingCurrent,
		},
		{
			color: '#836FFF',
			title: 'Conditional Offer Received',
			value: "COND",
			icon: <Vote color='#836FFF' size="20" />,
			count: count?.current?.conditionalCurrent,
		},
	];

	const slideArr4 = [
		{
			color: '#FC6736',
			title: 'Un Conditional Offer Received',
			value: "UNCOND",
			icon: <Vote color='#FC6736' size="20" /> ,
			count: count?.current?.unConditionalCurrent,
		},
		{
			color: '#647D87',
			title: 'Offer Accepted',
			value: "OFFER",
			icon: <Vote color='#647D87' size="20" />,
			count: count?.current?.offerCurrent,
		},
		{
			color: '#7360DF',
			title: 'No Proceeding Offer/Application',
			value: "NOTPRO",
			icon: <Vote color='#7360DF' size="20" />,
			count: count?.current?.notProceedingCurrent,
		},
		{
			color: '#B2A59B',
			title: 'Fund Ready Students',
			value: "FUND",
			icon: <Vote color='#B2A59B' size="20" />,
			count: count?.current?.fundReadyCurrent,
		},
	];

	const slider = () => {
 
		var swiper = new Swiper(".geebee-carousel.followup-pending", {
			slidesPerView: 5,
			spaceBetween: 10,
			speed: 700,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
				"1": {
					slidesPerView: 1,
					spaceBetween: 10,
                },
				'480': {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				'640': {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				'920': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
				'1200': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next.carousel-next",
				prevEl: ".swiper-button-prev.carousel-prev",
			},
		});

	}

	useEffect(() => {
		slider();
		$('html,body').animate({ scrollTop: 0 }, 'fast');
	}, [loader]);

	const handleNavigate =(item, type)=>{
		resetAll()
		if(type === "CURRENT"){
			setFinancialYear("CURRENT")
		}
		if(item.value == "FULLDOC"){
			setFulldocappmoved(true)
		}
		if(item.value === "AWAITING"){
			setAppStatus(3)
		}
		if(item.value === "COND"){
			setAppStatus(5)
		}
		if(item.value === "UNCOND"){
			setAppStatus(6)
		}
		if(item.value === "NOTPRO"){
			setNotProceeding(true)
		}
		if(item.value === "OFFER"){
			setAppStatus(7)
		}
		if(item.value === "FUND"){
			setFundReady(true)
		}
		setActiveTab(RouteConstants.ADMISSIONSTAFFMYCLIENTS)
		navigate(RouteConstants.ADMISSIONSTAFFMYCLIENTS)
	}

	return ( 
		<div>
			{
				loader ?
				<div style={{display:'flex', justifyContent:'center', flexDirection:'column', marginTop:'10%'}}>
					<LoaderCommon  />
				</div>:
				<div>


					<div className="carousel-section mb-4">
						<div className="row">
							
							<div className="col-md-12" style={{display:'flex', alignItems:'center', gap:'4px'}}>
								<i className="followup-icon">
									<IconLayout size="20" className='text-[#7D6FAB]' />
								</i>
								<div style={{display:'flex', flexDirection:'column'}}>
									<div style={{display:'flex', alignItems:'center', gap:'10px'}}>
										<span className="text-[16px] font-medium">Total Clients</span>	
									</div>
								</div>
							</div>
							
							<div className="col-md-12 mt-4">
								<div className="swiper-container followup-pending geebee-carousel">
									<div className="swiper-wrapper">
										{slideArr.map((item, index) => (
											<div
											onClick={()=>handleNavigate(item, "TOTAL")}
											key={index} 
											className={`swiper-slide h-32 rounded-2xl text-white cursor-pointer`} 
											style={{background:item.color}}>
												<div className='flex flex-col p-3 justify-between h-full'>
													<div className='flex justify-between'>
														<div className='bg-white rounded-full flex justify-center items-center w-10 h-10 color-red-500'>{item.icon}</div>
														<div className='text-4xl'>
															<CountUp end={item?.count} duration={1} />
														</div>
													</div>
													<div>
														<div className='text-[14px]'>{item.title}</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
							<div className="col-md-12 mt-4">
								<div className="swiper-container followup-pending geebee-carousel">
									<div className="swiper-wrapper">
										{slideArr2.map((item, index) => (
											<div
											onClick={()=>handleNavigate(item, "TOTAL")}
											key={index} 
											className={`swiper-slide h-32 rounded-2xl text-white cursor-pointer`} 
											style={{background:item.color}}>
												<div className='flex flex-col p-3 justify-between h-full'>
													<div className='flex justify-between'>
														<div className='bg-white rounded-full flex justify-center items-center w-10 h-10 color-red-500'>{item.icon}</div>
														<div className='text-4xl'>
															<CountUp end={item?.count} duration={1} />
														</div>
													</div>
													<div>
														<div className='text-[14px]'>{item.title}</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>

							<div className="col-md-12 flex items-center gap-2 mt-4">
								<i className="followup-icon">
									<IconCalendarMonth size="25" className='text-[#7D6FAB]' />
								</i>
								<div style={{display:'flex', flexDirection:'column'}}>
									<div style={{display:'flex', alignItems:'center', gap:'10px'}}>
										<span className="text-[16px] font-medium">Current Financial Year</span>	
									</div>
								</div>
							</div>

							<div className="col-md-12 mt-4">
								<div className="swiper-container followup-pending geebee-carousel">
									<div className="swiper-wrapper">
										{slideArr3.map((item, index) => (
											<div
											onClick={()=>handleNavigate(item, "CURRENT")}
											key={index} 
											className={`swiper-slide h-32 rounded-2xl text-white cursor-pointer`} 
											style={{background:item.color}}>
												<div className='flex flex-col p-3 justify-between h-full'>
													<div className='flex justify-between'>
														<div className='bg-white rounded-full flex justify-center items-center w-10 h-10 color-red-500'>{item.icon}</div>
														<div className='text-4xl'>
															<CountUp end={item?.count} duration={1} />
														</div>
													</div>
													<div>
														<div className='text-[14px]'>{item.title}</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
							<div className="col-md-12 mt-4">
								<div className="swiper-container followup-pending geebee-carousel">
									<div className="swiper-wrapper">
										{slideArr4.map((item, index) => (
											<div
											onClick={()=>handleNavigate(item, "CURRENT")}
											key={index} 
											className={`swiper-slide h-32 rounded-2xl text-white cursor-pointer`} 
											style={{background:item.color}}>
												<div className='flex flex-col p-3 justify-between h-full'>
													<div className='flex justify-between'>
														<div className='bg-white rounded-full flex justify-center items-center w-10 h-10 color-red-500'>{item.icon}</div>
														<div className='text-4xl'>
															<CountUp end={item?.count} duration={1} />
														</div>
													</div>
													<div>
														<div className='text-[14px]'>{item.title}</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>

					<FollowupToday />
					<FollowupPending />
											
				</div>
			}

		</div>
	)
}

export default AdmissionDashboard