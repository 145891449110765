import React from 'react'
import ReactApexChart from "react-apexcharts";

const Charts = ({categories, data, colors}) => {

	const maxValue = 98800

	const chart = {
		series : [
			{
				name: "Active Counts",
				data: data
			}
		],
		options : {
			chart: {
				height: 350,
				type: 'bar',
				stacked: false,
				toolbar: {
					show: false
				},
			},
			grid: {
				show: true,
				borderColor: '#f0f0f0',
				strokeDashArray: 1,
			},	
			legend: {
				show: true
			},
			yaxis: {
				show: true,
				labels: {
					formatter: function (val) {
						const newValue = val.toFixed(0)
						return newValue;
					},
					style: {
						fontSize: "12px",
						fontFamily: 'Poppins',
					}
				}
			},
			stroke: {
				width:[0, 0, 4],
				curve: 'smooth'
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: "30%",
					borderRadius: 5,
						dataLabels: {
							position: 'top',
						},
				},
			},
			dataLabels: {
                enabled: true,
                formatter: function (val) {
                  return val;
                },
                offsetY: -20,
                style: {
                  fontSize: '12px',
                  colors: ["#304758"]
                }
			},
			xaxis: {
				categories: categories,
				labels: {
					style: {
						fontSize: "12px",
						fontFamily: 'Poppins',
					}
				}
			},
			colors: colors,
		}

	};



    return(
        <div>
            <ReactApexChart
                options={chart.options}
                series={chart.series}
                type="bar"
            />
        </div>
    )

}

export default Charts