import React, { useState, useEffect, Fragment } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import authQueries from '../../../queries/authQueries'
import * as yup from 'yup'
import { message as messages, Popconfirm } from 'antd'
import { userStore } from '../../../store/userStore'
import moment from 'moment'
import { AutoCompleteAntd, AutoCompleteAntdInteded } from './AutoComplete';

const OverseasEducation = () => {

    const appStore = userStore();

    const [result, setResult] = useState(appStore?.appAllData?.overseas_education_historys)
    const [countryResult, setCountryResult] = useState([])
    const [edit, setEdit] = useState(0)

    useEffect(() => {
        listCountry.mutateAsync({ search: '', status: '', page: 0, size: 500 })
    }, [])

    const addOverseasSchema = yup.object({

        country: yup
            .string('⚠ Please Select Country')
            .required('⚠ Country is required'),
        college: yup
            .string('⚠ Please Select College')
            .required('⚠ College is required'),
        program: yup
            .string('⚠ Please enter Program name')
            .required('⚠ Program name is required'),
        visa: yup
            .string('⚠ Please enter Visa Term')
            .required('⚠ Visa Term is required'),
        existclose: yup
            .string('⚠ Please Select Exist or Close')
            .required('⚠ Exist or Close is required'),
        date: yup
            .string('⚠ Please Enter Date')
            .required('⚠ Date is required'),
    });

    const editOverseasSchema = yup.object({

        countrys: yup
            .string('⚠ Please Select Country')
            .required('⚠ Country is required'),
        colleges: yup
            .string('⚠ Please Select College')
            .required('⚠ College is required'),
        programs: yup
            .string('⚠ Please Enter Program name')
            .required('⚠ Program name is required'),
        visas: yup
            .string('⚠ Please Enter Visa Term')
            .required('⚠ Visa Term is required'),
        existcloses: yup
            .string('⚠ Please Select Exist or Close')
            .required('⚠ Exist or Close is required'),
        dates: yup
            .string('⚠ Please Enter Date')
            .required('⚠ Date is required'),
    });

    const listCountry = authQueries.useCountryMutationList(
        (response) => {
            setCountryResult(response)
        }
    );

    const { register, reset, control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(edit === 0 ? addOverseasSchema : editOverseasSchema)
    });

    const addOverseas = authQueries.useOverseasAdd(
        (response) => {
            viewAllApplicationData.mutateAsync(appStore.appid)
            let datas = ({
                country: "",
                college: "",
                program: "",
                visa: "",
                existclose: "",
                date: "",
            })
            reset({ ...datas });
        }
    );

    const onSubmit = (data) => {

        const datavalues = {
            applicant_id: appStore.appid,
            country_id: data.country,
            college: data.college,
            program_name: data.program,
            visa_term: data.visa,
            existing_or_closed: data.existclose,
            return_date: data.date,
            status: "ACTIVE"
        }

        addOverseas.mutateAsync(datavalues);
    }

    const viewOverseasData = (event, item) => {
        event.preventDefault();
        setEdit(item?.id)
        let datas = ({
            countrys: item.country_id,
            colleges: item.college,
            programs: item.program_name,
            visas: item.visa_term,
            existcloses: item.existing_or_closed,
            dates: moment(item.return_date).format("YYYY-MM-DD"),
        })
        reset({ ...datas });
    }

    const editOverseas = authQueries.useOverseasEdit(
        (response) => {
            setEdit(0)
            viewAllApplicationData.mutateAsync(appStore.appid)
        }
    );

    const onEdit = (data, id) => {

        const datavalues = {
            id: edit,
            applicant_id: appStore.appid,
            country_id: data.countrys,
            college: data.colleges,
            program_name: data.programs,
            visa_term: data.visas,
            existing_or_closed: data.existcloses,
            return_date: data.dates,
            status: "ACTIVE"
        }

        editOverseas.mutateAsync(datavalues)
    }

    const deleteOverseas = authQueries.useOverseasDelete(
        (response) => {
            messages.success("successfully deleted")
            viewAllApplicationData.mutateAsync(appStore.appid)
        }
    );

    const deleteOverseasData = async(event, id) => {
        event.preventDefault();
        deleteOverseas.mutateAsync(id)
    }

    const viewAllApplicationData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
                setResult(response?.data?.overseas_education_historys)
            }
        }
    )

    return (

        <div>

            <div className="card">
                <div className="card-header">
                    <a className="collapsed card-link" data-toggle="collapse" href="#overseas_education">
                        Overseas Education History, If any
                    </a>
                    <div className={result?.length > 0 ? "tab-info fields-completed" :  "tab-info fields-notcompleted" }>
                        <span className={result?.length > 0 ? 'icon-checkmark1' : 'icon-cancel1'}></span>
                    </div>
                </div>
                <div id="overseas_education" className="collapse" data-parent="#accordion">
                    <div className="card-body">

                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="grid grid-cols-12 gap-x-6 max-[1187px]:grid-cols-10 max-[1046px]:grid-cols-8 max-[942px]:grid-cols-5  max-[553px]:grid-cols-4 max-[425px]:grid-cols-2">
                                <div className="col-span-2">
                                    <div className="form-group">
                                        <label htmlFor="">Country:</label>
                                        <select className="form-control form-control-sm !w-full" {...register("country")} name="country" >
                                            <option value=''>Select</option>
                                            {
                                                countryResult?.data?.items.map((item, index) => (
                                                    <option value={item.id} key={index}>
                                                        {item.name}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                        <div className="error-msg">{errors.country?.message}</div>
                                    </div>
                                </div>
                                <div className="col-span-3 max-[553px]:col-span-2">
                                    <div className="form-group">
                                        <label htmlFor="">College/University:</label>
                                        <input {...register("college")} name="college" className="form-control form-control-sm !w-full" />
                                        <div className="error-msg">{errors.college?.message}</div>
                                    </div>
                                    <div id="output"></div>
                                </div>
                                <div className="col-span-3 max-[553px]:col-span-2">
                                    <div className="form-fgroup">
                                        <label htmlFor="">Program Name:</label>
                                        <input {...register("program")} name="program" className="form-control form-control-sm !w-full" />
                                        <div className="error-msg">{errors.program?.message}</div>
                                    </div>
                                    <div id="output"></div>
                                </div>
                                <div className="col-span-2">
                                    <div className="form-group">
                                        <label htmlFor="">Visa Term:</label>
                                        <input {...register("visa")} name="visa" className="form-control form-control-sm !w-full" />
                                        <div className="error-msg">{errors.visa?.message}</div>
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="form-group">
                                        <label htmlFor="">Existing/ Closed:</label>
                                        <select className="form-control form-control-sm !w-full" {...register("existclose")} name="existclose" >
                                            <option value=''>Select</option>
                                            <option value='existing'>Existing</option>
                                            <option value='closed'>Closed</option>
                                        </select>
                                        <div className="error-msg">{errors.existclose?.message}</div>
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="form-group">
                                        <label htmlFor="">Return Date:</label>
                                        <input type="date" {...register("date")} name="date" className="form-control form-control-sm !w-full" />
                                        <div className="error-msg">{errors.date?.message}</div>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="form-group">
                                        <label htmlFor="">&nbsp;</label><br />
                                        <button 
                                        type='submit' 
                                        className="btn btn-default btn-nextrow text-white w-full"
                                        >Create</button>
                                    </div>
                                </div>
                            </div>

                        </form>

                        <div className="row">
                            <div className="col-md-12">
                                <hr />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mt-2">
                                <form>
                                    <div className="table-wrpr">
                                        <div className="table-section">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table prospect-table">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th width="120">Country</th>
                                                                <th>College/ University</th>
                                                                <th width="300">Program Name</th>
                                                                <th>Visa Term</th>
                                                                <th width="200">Existing/ Closed</th>
                                                                <th width="120">Return Date</th>
                                                                <th width="100">&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id="sortable6" className="ui-sortable">
                                                            {result?.map((item, index) => (
                                                                <Fragment key={index}>
                                                                    {edit !== item.id ?
                                                                        <tr className="ui-sortable-handle">
                                                                            <td>{index + 1}</td>
                                                                            <td>{item.country?.name}</td>
                                                                            <td>{item.college}</td>
                                                                            <td>{item.program_name}</td>
                                                                            <td>{item.visa_term}</td>
                                                                            <td>{item.existing_or_closed}</td>
                                                                            <td>{moment(item.return_date).format('DD-MM-YYYY')}</td>
                                                                            <td className="action-icons flex gap-1 justify-end">
                                                                                <button onClick={event => viewOverseasData(event, item)} className="btn-nextrow">Edit</button>
                                                                                <Popconfirm
                                                                                    placement="left"
                                                                                    title="Are you sure to delete this Details ?"
                                                                                    onConfirm={(event) => {deleteOverseasData(event, item.id)}}
                                                                                    okText="Yes"
                                                                                    cancelText="No"
                                                                                >
                                                                                    <button type='button' style={{ color: '#fff' }} className="btn-removerow">Remove</button>
                                                                                </Popconfirm>
                                                                            </td>
                                                                        </tr>
                                                                        :
                                                                        <tr>
                                                                            <td>{index + 1}</td>
                                                                            <td>
                                                                                <select {...register("countrys")} name="countrys" className="form-control form-control-sm !w-full">
                                                                                    <option value="">Select</option>
                                                                                    {
                                                                                        countryResult?.data?.items.map((item, index) => (
                                                                                            <option value={item.id} key={index}>
                                                                                                {item.name}
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                                <div className="error-msg">{errors.countrys?.message}</div>
                                                                            </td>

                                                                            <td>
                                                                                <input type='text' {...register("colleges")} name="colleges" className="form-control form-control-sm !w-full" />
                                                                                <div className="error-msg">{errors.colleges?.message}</div>
                                                                            </td>
                                                                            <td>
                                                                                <input type='text' {...register("programs")} name="programs" className="form-control form-control-sm !w-full" />
                                                                                <div className="error-msg">{errors.programs?.message}</div>
                                                                            </td>
                                                                            <td>
                                                                                <input type='text' {...register("visas")} name="visas" className="form-control form-control-sm !w-full" />
                                                                                <div className="error-msg">{errors.visas?.message}</div>
                                                                            </td>

                                                                            <td>
                                                                                <select {...register("existcloses")} name="existcloses" className="form-control form-control-sm !w-full">
                                                                                    <option value="">Select</option>
                                                                                    <option value="existing">Existing</option>
                                                                                    <option value="closed">Closed</option>

                                                                                </select>
                                                                                <div className="error-msg">{errors.existcloses?.message}</div>
                                                                            </td>

                                                                            <td>
                                                                                <input type='date' {...register("dates")} name="dates" className="form-control form-control-sm !w-full" />
                                                                                <div className="error-msg">{errors.dates?.message}</div>
                                                                            </td>

                                                                            <td className="action-icons">
                                                                                <button style={{ color: '#fff' }} onClick={handleSubmit(onEdit)} className="btn-nextrow mt-[-5px]">Update</button>
                                                                            </td>
                                                                        </tr>

                                                                    }

                                                                </Fragment>

                                                            ))}


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )
}

export default OverseasEducation