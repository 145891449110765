import React, { Fragment, useEffect, useState } from 'react'
import PaginationComponent from '../Common/PaginationComponent'
import { useLoaderStore, usePaginationStore } from '../../store/userStore';
import authQueries from '../../queries/authQueries';
import { decode } from 'html-entities';
import LoaderCommon from '../Common/LoaderCommon';
import { Empty } from 'antd';

const SPMUserlist = () => {

    const [result, setResult] = useState([]);
    const [search, setSearch] = useState('');
    const [loader, setLoader] = useState(true);

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    useEffect(()=>{
        userListing.mutateAsync({
            page:currentPage, 
            size:25, 
            search:search
        })
    },[currentPage, search])

    const userListing = authQueries.useSeniorManagerUsersList(
        (res) => {
            setResult(res)
            setLoader(false)
        }   
    );


  return (
    <Fragment>

        <div className="add_clients">
            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr">
                    <i className="appointments-icon">
                        <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <h2><span>My</span>Counselors</h2>	
                </div>
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>

            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header">
                        <a className="collapsed card-link"  href="" style={{pointerEvents:'none'}}>
                            My Counselors
                        </a>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">
                                <div className='flex justify-between'>
                                    <div className="table-search-filter-wrpr w-full">
                                        <div className="table-search-wrpr w-full flex justify-end">
                                            <input onChange={(e)=>{setSearch(e.target.value)}} style={{minWidth: '300px'}} className="form-control form-control-sm" type="text" placeholder="Search" />
                                        </div>
                                    </div>
                                </div>
        
                                {
                                    loader ?
                                    <LoaderCommon />
                                    :
                                    <Fragment>
                                        {
                                            result?.data?.totalItems > 0 ?
                                            <div className="table-wrpr">
                                                <div className="table-section">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <table className="table prospect-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th width="30">#</th>
                                                                        <th>User Name</th>
                                                                        <th>Name</th>
                                                                        <th>Phone</th>
                                                                        <th>Email</th>
                                                                        <th>Branch</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="ui-sortable">
                                                                    {result?.data?.items?.map((item, index) => {
                                                                        const element = item?.AssociatedUser
                                                                        const detail = element?.userdetail
                                                                        const office = element?.useroffices
                                                                        return(
                                                                        <tr className="ui-sortable-handle" key={index}>
                                                                            <th className="">{index+1}</th>
                                                                            <td>
                                                                                {element?.username}
                                                                            </td>
                                                                            <td>{decode(detail?.firstname)} {decode(detail?.lastname)}</td>
                                                                            <td>{detail?.phone}</td>
                                                                            <td>{detail?.email}</td>
                                                                            <td>{office?.map(userOffice => userOffice?.office?.name).join(', ')}</td>
                                                                        </tr>
                                                                    )})}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <PaginationComponent currentPage={currentPage} setCurrentPage={setCurrentPage} result={result} />
                                            </div>:
                                            <div className='mt-24 mb-24'>
                                                <Empty
                                                description={<div className="font-sans text-sm text-blue-700">Users Not found!</div>}
                                                />
                                            </div>
                                        }
                                    </Fragment>
                                }
                                

                        </div>
                    </div>
                </div>
            </div>


        </div>
        
    </Fragment>
  )
}

export default SPMUserlist