import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../queries/authQueries';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Empty, Popover, Select, message } from "antd";
import { Pagination, PaginationItem } from "@mui/material";
import LoaderCommon from "./LoaderCommon";
import InputSearch from "./InputSearch";
import SelectSearch from "./SelectSearch";
import { usePaginationStore, userStore } from "../../store/userStore";
import UserData from "../../Data/UserData";
import PaginationComponent from "./PaginationComponent";
import ApplicantViewButton from "./ApplicantViewButton";
import { IconFileDescription } from "@tabler/icons-react";
import LeadStatus from "./LeadStatus";
import moment from "moment";
import { decode } from "html-entities";

const FinishedClientsVisa =()=>{

    const navigate = useNavigate()
    const store = userStore()

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const [result, setResult] = useState([])
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [visa, setVisa] = useState("")
    const [intake, setIntake] = useState("")
    const [office, setOffice] = useState("")

    const [approve, setApprove] = useState(true)
    const [refund, setRefund] = useState(true)
    const [refuse, setRefuse] = useState(true)

    const [intakeList, setIntakeList] = useState([])
    const [officeList, setOfficeList] = useState([])

    const chipData = [
        {id: 17, name: "Visa Approved", state: approve, setState: setApprove},
        {id: 18, name: "Visa Refused", state: refuse, setState: setRefuse},
        {id: 20, name: "Refund Completed", state: refund, setState: setRefund}
    ]

    const visaList = store?.user?.visa?.map(item=>{
        const obj = {id: item?.visatype?.id, name: item?.visatype?.description}
        return obj
    })

    const inputSearchArry = [
        {
            label: "Applicant Name",
            size: 2,
            state: name,
            setState: setName,
            type: "text",
            input: InputSearch
        },
        {
            label: "Email",
            size: 2,
            state: email,
            setState: setEmail,
            type: "text",
            input: InputSearch
        },
        {
            label: "Mobile No",
            size: 2,
            state: mobile,
            setState: setMobile,
            type: "number",
            input: InputSearch
        },
        {
            label: "Visa Type",
            size: 2,            
            state: visa,
            setState: setVisa,
            input: SelectSearch,
            value: visaList,
            name: 'name'
        },
        {
            label: "Intake",
            size: 2,            
            state: intake,
            setState: setIntake,
            input: SelectSearch,
            value: intakeList,
            name: 'intake'
        },
        {
            label: "Office",
            size: 2,            
            state: office,
            setState: setOffice,
            input: SelectSearch,
            value: officeList,
            name: 'name'
        }
    ]
    
    const finishedClientsList = authQueries.useFinishedClientsVisaTypeListMutation(
        (res)=>{
            setResult(res)
        }
    )

    useEffect(()=>{
        let pro = []
        chipData.map(element => {
            if(element.state){
                pro.push(element.id)
            }
        })
        finishedClientsList.mutateAsync({
            size: 25,
            page: currentPage,
            name: name,
            email: email,
            mobile: mobile,
            visa: visa,
            intake: intake,
            office: office,
            prostatus: pro,

        })
    },[currentPage, name, email, mobile, visa, intake, refund, refuse, approve, office])

    const officeListing = authQueries.useOfficeMutationList(
        (res)=>{
            setOfficeList(res?.data?.items)
        }
    )
    
    const intakeListing = authQueries.useIntakeVisaMutationList(
        (res)=>{
            setIntakeList(res?.data)
        }
    )

    useEffect(() => {
        officeListing.mutateAsync({size:1000000000})
    }, []);

    useEffect(() => {
        if(visa){
            intakeListing.mutateAsync(visa)
        }
    }, [visa]);


    const handleChangeChip=(item)=>{
        if(item?.state){
            let pro = []
            chipData.map(element => {
                if(element.state){
                    pro.push(element.id)
                }
            })
            if(pro.length < 2){
                return message.error("Please select at least one status.")
            }
        }

        item.setState(!item?.state)
        
    }

    const handleStatus = (item) => {
        console.log(item.pros_status)
        chipData.map(element => {
            if(element.id !== item.pros_status){
                element.setState(!element?.state)
            }
        })
        
    }

    return(
        <div>
            <div className="add_clients border-none">
                <div className="row">
                    <div className="col-md-12" style={{display:'flex', alignItems:'center', gap:'4px'}}>
                        <i className="followup-icon">
                            <IconFileDescription size="30" className='text-[#7D6FAB]' />
                        </i>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                                <span className="text-[16px] font-medium">Visa Approved / Visa Refused / Refund Completed</span>	
                            </div>
                        </div>
                    </div>
                </div>
                <div className="add_clients_accordion_form flex flex-col mt-4">
                    <div className="card">
                        <div class="card-header"><a class="collapsed card-link" href="" style={{pointerEvents:'none'}}>My Clients</a></div>
                        <div className="p-3 flex flex-col gap-4">
                            <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[955px]:grid-cols-8 max-[717px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-3 border-b boder-solid pb-6 max-[477px]:pb-12 max-[334px]:pb-20">
                                {
                                    inputSearchArry?.map((item, index)=> (
                                        <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                            <item.input item={item} />
                                        </div>
                                    ))
                                }

                                <div className="col-span-6 max-[585px]:col-span-4 max-[445px]:col-span-2 justify-center flex flex-col gap-2">
                                    <div className="text-[12px] h-4 font-[500]">Prospect Status</div>
                                    <div className="flex gap-2 h-10 items-center flex-wrap">
                                        {
                                            chipData.map((item, index)=> (
                                                <div 
                                                key={index} 
                                                onClick={()=>{handleChangeChip(item)}} 
                                                className={`${item.state ? "bg-[#28a745]" : "bg-[#ff4d4f]"} flex items-center h-[30px] rounded-full cursor-pointer justify-start`}
                                                >
                                                    <div className="text-[24px] flex pl-[4px]">
                                                        {
                                                            item.state ?
                                                            <i className='icon-cancel1 text-white'/>:
                                                            <i className='icon-plus1 text-white' />
                                                        }
                                                    </div>
                                                    <div className="text-[10px] text-white font-semibold pl-2 pr-3">
                                                        {item.name}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div>
                                {
                                    finishedClientsList.isLoading ?
                                    <LoaderCommon />:
                                    (
                                        result?.data?.totalItems > 0 ?
                                        <div className="table-list-search-wrpr simple-search">
                                            <div className="search-results-count-wrpr">
                                                {
                                                result?.data?.totalItems > 0 ?
                                                `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                                `O results found`
                                                }
                                            </div>
                                            <div className="table-wrpr mb-0">
                                                <div className="table-section">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <table className="table prospect-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th width="30">#</th>
                                                                        <th>Profile Status</th>
                                                                        <th>Office</th>
                                                                        <th>Date Created</th>
                                                                        <th>Applicant Name</th>
                                                                        <th>Contact Number</th>
                                                                        <th>Visa Type</th>
                                                                        <th>Intake</th>
                                                                        <th>Reg. Date</th>
                                                                        <th>Prospect Status</th>
                                                                        <th>Reffered By</th>
                                                                        <th width='150'>&nbsp;</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        result?.data?.items?.map((item, index) => (
                                                                            <tr>
                                                                                <td className="">{(index + 1) + (result.data?.currentPage * 25)}</td>
                                                                                <td>
                                                                                    <LeadStatus item={item} />
                                                                                </td>
                                                                                <td>{item?.office?.name}</td>
                                                                                <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                                <td>
                                                                                    {decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)}
                                                                                </td>
                                                                                <td>
                                                                                    <div className="email-mobile flex flex-col">
                                                                                        <div>{item.mobile_no}</div>
                                                                                        <div>{item.landline_no}</div>
                                                                                        <div>{item.whatsapp_no}</div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{item?.visatype?.description}</td>
                                                                                <td>{item?.intake?.intake}</td>
                                                                                <td>
                                                                                    {item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                                                </td>
                                                                                <td>{item?.status_of_prospect?.name}</td>
                                                                                <td>{item?.refferal?.refferal}</td>
                                                                                <td className="flex justify-end">
                                                                                    <div className="w-[50px]">
                                                                                        <ApplicantViewButton item={item} />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }									
                                                                </tbody>
                                                            </table>
                                                            <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>:
                                        <div className='mt-24 mb-24'>
                                            <Empty
                                            description={<div className="font-sans text-sm text-blue-700">Clients not found!</div>}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinishedClientsVisa