import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../../queries/authQueries'
import { Link } from 'react-router-dom'
import LoaderCommon from '../../Common/LoaderCommon'
import moment from 'moment';
import { Empty } from 'antd';
import { getCurrentFinancialYear, getNextFinancialYear, getPreviousFinancialYear } from '../../Common/financialYear';


const AssociateReport = () => {

    const currentFinancialYear = getCurrentFinancialYear()
    const nextFinancialYear = getNextFinancialYear()
    const previousFinancialYear = getPreviousFinancialYear()

    const today = new Date()
    const [result, setResult] = useState([])
    const [dateRange, setDateRange] = useState("CURRENT")
    const [fromDate, setFromDate] = useState(today)
    const [toDate, setToDate]= useState(today)

    const associatesReport = authQueries.useAssociatesReportMutation(
        (res)=>{
            setResult(res)
        }
    )

    useEffect(()=>{
        associatesReport.mutateAsync({from: fromDate, to: toDate, type: dateRange })
    },[])


    const onSearch =()=>{
        associatesReport.mutateAsync({from: fromDate, to: toDate, type: dateRange })
    }

    const selectDate=(e)=>{
        setDateRange(e.target.value)
    }


  return (
    <div>

        <div className="breadcrumb-and-otherbuttons">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Associates Report</li>
                </ol>
            </nav>
        </div>
            
        <div className="add_clients">
            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr">
                    <i className="appointments-icon">
                        <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                    </i>
                    <h2><span>&nbsp;</span>Associates Report</h2>	
                </div>
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>
            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header">
                        Associates Report
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">
                            <div className="row ">
                                <div className="col-md-12">
                                    <div className="table-list-search-wrpr table-report-ceo overflow-auto">
                                        <div className="row">
                                            <div className="col-md-6">
                                            </div>
                                            <div className="col-md-6 text-right">
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-12 gap-4 max-[1446px]:grid-cols-10 max-[1094px]:grid-cols-8 max-[949px]:grid-cols-6 max-[640px]:grid-cols-4 max-[483px]:grid-cols-2 !w-full mb-4'>
                                        <div className='col-span-3'>
                                            <label>Select Date</label>
                                            <select value={dateRange} className='form-control form-control-sm !w-full !min-h-[38px]' onChange={selectDate}>
                                                <option value="PREVIOUS">Previous Financial Year ({previousFinancialYear})</option>
                                                <option value="CURRENT">Current Financial Year ({currentFinancialYear})</option>
                                                <option value="FUTURE">Next Financial Year ({nextFinancialYear})</option>
                                                <option value="CUSTOM">Custom Date</option>
                                            </select>
                                        </div>
                                        {dateRange === "CUSTOM" &&
                                            <Fragment>
                                                <div className='col-span-2'>
                                                    <label>From Date</label>
                                                    <input 
                                                    max={moment(toDate).format("YYYY-MM-DD")} 
                                                    value={moment(fromDate).format('YYYY-MM-DD')} 
                                                    type='date' onChange={(e)=> {setFromDate(e.target.value)}} 
                                                    className='form-control form-control-sm !w-full !min-h-[38px]' 
                                                    />
                                                </div>
                                                <div className='col-span-2'>
                                                    <label>To Date</label>
                                                    <input 
                                                    min={moment(fromDate).format("YYYY-MM-DD")} 
                                                    value={moment(toDate).format('YYYY-MM-DD')} 
                                                    type='date' onChange={(e)=> {setToDate(e.target.value)}} 
                                                    className='form-control form-control-sm !w-full !min-h-[38px]' 
                                                    />
                                                </div>
                                            </Fragment>
                                        }
                                        <div className='col-span-1 flex flex-col'>
                                            <label>&nbsp;</label>
                                            <button onClick={onSearch} className='mt-[3px] rounded-md text-[12px] !w-full !min-h-[38px] bg-[#323a51] border-none text-white'>
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                    {associatesReport?.isLoading ?
                                        <LoaderCommon />:
                                        <div className='overflow-x-auto max-h-[600px]'>
                                            {
                                                result?.data?.length > 0 ?
                                                <table border={1} className="table report collageprogram overflow-auto">
                                                    <thead className="sticky top-0">
                                                        <tr className='text-center'>
                                                            <th className='text-left'>Sl No</th>
                                                            <th className='text-left'>Associates Name</th>
                                                            <th>Active Clients</th>
                                                            <th>Registered Client</th>
                                                            <th>Preparing Visa</th>
                                                            <th>Visa Applied</th>
                                                            <th>Visa Approved</th>
                                                            <th>Expected Student Visas</th>
                                                            <th>Not Interested</th>
                                                            <th>Not Qualified</th>
                                                        </tr>
                                                        <AssociateReportTable data={result.grandTotal} />
                                                    </thead>
                                                    <tbody className="h-4 overflow-y-auto">
                                                        {
                                                            result?.data?.map((item, index)=>(
                                                                <tr className='text-[12px] font-bold text-center' key={index}>
                                                                    <td className='font-normal text-left'>{index+1}</td>
                                                                    <td className='font-normal text-left'>
                                                                        <div className='flex flex-col'>
                                                                            <div className='text-[13px] font-medium'>{item?.name}</div>
                                                                            <div>{item?.phone}</div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{item?.activeCount}</td>
                                                                    <td>{item?.registeredCount}</td>
                                                                    <td>{item?.visaPrepareCount}</td>
                                                                    <td>{item?.visaAppliedCount}</td>
                                                                    <td>{item?.visaApprovedCount}</td>
                                                                    <td className='bg-cyan-700 text-white'>{item?.expectVisa}</td>
                                                                    <td>{item?.notInterestedCount}</td>
                                                                    <td>{item?.notQualifiedCount}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                    <tbody>
                                                        <AssociateReportTable data={result.grandTotal} />
                                                    </tbody>

                                                </table>:
                                                <div className='mt-28 mb-28'>
                                                    <Empty description={<div className="text-sm font-poppins">No data found!</div>} />
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      
    </div>
  )
}

export default AssociateReport

const AssociateReportTable = ({data})=>{
    return(
        <Fragment>
            <tr className='text-[13px] !font-bold text-center bg-[#6daee6]'>
                <th></th>
                <th className="text-left">Grand Total</th>
                <td>{data?.activeCount}</td>
                <td>{data?.registeredCount}</td>
                <td>{data?.visaPrepareCount}</td>
                <td>{data?.visaAppliedCount}</td>
                <td>{data?.visaApprovedCount}</td>
                <td>{data?.expectVisa}</td>
                <td>{data?.notInterestedCount}</td>
                <td>{data?.notQualifiedCount}</td>
            </tr>
        </Fragment>
    )
}