import { Autocomplete, Pagination, PaginationItem, TextField } from '@mui/material'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../queries/authQueries'
import { useForm } from 'react-hook-form'
import { Empty, Popconfirm } from 'antd'
import { usePaginationStore, userStore } from '../../store/userStore'
import { Button, Checkbox, Modal } from '@nextui-org/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import LoaderCommon from '../Common/LoaderCommon'
import LeadStatus from '../Common/LeadStatus'
import PaginationComponent from '../Common/PaginationComponent'

const LoanCounselorApplicant = () => {

    const [result, setResult] = useState([])
    const [search, setSearch] = useState([])

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const applicantList = authQueries.useApplicationLoanMutationList(
        (response)=> {
            setResult(response)
        }
    )

    useEffect(()=>{
        applicantList.mutateAsync({
            page:0, 
            size:25
        })
    },[])


    const {register, handleSubmit} = useForm({
	})

    const onSubmit =(data)=>{

        const name = data.name ? data.name : ''
        const nameArr = name.split(' ')
        let fname = ""
        let mname = ""
        let lname = ""
        if(nameArr.length > 2){
            fname = nameArr[0]
            mname = nameArr[1]
            lname = nameArr[2]
        }else if(nameArr.length > 1){
            fname = nameArr[0]
            lname = nameArr[1]
        }else{
            fname = nameArr[0]
        }

        const dataValues = {
            page: 0,
            size: 25,
            fname: fname,
            mname: mname,
            lname: lname,
            email: data.email,
            mobile: data.mobile,
        }

        setSearch(dataValues)
    
        applicantList.mutateAsync(dataValues)
    }

    const handleChange =(event, value)=> {
        search.page = value - 1
        applicantList.mutateAsync(search)
    }

    const confirmApplicant =(e, id)=> {
        confirmApp.mutateAsync({id:id, type:'LOAN'})
    }

    const confirmApp = authQueries.useApplicantExeConfirmMutation(
        (res)=> {
            applicantList.mutateAsync(search)
        }
    )

  return (
    <div>
        <div className="breadcrumb-and-otherbuttons">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Clients</li>
                </ol>
            </nav>
        </div>
				
        <div className="add_clients">
            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr">
                    <i className="appointments-icon">
                        <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <h2><span>My</span>Clients</h2>	
                </div>
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>
            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header">
                        <a className="collapsed card-link"  href="" style={{pointerEvents:'none'}}>
                            Student Requires Loan Assistance
                        </a>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">   


                            <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='grid grid-cols-12 gap-4'>
                                        <div className="col-span-2 flex flex-col">
                                            <div className='text-[12px] h-[25px]'>Applicant Name:</div>
                                            <input placeholder='Applicant Name' {...register('name')} name="name" id="spouse_name" type="text" className="bg-white border border-solid border-gray-300 rounded-md w-full !min-h-[38px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 font-poppins" />
                                        </div>
                                        <div className="col-span-2 flex flex-col">
                                            <div className='text-[12px] h-[25px]'>Phone No:</div>
                                            <input placeholder='Phone No' {...register('mobile')} name="mobile" id="spouse_name" type="text" className="bg-white border border-solid border-gray-300 rounded-md w-full !min-h-[38px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 font-poppins"  />
                                        </div>
                                        <div className="col-span-2 flex flex-col">
                                            <div className='text-[12px] h-[25px]'>Email:</div>
                                            <input placeholder='Email' {...register('email')} name="email" id="spouse_name" type="text" className="bg-white border border-solid border-gray-300 rounded-md w-full !min-h-[38px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 font-poppins" />
                                        </div>
                                        <div className='col-span-2 flex flex-col'>
                                            <div className='h-[25px]'></div>
                                            <button type="submit" className="btn-customized-large w-[120px] h-[35px] rounded-md">
                                                Search
                                            </button>
                                        </div>
                                    </div>
                            </form>

                            <div className="table-list-search-wrpr simple-search mt-3">

                                <div className="row">
                                    <div className="col-md-12 search-results-count-wrpr">
                                        Search Results {result?.data?.items?.length} of {result?.data?.totalItems}
                                    </div>
                                </div>

                                {applicantList?.isLoading ?
                                    <LoaderCommon />:
                                    <Fragment>
                                        {result?.data?.totalItems > 0 &&
                                            <div className="table-wrpr">
                                                <div className="table-section">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <table className="table prospect-table mb-0">
                                                                <thead>
                                                                    <tr className='alt-colors'>
                                                                        <th width="30">#</th>
                                                                        <th>Profile Status</th>
                                                                        <th>Office</th>
                                                                        <th>Date Created</th>
                                                                        <th>Applicant Name</th>
                                                                        <th>Contact Number</th>
                                                                        <th>Visa Type</th>
                                                                        <th>Intake</th>
                                                                        <th>Counselor</th>
                                                                        <th>Reg. Date</th>
                                                                        <th>Prospect Status</th>
                                                                        <th>Reffered By</th>
                                                                        <th width='150'>&nbsp;</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="ui-sortable">
                                                                    {result?.data?.items?.map((item, index) => (
                                                                        <tr className="ui-sortable-handle" key={index}>
                                                                            <th className="">{(index+1) + (result?.data?.currentPage * 25)}</th>
                                                                            <td>
                                                                                <LeadStatus item={item} />
                                                                            </td>
                                                                            <td>{item?.office?.name}</td>
                                                                            <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                            <td>
                                                                                {item?.applicant_name} {item?.middlename} {item?.lastname} 
                                                                            </td>
                                                                            <td>
                                                                                <div className="email-mobile flex flex-col">
                                                                                    <div>{item.mobile_no}</div>
                                                                                    <div>{item.landline_no}</div>
                                                                                    <div>{item.whatsapp_no}</div>
                                                                                </div>                                                                    
                                                                            </td>
                                                                            <td>{item?.visatype?.description}</td>
                                                                            <td>{item?.intake?.intake}</td>
                                                                            <td>{item?.assignUser?.CounselorAssign?.userdetail?.firstname} {item?.assignUser?.CounselorAssign?.userdetail?.lastname}</td>
                                                                            <td>
                                                                                {item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                                            </td>
                                                                            <td>
                                                                                {item?.status_of_prospect?.name}
                                                                            </td>
                                                                            <td>{item?.refferal?.refferal}</td>
                                                                            <td>
                                                                                <Popconfirm
                                                                                placement="left"
                                                                                title={item?.loan === "NO" ? "Are you sure student requires loan assistance?" : "Are you sure student not requires loan assistance?" }
                                                                                onConfirm={(event) => {confirmApplicant(event, item.id)}}
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                                >
                                                                                    <Checkbox isSelected={item?.loan === "YES" ? true:false}>
                                                                                        <span style={{fontSize:'12px', fontWeight: 400}}>Student Requires Loan Assistance</span>
                                                                                    </Checkbox>
                                                                                </Popconfirm>
                                                                            </td>
                                                                        </tr>
                                                                    ))}												  
                                                                </tbody>
                                                            </table>
                                                            {applicantList?.data?.data?.totalPages > 1 &&
                                                            <ul className="pagination pagination-sm justify-content-center table-pagination mt-5 custom-pagination">
                                                                <Pagination
                                                                    count={result?.data?.totalPages}
                                                                    nextText="Next"
                                                                    prevText="Previous"
                                                                    renderItem={
                                                                        (item) => (
                                                                            <div>
                                                                            <PaginationItem 
                                                                            component="li"
                                                                            {...item}
                                                                            className={`!bg-white !text-[12px] ${item.selected && '!border-rose-500'}`}
                                                                            />
                                                                            </div>
                                                                        )
                                                                    }
                                                                    variant="outlined"
                                                                    page={result?.data?.currentPage + 1}
                                                                    shape="rounded"                
                                                                    onChange={handleChange}
                                                                />
                                                            </ul>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            result?.data?.totalItems === 0 &&
                                            <div style={{marginTop:'5%', marginBottom: '10%'}} className="text-center flex justify-center">
                                                <Empty
                                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                                    imageStyle={{ height: 80, display:'flex', justifyContent:'center' }}
                                                    description={
                                                    <h6>
                                                        Sorry! No Leads found.
                                                    </h6>
                                                    }
                                                >
                                                </Empty>
                                            </div>
                                        }
                                    </Fragment>
                                }
                            </div>

                        </div>
                    </div>
                </div>               
            </div>
        </div>
    </div>
  )
}

export default LoanCounselorApplicant