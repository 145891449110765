import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import { Select as SelectSingle } from 'antd';


export const AutoCompleteChild = ({control, data, name, value, error}) => {
    return (
    <div>

        {!value && (
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange } }) => (
                <Select
                    placeholder={<div>Search or Select</div>}
                    className={error?'form-error':null}
                    options={data}
                    onChange={(val) => onChange(val.id)}
                />
                )}
                // rules={{ required: true }}
            />
        )}
        {value && (
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange } }) => (
                <Select
                    placeholder={<div>Search or Select</div>}
                    className={error?'form-error':null}
                    options={data}
                    onChange={(val) => {
                        onChange(val.value);
                    }}
                    defaultValue={value.value?value:{label:'Search or select'}}
                />
                )}
                // rules={{ required: true }}
            />
        )}

    </div>
  )
}


export const AutoCompleteIntake = ({control, data, name, value, error}) => {
    return (
    <div>

      {!value && (
          <Controller
              name={name}
              control={control}
              render={({ field: { onChange } }) => (
              <Select
                  placeholder={<div>Search or Select</div>}
                  className={error?'form-error':null}
                  options={data}
                  onChange={(val) => onChange(val.id)}
              />
              )}
              // rules={{ required: true }}
          />
      )}
      {value && (
          <Controller
              name={name}
              control={control}
              render={({ field: { onChange } }) => (
              <Select
                  placeholder={<div>Search or Select</div>}
                  className={error?'form-error':null}
                  options={data}
                  onChange={(val) => {
                      onChange(val.value);
                  }}
                  defaultValue={value.value?value:{label:'Search or select'}}
              />
              )}
              // rules={{ required: true }}
          />
      )}

    </div>
  )
}



export const AutoCompleteChilds = ({control, data, name, value, emailValid, mobileValid, email, mobile,setVisaId, error, dis, whatsappValid, whatsapp, landline, landlineValid }) => {
    return (
      <div>
  
        {!value && (
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange } }) => (
                <SelectSingle
                  showSearch
                  size='large'
                  filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder={<div>Search or Select</div>}
                  className={error?'form-error':null}
                  mode='single'
                  allowClear
                  disabled={dis}
                  style={{
                      width: '100%',
                  }}
                  options={data}
                  onChange={(val) => {
                    console.log(val)
                      onChange(val)
                      setVisaId(val)
                      emailValid({visa:val, email:email}); 
                      mobileValid({number:mobile, visa:val, type:"MOBILE"});
                      whatsappValid({number:whatsapp, visa:val, type:"WHATSAPP"});
                      landlineValid({number:landline, visa:val, type:"LANDLINE"});
                  }}
                />
                )}
              //   rules={{ required: true }}
            />
        )}
          
        {value && (
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange } }) => (
                <SelectSingle
                      showSearch
                      filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={<div>Search or Select</div>}
                      className={error?'form-error':null}
                      mode='single'
                      allowClear
                      style={{
                          width: '100%',
                      }}
                      options={data}
                      onChange={(val) => {
                        console.log(val)
                        onChange(val);
                        setVisaId(val)
                        emailValid({visa:val, email:email}); 
                        mobileValid({number:mobile, visa:val, type:"MOBILE"});
                        whatsappValid({number:whatsapp, visa:val, type:"WHATSAPP"});
                        landlineValid({number:landline, visa:val, type:"LANDLINE"});
                    }}
                    disabled={dis}
                    defaultValue={value?value:{label:'Search or select'}}
                />
                )}
              //   rules={{ required: true }}
            />
        )}
  
      </div>
    )
  }
  



export const AutoCompleteAntd = ({control, data, name, value, emailValid, mobileValid, email, mobile,setVisaId, error, dis }) => {
  return (
    <div> 
      {!value && (
          <Controller
              name={name}
              control={control}
              render={({ field: { onChange } }) => (
                <SelectSingle
                    showSearch
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={<div>Search or Select</div>}
                    className={error?'form-error]':null}
                    mode='single'
                    allowClear
                    style={{
                        width: '100%',
                    }}
                    onChange={(val) => {
                        onChange(val)
                    }}
                    disabled={dis}
                    options={data}
                />
              )}
            //   rules={{ required: true }}
          />
      )}
      {value && (
          <Controller
              name={name}
              control={control}
              render={({ field: { onChange } }) => (
                <SelectSingle
                    size='large'
                    showSearch
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={<div>Search or Select</div>}
                    className={error?'form-error':null}
                    mode='single'
                    allowClear
                    style={{
                        width: '100%',
                    }}
                    onChange={(val) => {
                        onChange(val)
                    }}
                    options={data}
                    disabled={dis}
                    defaultValue={value}
                />
              )}
            //   rules={{ required: true }}
          />
      )}
    </div>
  )
}

export const AutoCompleteAntdOffice = ({control, data, name, value, error, setOffice, dis }) => {
  return (
    <div> 
      {!value && (
          <Controller
              name={name}
              control={control}
              render={({ field: { onChange } }) => (
                <SelectSingle
                    showSearch
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={<div>Search or Select</div>}
                    className={error?'form-error':null}
                    mode='single'
                    allowClear
                    style={{
                        width: '100%',
                    }}
                    onChange={(val) => {
                        onChange(val)
                        setOffice(val)
                    }}
                    options={data}
                    disabled={dis}
                />
              )}
            //   rules={{ required: true }}
          />
      )}
      {value && (
          <Controller
              name={name}
              control={control}
              render={({ field: { onChange } }) => (
                <SelectSingle
                    size='large'
                    showSearch
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={<div>Search or Select</div>}
                    className={error?'form-error':null}
                    mode='single'
                    allowClear
                    style={{
                        width: '100%',
                    }}
                    onChange={(val) => {
                        onChange(val)
                        setOffice(val)
                    }}
                    options={data}
                    disabled={dis}
                    defaultValue={value}
                />
              )}
            //   rules={{ required: true }}
          />
      )}
    </div>
  )
}

export const AutoCompleteAntdInteded = ({control, data, name, value, setValue, error }) => {

  return (
    <div> 
      {!value && (
          <Controller
              name={name}
              control={control}
              render={({ field: { onChange } }) => (
                <SelectSingle
                    showSearch
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={<div>Search or Select</div>}
                    className={error?'form-error':null}
                    mode='single'
                    allowClear
                    style={{
                        width: '100%',
                    }}
                    onChange={(val) => {
                        setValue(val)
                        onChange(val)
                    }}
                    options={data}
                />
              )}
            //   rules={{ required: true }}
          />
      )}
      {value && (
          <Controller
              name={name}
              control={control}
              render={({ field: { onChange } }) => (
                <SelectSingle
                    showSearch
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={<div>Search or Select</div>}
                    className={error?'form-error':null}
                    mode='single'
                    allowClear
                    style={{
                        width: '100%',
                    }}
                    onChange={(val) => {
                        onChange(val)
                        setValue(val)
                    }}
                    options={data}
                    defaultValue={value}
                />
              )}
            //   rules={{ required: true }}
          />
      )}
    </div>
  )
}