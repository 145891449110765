import React, { useEffect, useState } from "react";
import { socket } from "./socket";
import { Button, Space, message as messages, notification } from "antd";
import { useViewProfile, userStore } from '../store/userStore';
import authQueries from "../queries/authQueries";
import { useNavigate } from "react-router-dom";


const SocketNotification =()=> {

  
    const storeData = userStore()
    const navigate = useNavigate()
    const setApplicationId = useViewProfile((state) => state.setAppId)

    useEffect(()=>{
      // socket.on("chat", (message) => {
      //   console.log(message)
      // })
      socket.on('profile_update', (message) => {
         if(message.id === storeData.user.id){
          const user = storeData?.user
          // user.name = message?.name
          const data = {
            ...user,
            image: message?.image,
            name: message?.name
          }
          
          storeData.setUser(data)
          
          openProfileUpdate(message)
         }
      });
    },[])

    useEffect(()=> {
      notify()
    },[])


    const notify = async()=> {
      socket.on('notification', (message) => {
        if(message?.rid){
            if(message.uid === storeData?.user?.id && message.rid === storeData?.user?.rid){
              openNotification(message?.appid, message?.message, message?.details)
            }
        }else{
          if(message.uid === storeData?.user?.id){
            openNotification(message?.appid, message?.message, message?.details)
          }
        }
      });

      socket.on('notificationCount', (message) => {

        let data = storeData.notificationCount
        if(!data){
          data = []
        }
        let alreadyExist = data?.find(item => item.id === message.id)

        if(alreadyExist){
          alreadyExist.count = message.count
          storeData.setNotificationCount(data)
        }else{
          data.push({id: message.id, count:message.count})
          storeData.setNotificationCount(data)
        }

      })

    }

    const viewData = async(id) => {
      await setApplicationId(id)  
      navigate('/applicantdetails')
    }
  
    const [api, contextHolder] = notification.useNotification()
    const [profile, contextProfile] = notification.useNotification()
  
    const openNotification = (appid, message, details) => { 
        const btn = (
            <Space>
              {
                appid &&
                <Button type="primary" size="small" onClick={()=>{viewData(appid)}}>
                    View Applicant
                </Button>
              }
            </Space>
        );
        api.open({  
              duration: '12',
              placement: 'topRight',
              message: <span style={{fontSize:'13px', fontWeight:600, color:'#1269fd', fontFamily:'Poppins'}}>{message}</span>,
              description: <span style={{fontSize:'13px', fontWeight:'lighter', color:'#1269fd', color:'#434242'}}>{details}</span>,
              btn,
              className: 'custom-class',
              style: {
              width: 400,
            },
        });
    };

    const openProfileUpdate = (message) => { 
      profile.open({  
            duration: '12',
            placement: 'topRight',
            message: <span style={{fontSize:'13px', fontWeight:600, color:'#1269fd', fontFamily:'Poppins'}}>{message?.message}</span>,
            description: <span style={{fontSize:'13px', fontWeight:'lighter', color:'#1269fd', color:'#434242'}}>{message?.description}</span>,
            className: 'custom-class',
            style: {
            width: 400,
          },
      })
  }


  // const uid = storeData.user

  // useEffect(() => {
  //   socket.emit('login', uid.username);
  //   socket.on('updateActiveUsers', (users) => {
  //     storeData.setActiveUsers(users)
  //   });

  //   const tabActivityInterval = setInterval(() => {
  //     socket.emit('tabActivity', !document.hidden);
  //   }, 5000);

  //   return () => {
  //     clearInterval(tabActivityInterval);
  //     socket.emit('logout');
  //   };
  // }, [uid.username]);


  return (
    <div>
      {contextHolder}
      {contextProfile}
    </div>
  )
}

export default SocketNotification
