import { Modal } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import AutoCompleteInput from '../../Common/AutoCompleteInput'
import InputSelectForm from '../../Common/InputSelectForm'
import { generateValidationSchema } from '../../Common/validationSchema'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { userStore } from '../../../store/userStore'
import authQueries from '../../../queries/authQueries'
import toast from 'react-hot-toast'

const EditIntentedProgramModal = ({item, programListing, campusList, collegeResult, intakeResult, viewAllApplicationData, setCollege, college}) => {

    const [visible, setVisible] = useState(false)
    const [pgm, setPgm] = useState({name:"", link: ""})
    const [program, setProgram] = useState("")
    
    const selectCollege =(value)=>{
        setValue('program', '')
        setValue('campus', '')
        setCollege(value)
    }

    const appStore  = userStore()

    const inputFields = [
        {
            name: "intake",
            placeholder: "Select or Enter Intake",
            label: "Intake",
            size: "6",
            type: AutoCompleteInput,
            required: true,
            options: intakeResult,
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            name: "college",
            placeholder: "Select or Enter College/University",
            label: "College/University",
            size: "6",
            type: AutoCompleteInput,
            required: true,
            setState: selectCollege,
            options: collegeResult,
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            name: "campus",
            placeholder: "Select or Enter Campus",
            label: "Campus",
            size: "6",
            type: AutoCompleteInput,
            required: false,
            options: campusList?.data?.data?.items,
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            name: "program",
            placeholder: "Select or Enter Program",
            label: "Program",
            size: "6",
            type: AutoCompleteInput,
            setState: setProgram,
            required: true,
            options: programListing?.data?.data?.items,
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            name: "placement",
            label: "Placement",
            size: "6",
            type: InputSelectForm,
            required: false,
            options: [{name:"WITH PLACEMENT"}, {name:"WITHOUT PLACEMENT"}],
            optionProps:{name: "name", name2: "", value: "name", value2: "", advanced: false}
        },
    ]

    const { register, reset, control, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    });

    const handleOpen =()=>{
        setCollege(item?.intended_college_or_university)
        const resetData = {
            college: item?.intended_college_or_university,
            program: item?.intended_program,
            campus: item?.camp_id,
            placement: item?.placement ? item?.placement : null,
            intake: item?.intake_id
        }
        reset(resetData)
        setVisible(true)
    }

    const handleClose =()=>{
        setVisible(false)
    }

    useEffect(()=>{
        const pro = programListing?.data?.data?.items?.find(element => element.value === program)
        if(pro){
            setPgm({name: pro?.label, link: pro?.link})
            return
        }
        setPgm({name:"", link: ""})
        return
    }, [program])

    const editIntentProgram = authQueries.useIntProgramEdit(
        (response) => {
            if(response?.message === "success"){
                handleClose()
                toast.success("Successfully updated a program")
                viewAllApplicationData.mutateAsync(appStore.appid)
            }
        }
    );

    const onSubmit = (data) => {
        const datavalues = {
            id: item?.id,
            applicant_id: appStore.appid,
            intended_college_or_university: data.college,
            campus: data.campus ? data.campus : null,
            intended_program: data.program,
            intake: data.intake,
            placement: data.placement ? data.placement : null,
            status: "ACTIVE"
        }

        editIntentProgram.mutateAsync(datavalues);
    }


  return (
    <Fragment>
        <button onClick={handleOpen} className='btn-customized-large h-[30px] w-[60px]'>
            Edit
        </button>
        <Modal
        open={visible}
        onCancel={handleClose}
        footer={null}
        width="50%"
        >
            <div className='flex flex-col gap-6 font-poppins'>
                <div>
                    <div className='text-[14px] font-medium'>Update Intended Program</div>
                </div>
                <form>
                    <div className='flex flex-col justify-center gap-6'>
                        <div className={`grid grid-cols-12 gap-x-6 gap-y-3`}>
                            {
                                inputFields?.map((item, index)=>(
                                    <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                        <item.type item={item} register={register} errors={errors} control={control} options={item?.options} optionProps={item?.optionProps} />
                                    </div>
                                ))
                            }
                        </div>
                        <div>
                            {pgm?.link &&
                                <div className='flex flex-wrap text-[12px] gap-4 bg-green-200 py-2 px-4 rounded-lg'>
                                    <div>{pgm?.name}</div>
                                    <a className='text-blue-500' href={pgm?.link}>{pgm?.link}</a>
                                </div>
                            }
                        </div>
                        <div className='flex justify-end gap-5'>
                            <button 
                            onClick={handleClose}
                            className='bg-red-500 text-white px-8 py-[8px] rounded-md text-[12px] font-semibold' 
                            type='button'
                            >
                            Cancel
                            </button>
                            <button 
                            onClick={handleSubmit(onSubmit)} 
                            className='bg-primary text-white px-8 py-[8px] rounded-md text-[12px] font-semibold' 
                            type='submit'
                            >
                            Update
                            </button>
                        </div>
                    </div>
                </form>
            </div>

        </Modal>
    </Fragment>
  )
}

export default EditIntentedProgramModal
