import React, { Fragment } from 'react'
import HOT from '../../assets/images/hot-icon-2.png'
import WARM from '../../assets/images/warm-icon-2.png'
import COLD from '../../assets/images/cold-icon-2.png'
import REG from '../../assets/images/registered-icon-2.png'
import LONG from '../../assets/images/longterm-icon-3.png'
import HOLD from '../../assets/images/on-hold-icon.png'

const LeadStatus = ({item}) => {
  return (
    <Fragment>
        <div className="type-wrpr">
            <img
            className="type-icon hot" 
                src={
                    item.lead_status === "ONHOLD" ? HOLD :
                    item.lead_status === "LONG" ? LONG :
                    item.registered_status === "YES" ? REG : 
                    item?.lead_status === "HOT" ? HOT : 
                    item?.lead_status === "WARM" ? 
                    WARM : item?.lead_status === "COLD" && COLD
                }
                width="" 
                height="" 
                alt="" 
            />
            <span 
            style={{
            marginLeft:'5px',
            color: (item.lead_status === "LONG" || item.lead_status === "ONHOLD") ? '#F00' : item.registered_status === "YES" && "#1C4C3B",
            fontWeight: 600
            }}
            >
            {
                item.lead_status === "ONHOLD" ? "ON HOLD" :
                item.lead_status === "LONG" ? "LONG TERM" :
                item.registered_status === "YES" ? "REGISTERED" : 
                item?.lead_status === "HOT" ? "HOT" : 
                item?.lead_status === "WARM" ? "WARM" : 
                item?.lead_status === "COLD" && "COLD"
            }
            </span>
        </div>
    </Fragment>
  )
}

export default LeadStatus
