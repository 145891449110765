import { Modal } from '@nextui-org/react'
import { decode } from 'html-entities'
import moment from 'moment'
import React, { Fragment, useState, useEffect } from 'react'
import { userStore } from '../../store/userStore'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import authQueries from '../../queries/authQueries'
import { useForm } from 'react-hook-form'
import { useApi } from './ApiProvider'
import VisaStaffStatusOptions from './VisaStaffStatusOptions'
import LoaderCommon from './LoaderCommon'
import ModalHeaderText from './ModalHeaderText'
import { IconChevronDown } from '@tabler/icons-react'

const ProspectStatusList = ({item, readonly, defaultStatus}) => {

    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState([])
    const [loader, setLoader] = useState(true)
    const [applicant, setApplicant] = useState({})

    const store = userStore()

    const handleOpen =()=>{
        setOpen(true)
        setLoader(true)
        setApplicant(item)
        listStatus.mutateAsync({role: store?.user?.rid, visa: item?.visa_type_id, showstatus: item?.registered_status})
    }

    const handleClose =()=>{
        setOpen(false)
    }

    const listStatus = authQueries.useStatusOfProspectsRoleList(
		(response) => {
			setStatus(response)
            setLoader(false)
		}
	);

  return (
    <Fragment>
        <div onClick={handleOpen} style={{color:'#00f'}} className="btn-fee cursor-pointer">
            {defaultStatus ? defaultStatus : item?.status_of_prospect?.name}
        </div>
        <Modal
        open={open}
        closeButton
        onClose={handleClose}
        className="followup-modal"
        centered
        blur
        animationDuration={1000}
        overlayId="modal-overlay"
        width='90%'
        scroll
        >
            <Modal.Header>
                <ModalHeaderText label="Prospect Status" />
            </Modal.Header>
            <Modal.Body>
                {
                    loader ?
                    <LoaderCommon />:
                    <StatusCreate item={applicant} status={status} readonly={readonly} />
                }
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default ProspectStatusList


const StatusCreate =({item, status, readonly})=> {

	const [statusResult, setStatusResult] = useState([])

    const store = userStore()

    const { setApiDataAndNotify } = useApi();

	useEffect(()=> {
	  listProspect.mutateAsync(item?.id)
	}, [])
  
	const validationSchema = yup.object({
	  comment: yup
		  .string('⚠ Please enter comments')
		  .required('⚠ comments is required'),
	  prospect: yup
		  .string('⚠ Please Select Prospect Status')
		  .required('⚠ Prospect Status is required'),
	});
    
	const onSubmit = (data) => {
	  const datavalues = {
		  applicant_id: item?.id,
		  prospects_id: data.prospect,
		  comments: data.comment,
		  status: "ACTIVE"
	  }
	  addProspect.mutateAsync(datavalues);
	}
  
	const addProspect = authQueries.useProStatusAdd(
	  (response) => {
		const dataValues = {
		  prospect: '',
		  comment: ''
		}
		reset(dataValues)
        setApiDataAndNotify(response)
		listProspect.mutateAsync(item?.id)
	  }
	);
  
	const { register , reset, handleSubmit, formState:{ errors }} = useForm({
		resolver: yupResolver(validationSchema)
	});
  
	const listProspect = authQueries.useProStatusList(
	  (response) => {
		setStatusResult(response)
	  }
	);
  
	return(
        <div className="row">
            {!readonly &&
                <div className="col-md-3">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex flex-col gap-3'>
                            <div className="flex flex-col gap-1">
                                <div className='text-[12px] h-4 font-[500]'>Status</div>
                                    <select 
                                    {...register('prospect')} 
                                    name="prospect" 
                                    className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
                                    >
                                    <option value="">Select Status</option>
                                    {store?.user?.role !== "VISASTAFF" ?
                                        (status?.data?.map((pros, index) => {
                                            if(pros?.id === 2 && item?.registered_status === "YES" && store?.user?.role === "COUNSELOR"){
                                                return
                                            }
                                            return(
                                                <option value={pros?.id} key={index}>{decode(pros?.name)}</option>
                                            )
                                        })):
                                        <VisaStaffStatusOptions status={status?.data} current={item?.pros_status} />
                                    }
                                    </select>
                                <div style={{color:'#f00', fontSize:"13px"}}>{errors?.prospect?.message}</div>
                            </div>
                            <div className="flex flex-col gap-1">
                                <div className='text-[12px] h-4 font-[500]'>Comment</div>
                                <textarea
                                {...register('comment')}
                                name="comment" 
                                className="bg-white border border-solid border-gray-300 rounded-md w-full min-h-[140px] p-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
                                rows="5"
                                ></textarea>
                                <div style={{color:'#f00', fontSize:"13px"}}>{errors?.comment?.message}</div>
                            </div>
                            <div>
                                {
                                    (store?.user?.role === 'COUNSELOR' && item?.assignUser?.counselor !== store?.user?.id) ?
                                    <button type="button" disabled className='btn-customized-large opacity-70 cursor-not-allowed'>Submit</button>:
                                    <button type="submit" className="btn-customized-large">Submit</button>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            }
            <div className={readonly ? "col-md-12":"col-md-9"}>
                <div className="table-wrpr">
                    <div className="table-section">
                        <div className="row">
                            <div className='col-md-12'>
                                <table className="table prospect-table">
                                    <thead>
                                        <tr>
                                            <th width="90">Date</th>
                                            <th width="300">Status</th>
                                            <th width="400">Comment</th>
                                            <th width="90">Commented By</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {
                                        statusResult.data?.map((item, index) => (
                                            <tr className="ui-sortable-handle">
                                                <td width="100">{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                                                <td width="130">{decode(item?.status_of_prospect?.name)}</td>
                                                <td width="40">{item?.comments}</td>
                                                <td width="120">{item?.createdBy?.userdetail?.firstname} {item?.createdBy?.userdetail?.lastname}</td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	)
}
