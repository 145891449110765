import React, { useEffect, useState } from "react"
import authQueries from '../../../queries/authQueries'
import { Link, useNavigate } from "react-router-dom"
import { Modal, useModal, Button, Text } from "@nextui-org/react"
import { userStore } from '../../../store/userStore'

const CountryWise =({fromDate,toDate})=>{
    const user = userStore();
    const [result, setResult] = useState([])
    const [status, setStatus] = useState('')
    const [visaDesc, setvisaDesc] = useState('')
    const [dataType, setDataType] = useState('')
    const [applicantresult, setApplicantresult] = useState([])
    const [visibleStatus, setVisibleStatus] = useState(false)

    let office=''
    let officeCount =''
    let applicantName=''
    let PhoneNum=''
    let intakeNum=''

    useEffect(()=>{
        user.setToggleMenu(false);
        countrywiseReportApi.mutateAsync({from: fromDate, to:toDate, page:0, size:10});
    },[])

    useEffect(()=>{
        countrywiseReportApi.mutateAsync({from: fromDate, to:toDate, page:0, size:10});
    },[fromDate,toDate])


    const countryAPI = (fromDate,toDate ) => {
        countrywiseReportApi.mutateAsync({from: fromDate, to:toDate, page:0, size:10});
    }


    const handleCloseStatus = () => {
        setVisibleStatus(false)
    }

    let DocpendingTotal=0;
    let docpendingIelts=0;
    let fullDocs=0;
    let fullDocsonly=0;
    let fullDefered=0;
    let awaitingOfr=0;
    let ConditionalOffer=0;
    let UnConditionalOffer=0;
    let OfferAccepted=0;
    let NotProceeding=0;
    let TotalProcessingVisa=0;
    let TotalVisaApplied=0;
    let TotalVisaApproved=0;
    let TotalDeferRequested=0;
    let RefundRequest=0;
    let VisaRefused=0;
    let ExpectedVisa=0;


    
    const countrywiseReportApi = authQueries.useCountrywiseReport(
        (response) => {
            console.log("response",response.data[0])
            setResult(response.data[0])        
        }   
    
    )

    // const handlefromDate = (event) => {
    //     const value = event.target.value;
    //     console.log("fromdate",value)
    //     setfromDate(value);
    // };

    // const handletoDate = (event) => {
    //     const value = event.target.value;
    //     console.log("todate",value)
    //     settoDate(value);
    // };

    const filterData = (e) => {
        countrywiseReportApi.mutateAsync({ from: fromDate, to:toDate, page:0, size:10  });
        setStatus(e.target.value)
      }


    const statusModalOpen =(type, visa, desc, country)=>{
        setVisibleStatus(true)
        setvisaDesc(desc)
        setDataType(type)
        if(type==1)
        {
            setStatus("Document Pending Details")
        }else if(type==2)
        {
            setStatus("Document Pending with ielts Details")
        }else if(type==3)
        {
            setStatus("Full Docs")
        }else if(type==4)
        {
            setStatus("Full Docs from Previous Intake")
        }else if(type==5)
        {
            setStatus("Full Docs(Not even a single application moved)")
        }else if(type==6)
        {
            setStatus("Awaiting Offer")
        }else if(type==7)
        {
            setStatus("Conditional Offer")
        }else if(type==8)
        {
            setStatus("Unconditional Offer")
        }else if(type==9)
        {
            setStatus("Offer Accepted")
        }else if(type==10)
        {
            setStatus("Not Proceeding with offer")
        }else if(type==11)
        {
            setStatus("Preparing Visa")
        }else if(type==12)
        {
            setStatus("Visa Applied")
        }else if(type==13)
        {
            setStatus("Visa Approved")
        }else if(type==14)
        {
            setStatus("Refund Requested")
        }else if(type==15)
        {
            setStatus("Visa Refused")
        }else if(type==16)
        {
            setStatus("Defer Requested")
        }
        listApplicant.mutateAsync({type:type, visa: visa, country:country ,from: fromDate, to:toDate})
    }

    const listApplicant = authQueries.useCountryApplicantList(
        (response) => {
            setApplicantresult(response.data[0])
             
            console.log("response data==>",response.data[0])
        }
    )



    return(
        <div>

            <div className="breadcrumb-and-otherbuttons">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Country Wise - Visa Status Report</li>
                    </ol>
                </nav>
            </div>
            

            <div className="add_clients" style={{marginBottom:'0px'}}>
                
                    <div className="row">
                        <div className="col-md-6 appointments-hing-wrpr">
                            <i className="appointments-icon">
                                <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                            </i>
                            <h2><span>&nbsp;</span>Country Wise - Visa Status Report</h2>    
                        </div>
                            
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
                    </div>
                    

                    <div id="accordion" className="add_clients_accordion_form"  style={{borderBottom:'none'}}>
                        <div  className="card" id="appointments_wrpr_employee">
                        <div className="card-header">
                            Country Wise - Visa Status Report
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                    
                                <div className="row ">
                                    <div className="col-md-12">
                                        <div className="table-list-search-wrpr">
                                    
                                            <table border={1} className="table report collageprogram  countrywise">
                                                <thead>
                                                    <tr>
                                    
                                                    <th>Country</th>
                                                    <th style={{ width: '100px' }}>Visa Type</th>
                                                    <th>Docs Pending</th>
                                                    <th >Docs Pending with IELTS</th>
                                                    <th >Full Docs from Current/Future Intake</th>
                                                    <th >Full Docs from Previous Intake</th>
                                                    <th >Full Docs(Not even a single application moved)</th>
                                                    <th >Awaiting Offer</th>
                                                    <th >Conditional Offer</th>
                                                    <th >UnConditional Offer</th>
                                                    <th >Not Proceeding with offer</th>
                                                    <th >Offer Accepted</th>
                                                    <th >Preparing Visa/Preparing Visa After refusal</th>
                                                    <th >Visa Applied</th>
                                                    <th >Visa Approved</th>
                                                    <th >Visa Refused</th>
                                                    <th >Defer requested</th>
                                                    <th >Refund Requested</th>
                                                   
                                                    <th >Expected Visa</th>
                                                    </tr>
                                                </thead>
                                                <tbody  className="ui-sortable">
                                                { countrywiseReportApi.isLoading && "Loading" }
                                                {   
                                                    result?.map((item, index) =>
                                                    {  
                                        
                                                        DocpendingTotal=DocpendingTotal+item.DocPending;
                                                        docpendingIelts=docpendingIelts+item.DocPendingielts;
                                                        fullDocs=fullDocs+item.fullDocs;
                                                        fullDefered=fullDefered+item.fullDefered;
                                                        fullDocsonly=fullDocsonly+item.fullDocsonly;
                                                        awaitingOfr=awaitingOfr+item.awaiting_offer;
                                                        ConditionalOffer=ConditionalOffer+item.ConditionalOffer;
                                                        UnConditionalOffer=UnConditionalOffer+item.UnConditionalOffer;
                                                        OfferAccepted=OfferAccepted+item.offeraccepted;
                                                        NotProceeding=NotProceeding+item.notpreceeding;
                                                        TotalProcessingVisa=TotalProcessingVisa+item.totalProcessingVisa;
                                                        TotalVisaApplied=TotalVisaApplied+item.totalvisaapplied;
                                                        TotalVisaApproved=TotalVisaApproved+item.totalvisaapproved;
                                                        TotalDeferRequested=TotalDeferRequested+item.deferRequested;
                                                        RefundRequest=RefundRequest+item.refundrequested;
                                                        VisaRefused=VisaRefused+item.visarefused;
                                                        ExpectedVisa=ExpectedVisa+item.expected_visa;

                                                    return(
                                                        <tr className="ui-sortable-handle">
                                                        <td>{item.country }</td>
                                                        <td>{item.description }</td>
                                                        <td><a  onClick={()=>{statusModalOpen(1, item.visaTypeId, item.description, item.id)}}  href="#">{item.DocPending?item.DocPending:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(2, item.visaTypeId, item.description, item.id)}}  href="#">{item.DocPendingielts?item.DocPendingielts:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(3, item.visaTypeId, item.description, item.id)}}  href="#">{item.fullDocs?item.fullDocs:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(5, item.visaTypeId, item.description, item.id)}}  href="#">{item.fullDefered?item.fullDefered:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(4, item.visaTypeId, item.description, item.id)}}  href="#">{item.fullDocsonly?item.fullDocsonly:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(6, item.visaTypeId, item.description, item.id)}}  href="#">{item.awaiting_offer?item.awaiting_offer:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(7, item.visaTypeId, item.description, item.id)}}  href="#">{item.ConditionalOffer?item.ConditionalOffer:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(8, item.visaTypeId, item.description, item.id)}}  href="#">{item.UnConditionalOffer?item.UnConditionalOffer:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(10, item.visaTypeId, item.description, item.id)}}  href="#">{item.notpreceeding?item.notpreceeding:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(9, item.visaTypeId, item.description, item.id)}}  href="#">{item.offeraccepted?item.offeraccepted:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(11, item.visaTypeId, item.description, item.id)}}  href="#">{item.totalProcessingVisa?item.totalProcessingVisa:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(12, item.visaTypeId, item.description, item.id)}}  href="#">{item.totalvisaapplied?item.totalvisaapplied:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(13, item.visaTypeId, item.description, item.id)}}  href="#">{item.totalvisaapproved?item.totalvisaapproved:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(15, item.visaTypeId, item.description, item.id)}}  href="#">{item.visarefused?item.visarefused:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(16, item.visaTypeId, item.description, item.id)}}  href="#">{item.deferRequested?item.deferRequested:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(14, item.visaTypeId, item.description, item.id)}}  href="#">{item.refundrequested?item.refundrequested:0}</a></td>
                                                        
                                                        <td>{item.expected_visa?item.expected_visa:0}</td>
                                                        </tr>
                                                    )})
                                                }
                                                 <tr>
                                                    <td  colSpan={2}><b>Total</b></td>
                                                    <td ><b>{DocpendingTotal}</b></td>
                                                    <td><b>{docpendingIelts}</b></td>
                                                    <td><b>{fullDocs}</b></td>
                                                    <td><b>{fullDefered}</b></td>
                                                    <td><b>{fullDocsonly}</b></td>
                                                    <td><b>{awaitingOfr}</b></td>
                                                    <td><b>{ConditionalOffer}</b></td>
                                                    <td><b>{UnConditionalOffer}</b></td>
                                                    <td><b>{NotProceeding}</b></td>
                                                    <td><b>{OfferAccepted}</b></td>
                                                    <td><b>{TotalProcessingVisa}</b></td>
                                                    <td><b>{TotalVisaApplied}</b></td>
                                                    <td><b>{TotalVisaApproved}</b></td>
                                                    <td><b>{TotalDeferRequested}</b></td>
                                                    <td><b>{RefundRequest}</b></td>
                                                    <td><b>{VisaRefused}</b></td>
                                                    <td><b>{ExpectedVisa}</b></td>
                                                </tr>
                                            </tbody>

                                            </table>
                                    </div>
                                </div>
                                
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    
                </div>
                
            </div>

        <Modal
            scroll
            blur
            closeButton
            width="70%"
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={visibleStatus}
            onClose={handleCloseStatus}
        >
            <Modal.Header>
                <Text b size="16px" className="text-center">{status} - {visaDesc}</Text>
            </Modal.Header>
            <Modal.Body>
                <table className="table report collageprogram alt-colors">
                    <thead>
            
                        <tr style={{background:'rgb(50 58 81 )'}}>
                        {(dataType==1 || dataType==2 || dataType==3 || dataType==4 || dataType==5 || dataType==6 || dataType==7 || dataType==8)?<th width="10%">Branch</th>:null}
                        <th width="20%">Name</th>
                        <th width="20%">Phone</th>
                        {dataType==15 || dataType==9 || dataType==10 || dataType==11 || dataType==12 || dataType==13 || dataType==14?<th width="10%">Email</th>:null}
                        {dataType==15 || dataType==9 || dataType==10 || dataType==11 || dataType==12 || dataType==13 || dataType==14?<th width="10%">Branch</th>:null}
                        <th width="20%">Active Intake</th>
                        {dataType==9 || dataType==10 || dataType==11 || dataType==12 || dataType==13 || dataType==14 || dataType==15?<th width="10%">Counselor</th>:null}
                        <th width="20%">College</th>
                        <th width="20%">Program</th>
                        {dataType==9 || dataType==10 || dataType==11 || dataType==12 || dataType==13 || dataType==14 || dataType==15?<th width="10%">Status</th>:null}
                        {dataType==1 || dataType==2 || dataType==3 || dataType==4 || dataType==5 || dataType==6 || dataType==7 || dataType==8?<th width="20%">Program Code</th>:null}
                        {dataType==1 || dataType==2 || dataType==3 || dataType==4 || dataType==5 || dataType==6 || dataType==7 || dataType==8?<th width="20%">Program Intake</th>:null}
                        {dataType==1 || dataType==2 || dataType==3 || dataType==4 || dataType==5 || dataType==6 || dataType==7 || dataType==8?<th width="20%">Counselor</th>:null}
                        {dataType==1 || dataType==2 || dataType==3 || dataType==4 || dataType==5 || dataType==6 || dataType==7 || dataType==8?<th width="20%">Doc Staff</th>:null}
                        {dataType==1 || dataType==2 || dataType==3 || dataType==4 || dataType==5 || dataType==6 || dataType==7 || dataType==8?<th width="20%">Visa Staff</th>:null}
                        {dataType==3 || dataType==4 || dataType==5 ?<th width="20%">Application status</th>:null}
                        {dataType==6 || dataType==7 || dataType==8 ?<th width="20%">Offer Type</th>:null}
                        {dataType==6 || dataType==7 || dataType==8 ?<th width="20%">Application Sub Date</th>:null}
                        {dataType==6 || dataType==7 || dataType==8 ? <th width="20%">No of days after submitting the application</th>:null}
                        {dataType==1 || dataType==2?<th width="30%">No Of days Followup Pending</th>:null}
                        {dataType==3 || dataType==4 || dataType==5 ?<th width="30%">No Of days after moved to full docs</th>:null}
                        </tr>

                    </thead>
                    <tbody id="sortable6" className="ui-sortable">
                    {
                            applicantresult?.map((item, index) => 
                            {

                                let offices = applicantresult?.filter(function(office){
                                    return  office.office == item.office
                                })

                                //console.log("offices",offices)

                                let applicants = applicantresult?.filter(function(applicant){
                                    return  applicant.id == item.id
                                })

                                let phone = applicantresult?.filter(function(phon){
                                    return  phon.mobile_no == item.mobile_no && item.mobile_no!='' && item.mobile_no!=null
                                })

                                let intakes = applicantresult?.filter(function(intak){
                                    return  intak.intake == item.intake && item.intake!='' && item.intake!=null
                                }) 

                                return(
                               
                                    <tr className="ui-sortable-handle">
                                       
                                        {(office!=item.office && (dataType==1 || dataType==2 || dataType==3 || dataType==4 || dataType==5 || dataType==6 || dataType==7 || dataType==8))?<td rowSpan={offices.length}>{office=item.office }</td>: null}
                                        {applicantName!=item.applicant_name?<td rowSpan={applicants.length}>{applicantName=item.applicant_name }</td>: null}
                                        {PhoneNum!=item.mobile_no && item.mobile_no!=null ?<td rowSpan={phone.length==0?1:phone.length}>{ PhoneNum=item.mobile_no }</td>: null} {item.mobile_no==null?<td></td>:null}
                                        {dataType==15 || dataType==9 || dataType==10 || dataType==11 || dataType==12 || dataType==13 || dataType==14?<td>{item.email}</td>:null}
                                        {dataType==15 || dataType==9 || dataType==10 || dataType==11 || dataType==12 || dataType==13 || dataType==14?<td>{item.office}</td>:null}
                                        <td >{item.intake}</td>
                                        {dataType==15 || dataType==9 || dataType==10 || dataType==11 || dataType==12 || dataType==13 || dataType==14?<td>{item.counselor}</td> :null}
                                        <td>{item.college_name}</td> 
                                        <td>{item.pname}</td> 
                                        {dataType==1 || dataType==2 || dataType==3 || dataType==4 || dataType==5 || dataType==6 || dataType==7 || dataType==8?<td>{item.intended_program}</td>  :null}
                                        {dataType==1 || dataType==2 || dataType==3 || dataType==4 || dataType==5 || dataType==6 || dataType==7 || dataType==8?<td>{item.intake}</td> :null}
                                        {dataType==1 || dataType==2 || dataType==3 || dataType==4 || dataType==5 || dataType==6 || dataType==7 || dataType==8?<td>{item.counselor}</td> :null}
                                        {dataType==1 || dataType==2 || dataType==3 || dataType==4 || dataType==5 || dataType==6 || dataType==7 || dataType==8?<td>{item.admission}</td> :null}
                                        {dataType==1 || dataType==2 || dataType==3 || dataType==4 || dataType==5 || dataType==6 || dataType==7 || dataType==8?<td>{item.visastaff}</td> :null}
                                        {dataType==15 || dataType==9 || dataType==10 || dataType==11 || dataType==12 || dataType==13 || dataType==14?<td>{item.status}</td> :null}
                                        {dataType==6 || dataType==7 || dataType==8 ?<td>{item.offertype}</td>:null}
                                        {dataType==6 || dataType==7 || dataType==8 ?<td>{item.application_submision_date}</td>:null}
                                        {dataType==3 || dataType==4 || dataType==5 ?<td>{item.application_status}</td>:null}
                                        {dataType==1 || dataType==2 || dataType==3 || dataType==4 || dataType==5 || dataType==6 || dataType==7 || dataType==8?<td>0</td>:null}
                                    </tr>
                            ); })
                            
                    }
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>

    </div>
    )    
}
export default CountryWise