import { Modal } from '@nextui-org/react'
import React, { Fragment, useState } from 'react'
import LoaderCommon from './LoaderCommon'
import authQueries from '../../queries/authQueries'
import { CalendarPlus } from 'lucide-react'
import { message } from 'antd'
import { useApi } from './ApiProvider'
import toast from 'react-hot-toast'

const roleItems = [
    {id: 3, label: "Counselor"},
    {id: 5, label: "Document Staff"},
    {id: 7, label: "Admission Staff"},
    {id: 8, label: "Visa Staff"},
    {id: 9, label: "Fee Coordinator"},
    {id: 6, label: "Program Coordinator"},
]

const AssignModal = ({item, roles, selectedRole, label, height, assignToProgram, fontSize}) => {

    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState("")
    const [result, setResult] = useState([])
    const [role, setRole] = useState(selectedRole)
    const [selectItem, setSelectItem] = useState("")
    const [office, setOffice] = useState(item?.office?.id)

    const { setApiDataAndNotify } = useApi()

    const counselorList = authQueries.useCounselorAssignList(
        (response) => {
            setResult(response)
        }   
    );

    const officeList = authQueries.useOfficeMutationList(
        (response) => {
        }   
    );

    const handleOpen =()=> {
        setOpen(true)
        officeList.mutateAsync({size: 10000, page:0})
        counselorList.mutateAsync({search:search, role:role, office: office})
    }

    const handleClose =()=> {
        setSelectItem("")
        setOpen(false)
    }
    
    const roleSelect =(value)=> {
        setRole(value)
        counselorList.mutateAsync({search:search, role:value, office: office})
    }

    const searchUser =(value)=> {
        setSearch(value)
        counselorList.mutateAsync({search:value, role:role, office: office})
    }

    const addAssign = authQueries.useAssignAdd(
        (response) => {
            if(selectedRole === 6){
                if(!response?.reassign){
                    assignToProgram()
                }
            }
            setApiDataAndNotify(response)
            toast.success(`Assigned Successfully`);
            setSearch("")
            setSelectItem("")
        }   
    );

    const assigningConfirm = () => {
        const dataValues = {
            applicant_id: item?.id,
            user_id: selectItem,
            role_id: role,
            status: 'ACTIVE'
        }
        handleClose()
        addAssign.mutateAsync(dataValues);
    }

    const selectOffice =(value)=>{
        setOffice(value)
        counselorList.mutateAsync({search:search, role:role, office: value})
    }

  return (
    <Fragment>

        <button className='btn-customized' style={{height: height && height, fontSize: fontSize ? fontSize:"10px"}} onClick={handleOpen}>{label}</button>

        <Modal
        open={open}
        closeButton={false}
        onClose={handleClose}
        scroll
        blur
        width="65%"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        >
            <Modal.Body>
                <div className='flex w-full justify-between mt-2'>
                    <div className="flex gap-2">
                        <div>
                            Assign to {roleItems?.find(element => element.id === parseInt(role))?.label}
                        </div>
                    </div>
                    <button onClick={handleClose} className='text-[12px]'>Close</button>
                </div>
                <div className='flex justify-end'>
                    <div className="grid grid-cols-4 gap-3 w-3/5 max-[1600px]:w-3/4 max-[1400px]:w-4/5 max-[1200px]:w-full">
                        <div className='col-span-1'>
                            <select
                            className='bg-[#f0f0f0] p-[9px] pl-3 w-full text-[13px] rounded-md focus:outline-none'
                            value={role}
                            onChange={(e)=>roleSelect(e.target.value)}
                            type="text"
                            >
                                {
                                    roleItems?.map((role, index) => {
                                        const exist = roles?.find(element => element === role?.id)
                                        if(!exist){
                                            return
                                        }
                                        return(
                                            <option key={index} value={role?.id}>{role?.label}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='col-span-2'>
                            <select
                            className='bg-[#f0f0f0] p-[9px] pl-3 text-[13px] w-full rounded-md focus:outline-none'
                            value={office}
                            onChange={(e)=>{selectOffice(e.target.value)}}
                            type="text"
                            >
                                {
                                    officeList?.data?.data?.items?.map((off, index) => {
                                        return(
                                            <option key={index} value={off?.id}>{off?.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='col-span-1'>
                            <input
                            className='bg-[#f0f0f0] p-[9px] pl-3 text-[13px] rounded-md focus:outline-none w-full'
                            value={search}
                            onChange={(e)=>{searchUser(e.target.value)}}
                            placeholder="Search User"
                            type="text"
                            />
                        </div>
                    </div>
                </div>

                {
                    counselorList.isLoading ?
                    <LoaderCommon />:
                    <div className="grid grid-cols-3 max-xl:grid-cols-2 max-md:grid-cols-1 gap-3 mt-1">
                        {result?.data?.map((user, index) => (  
                            <div
                            key={index}
                            onClick={()=>{setSelectItem(user.id)}}
                            style={{background: user.id === selectItem && '#000'}}
                            className={`col-span-1 flex custom-color text-white p-[12px] rounded-xl items-center gap-x-3`}
                            >
                                <div className="counsellor-photo-wrpr">
                                    <img src={user?.image? `${user?.image}` : require("../../assets/images/profile-avatar.jpg")} width="" height="" alt="" />
                                </div>
                                <div className="counsellor-name flex flex-col gap-y-1">
                                    <div className='text-[16px]'>{user?.firstname} {user?.lastname}</div>
                                    <div className="flex items-center gap-x-1">
                                        <span className="flex justify-center items-center text-[12px] bg-[#f8c207] p-[2px] rounded-full w-[25px] h-[25px]">{user.progress}</span> 
                                        <span className="text-[12px]">Clients</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                } 



            </Modal.Body>
            <Modal.Footer>
                {
                    result?.data?.length > 0 &&
                    <div>
                        <button
                        disabled={!selectItem ? true : false}
                        style={{cursor: selectItem ? 'pointer' : 'not-allowed'}}
                        onClick={assigningConfirm}
                        type="button" className="btn-customized-large w-[110px] h-[36px] gap-2">
                            <CalendarPlus size="16" />
                            Assign
                        </button>
                    </div>
                }
            </Modal.Footer>
        </Modal>

    </Fragment>
  )
}

export default AssignModal
