import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../queries/authQueries';
import { Link } from "react-router-dom";
import { userStore } from '../../store/userStore';
import moment from "moment";
import { Empty, Popover, Select, Skeleton, Spin } from "antd";
import { Avatar, Collapse, Grid, Loading, Text, Button } from "@nextui-org/react";
import FollowupToday from "./FollowupToday";
import { IconClipboardList } from "@tabler/icons-react";
import { SageMakerFeatureStoreRuntime } from "aws-sdk";
import LoaderCommon from "./LoaderCommon";

const today = new Date();
const yesterday = new Date();
yesterday.setDate(today.getDate() - 1);

const type = [
    {
        name: "Today",
        value: "TODAY",
        date: [today, today]
    },
    {
        name: "Yesterday",
        value: "YSTRDAY",
        date: [yesterday, yesterday]
    },
    {
        name: "Custom",
        value: "CUSTOM",
        date: [today, today]
    },
]

const DailyReportCEO =()=>{

    const store = userStore()

    const [result, setResult] = useState([])
    const [dateType, setDateType] = useState({
        name: "Today",
        value: "TODAY",
        date: [new Date(), new Date()]
    })

    const [office, setOffice] = useState("")
    const [user, setUser] = useState("")

    useEffect(()=> {
        OfficeList.mutateAsync({size: 500})
    },[])

    const OfficeList = authQueries.useOfficeMutationList(
        (res)=>{
        }
    )

    const usersList = authQueries.useOfficeWiseOnlyCounselorListMutation(
        (res)=>{
        }
    )
    
    const dailyFollowReport = authQueries.useDailyFollowReportCEOMutation(
        (res)=>{
            setResult(res)
        }
    )

    const onSubmit =()=>{
        dailyFollowReport.mutateAsync({from: dateType?.date[0], to: dateType?.date[1], office: office, user: user})
    }

    const selectDate =(e)=> {
        const newDate = type?.find(item => item?.value === e.target.value)
        setDateType(newDate)
    }

    const handleDateChange =(e, index)=> {
        if(index === 0){
            setDateType({
                name: "Custom",
                value: "CUSTOM",
                date: [e.target.value, dateType?.date[1]]
            })
        }else if(index === 1){
            setDateType({
                name: "Custom",
                value: "CUSTOM",
                date: [dateType?.date[0], e.target.value]
            })
        }
    }

    const handleChange =(e)=>{
        usersList.mutateAsync(e.target.value)
        setOffice(e.target.value)
    }

    return(
        <Fragment> 

            <div className="row">
                <div className="col-md-12" style={{display:'flex', alignItems:'center', gap:'4px'}}>
                    <i className="followup-icon">
                        <IconClipboardList size="30" className='text-[#7D6FAB]' />
                    </i>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                            <span className="text-[16px] font-medium">Followup Done / Followup Comment</span>
                            <span style={{background:'gold', borderRadius:'30px', height:'25px', width:'25px', display:'flex', alignItems:'center', justifyContent:'center', fontSize:'12px'}}>{result?.data ? result?.data?.length : 0}</span>
                        </div>
                        <div style={{marginTop:'-5px'}}>
                            <span style={{fontSize:'14px'}}>{dateType?.name} {dateType.value === "CUSTOM" && `( ${moment(dateType?.date[0]).format('DD/MM/YYYY')} - ${moment(dateType?.date[1]).format('DD/MM/YYYY')} )`}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div id="accordion" className="add_clients_accordion_form mt-4">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header">
                        <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                            Followup Done / Followup Comment
                        </a>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">

                            <div className="grid grid-cols-12 gap-3 mb-4">
                                <div className="col-span-2">
                                    <select 
                                    className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 font-poppins" 
                                    value={office} 
                                    onChange={handleChange} >
                                        <option value="">Select Office</option>
                                        {store?.user?.role === "CEO" ?
                                            
                                            OfficeList?.data?.data?.items?.map((item, index) => (
                                                <option key={index} value={item?.id}>{item?.name}</option>
                                            )) : 

                                            store?.user?.office?.map((item, index) => (
                                                <option key={index} value={item?.oid}>{item?.office?.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-span-2">
                                    <select
                                    className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 font-poppins" 
                                    value={user} 
                                    onChange={(e)=>setUser(e.target.value)}>
                                        <option value="">Select User</option>
                                        {
                                            usersList?.data?.data?.map((item, index) => (
                                                <option key={index} value={item?.id}>{item?.userdetail?.firstname} {item?.userdetail?.lastname}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-span-2">
                                    <select
                                    className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 font-poppins" 
                                    onChange={selectDate}
                                    >
                                        {
                                            type?.map((item, index) => (
                                                <option key={index} value={item?.value}>{item?.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                {
                                    dateType?.value === "CUSTOM" &&
                                    <Fragment>
                                        <div className="col-span-2">
                                            <input 
                                            max={moment(dateType?.date[1]).format("YYYY-MM-DD")} 
                                            onChange={(e) => handleDateChange(e, 0)} 
                                            value={moment(dateType?.date[0]).format("YYYY-MM-DD")} 
                                            type="date" 
                                            className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 font-poppins"
                                            />
                                        </div>
                                        <div className="col-span-2">
                                            <input 
                                            min={moment(dateType?.date[0]).format("YYYY-MM-DD")} 
                                            max={moment(today).format('YYYY-MM-DD')} 
                                            onChange={(e) => handleDateChange(e, 1)} 
                                            value={moment(dateType?.date[1]).format("YYYY-MM-DD")} 
                                            className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 font-poppins" 
                                            type="date"/>
                                        </div>
                                    </Fragment>
                                }
                                <div className="col-span-2">
                                    <button disabled={dailyFollowReport.isLoading} onClick={onSubmit} className="btn-customized-large h-full w-[60%]">
                                        <div style={{display:'flex', alignItems:'center', gap:'12px'}}>
                                            {
                                                dailyFollowReport.isLoading &&
                                                <Loading color="white" size="xs" />
                                            }
                                            <div>Generate Report</div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            {
                                dailyFollowReport?.isLoading ?
                                <LoaderCommon />:
                                <Fragment>
                                    {
                                        result?.data?.length > 0 ?
                                        <div className="table-wrpr">
                                            <div className="table-section">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <table className="table prospect-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th style={{minWidth:'140px'}}>Applicant Name</th>
                                                                    <th style={{minWidth:'140px'}}>Visa Type</th>
                                                                    <th>Email</th>
                                                                    <th>Phone</th>
                                                                    <th style={{minWidth:'140px'}}>Status</th>
                                                                    <th>Comments</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    result?.data?.map((item, index) => (
                                                                        <tr>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item?.applicant?.applicant_name} {item?.applicant?.middlename} {item?.applicant?.lastname}</td>
                                                                            <td>{item?.applicant?.visatype?.country?.icon} {item?.applicant?.visatype?.description}</td>
                                                                            <td>{item?.applicant?.email}</td>
                                                                            <td>{item?.applicant?.mobile_no}</td>
                                                                            <td>{item?.status?.name}</td>
                                                                            <td>{item?.comment}</td>
                                                                            <td>
                                                                                <Popover 
                                                                                    trigger={"hover"} 
                                                                                    content={
                                                                                        <div>
                                                                                            <div>Followup Date on <span style={{color:'blue', fontWeight:600}}>{moment(item?.followup?.followupdate).format("DD-MM-YYYY")}</span></div>
                                                                                            <div>Followup Assigned to <span style={{color:'blue', fontWeight:600}}>{item?.followup?.assigned?.userdetail?.firstname} {item?.followup?.assigned?.userdetail?.lastname}</span></div>
                                                                                            <div>Followup Created By <span style={{color:'blue', fontWeight:600}}>{item?.followup?.createdBy?.userdetail?.firstname} {item?.followup?.createdBy?.userdetail?.lastname}</span></div>
                                                                                        </div>
                                                                                    }
                                                                                    placement="left"
                                                                                >
                                                                                    <div style={{cursor:'pointer'}}>
                                                                                        <i className="icon-info_outline" style={{fontSize:'16px'}}></i>
                                                                                    </div>
                                                                                </Popover>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }									
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>:
                                        <div className="my-[70px]">
                                            <Empty description={<div className="text-[14px] font-medium font-poppins">No Data Found!</div>} />
                                        </div>
                                        
                                    }
                                </Fragment>
                            }

                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default DailyReportCEO