import React, {Fragment, useEffect, useState} from 'react'
import { userStore } from '../../../store/userStore'
import { Link } from "react-router-dom";
import authQueries from '../../../queries/authQueries'
import Academic from './Academic'
import ApplicantDetails from './ApplicantDetails'
import DocumentDetails from './DocumentDetails'
import HOT from '../../../assets/images/hot-icon-2.png'
import WARM from '../../../assets/images/warm-icon-2.png'
import COLD from '../../../assets/images/cold-icon-2.png'
import LONG from '../../../assets/images/longterm-icon-3.png'
import HOLD from '../../../assets/images/on-hold-icon.png'
import LoaderCommon from '../../Common/LoaderCommon';

const ClientLayout =() => {

	const [proStatus, setProStatus] = useState('HOT');
	const user = userStore();
	const appStore = userStore();
	const [loading, setLoading] = useState(true)
	
	const viewAllApplicationEditData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
				setProStatus(response?.data?.applicant?.lead_status)
				setLoading(false)
            }
        }
    )

	useEffect(()=> {
		if(appStore.appid){
			viewAllApplicationEditData.mutateAsync(appStore.appid)
		}else{
			setLoading(false)
		}
	}, [])

	const activeStyle = {
		display:'flex', 
		alignItems:'center', 
		gap:4,
		borderRadius:'5px', 
		background:'#EF0', 
		padding:'8px', 
		paddingLeft:'20px', 
		paddingRight:'20px'
	}

	const normalStyle = {
		display:'flex', 
		alignItems:'center', 
		gap:4,
		cursor:'pointer'
	}

  return (

    <div>

		<div className="breadcrumb-and-otherbuttons">
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb">
				<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
				<li className="breadcrumb-item active" aria-current="page">Add Clients</li>
				</ol>
			</nav>
		</div>
				
		<div className="add_clients">	
			<div className="row" style={{alignItems:'center'}}>
				<div className="col-md-3 appointments-hing-wrpr">
					<i className="appointments-icon">
						<img src={require('../../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
					</i>
					<h2><span>{appStore.appid?'Update':'Add'}</span>Clients</h2>	
				</div>
				{user.user.role !== "DOCUMENTSTAFF" &&
				<div className="col-md-9 hotwarmcold-wrpr justify-flex-end" style={{display:'flex',justifyContent:'flex-end'}}>
					<div style={{display:'flex', background:'#fff', alignItems:'center', gap:20, borderRadius:'10px', padding:"8px"}}>
						<div className='pl-3' style={{fontSize:'12px', fontWeight: 600}}>Profile Status</div>
						<div className="profile-status-btn-wrpr gap-3 pr-2">
							<div style={proStatus === "HOT" ? activeStyle : normalStyle} onClick={()=>{setProStatus("HOT")}} >
								<img src={HOT} width="12px" />
								<span style={{fontSize:'12px', fontWeight: 600}}>Hot</span>
							</div>
							<div style={proStatus === "WARM" ? activeStyle : normalStyle} onClick={()=>{setProStatus("WARM")}}>
								<img src={WARM} width="15px" />
								<span style={{fontSize:'12px', fontWeight: 600}}>Warm</span>
							</div>
							<div style={proStatus === "COLD" ? activeStyle : normalStyle} onClick={()=>{setProStatus("COLD")}}>
								<img src={COLD} width="12px" />
								<span style={{fontSize:'12px', fontWeight: 600}}>Cold</span>
							</div>
							<div style={proStatus === "ONHOLD" ? activeStyle : normalStyle} onClick={()=>{setProStatus("ONHOLD")}}>
								<img src={HOLD} width="17px" />
								<span style={{fontSize:'12px', fontWeight: 600}}>On Hold</span>
							</div>
							<div style={proStatus === "LONG" ? activeStyle : normalStyle} onClick={()=>{setProStatus("LONG")}}>
								<img src={LONG} width="17px" />
								<span style={{fontSize:'12px', fontWeight: 600}}>Long Term</span>
							</div>
						</div>		
					</div>
				</div>	
				}
			</div>


			<div id="accordion" className="add_clients_accordion_form">
			
				{
					user.user.role !== "DOCUMENTSTAFF" &&
					<Fragment>
						{
							loading?
							<LoaderCommon />:
							<ApplicantDetails lead_status={proStatus} />

						}
					</Fragment>
				}
					
				{
					user.user.role === "DOCUMENTSTAFF" &&
						<Fragment>
							{
								viewAllApplicationEditData.isLoading ?
								<LoaderCommon />:
								<DocumentDetails />
							}
						</Fragment>
				} 
	

			</div>


		</div>
	</div>
    
  )
}

export default ClientLayout