// import React, { Fragment, useEffect, useRef, useState } from 'react'
// import { usePaginationStore, userStore } from '../../store/userStore'
// import { useNavigate } from 'react-router-dom'
// import authQueries from '../../queries/authQueries'
// import FollowUp from './FollowUp'
// import LeadStatus from './LeadStatus'
// import moment from 'moment'
// import HOT from '../../assets/images/hot-icon-2.png'
// import WARM from '../../assets/images/warm-icon-2.png'
// import COLD from '../../assets/images/cold-icon-2.png'
// import REG from '../../assets/images/registered-icon-2.png'
// import LONG from '../../assets/images/longterm-icon-3.png'
// import { Empty, Tabs } from 'antd'
// import ProspectStatusList from './ProspectStatusList'
// import { useApi } from './ApiProvider'
// import ApplicantViewButton from './ApplicantViewButton'
// import { Pagination, PaginationItem } from '@mui/material'

// const leadtypeItems = [
//     {
//         label: "Registered",
//         value: "REGISTER",
//         image: REG,
//         width: "16px"
//     },
//     {
//         label: "Hot",
//         value: "HOT",
//         image: HOT,
//         width: "14px"
//     },
//     {
//         label: "Warm",
//         value: "WARM",
//         image: WARM,
//         width: "20px"
//     },
//     {
//         label: "Cold",
//         value: "COLD",
//         image: COLD,
//         width: "16px"
//     },
//     {
//         label: "Long Term",
//         value: "LONGTERM",
//         image: LONG,
//         width: "20px"
//     }
// ]

// const FollowupPendingLead = () => {

//     const store = userStore()
//     const navigate = useNavigate()

//     const [result, setResult] = useState([])
//     const [type, setType] = useState("REGISTER")
//     const [currentPage, setCurrentPage] = useState(0)

//     const followupRef = useRef(null);

//     const { apiData } = useApi()

//     const pendingList = authQueries.useCounselorDashboardPendingMutation(
// 		async (response) => {
// 			setResult(response?.data)
// 		}
// 	)

//     useEffect(() => {
// 		pendingList.mutateAsync({status: type, page: currentPage})
//     }, [type, apiData, currentPage]);


//     const handleChange =(item)=>{
//         setType(item?.value)
//         handleReset()
//     }

//     const handleChangePagination = (event, value) => {
//         setCurrentPage(value-1)
//         setTimeout(() => {
//             followupRef.current.scrollIntoView({ behavior: 'smooth' });
//         }, 0);
//     }

//     const handleReset =()=>{
//         setCurrentPage(0)
//     }

//     const followupView = (follow) => {
//         let lastFollowup = new Date()
//         for(let i = 0; i < follow?.length; i++){
//             if(i === 0){
//                 lastFollowup = new Date(follow[i]?.followupdate)
//             }
//         }

//         return moment(lastFollowup).fromNow()
//     }

//   return (
//     <Fragment>
//         <div className="followup-hding-section" ref={followupRef}>
//             <div>
//                 <div style={{display:'flex'}} className="followup-wrpr mb-3">
//                     <h2>
//                         Follow-up pending for last 7 days
//                     </h2>
//                     <div style={{
//                         background:'#ffc75e', 
//                         fontSize:'14px',
//                         padding:"4px", 
//                         height:"26px", 
//                         minWidth:'26px', 
//                         borderRadius:'15px', 
//                         marginLeft:'10px',
//                         display:'flex',
//                         justifyContent:'center'
//                     }}>{result?.totalItems}</div>
//                 </div>
//             </div>
//         </div>

//         <div className="table-wrpr tab-wrpr tab-bg">		
            
//             <div className='flex gap-2'>
//                 {
//                     leadtypeItems?.map((item, index)=> (
//                         <div
//                         style={{background: type === item?.value ? "#C0EEF2": "#fff"}}
//                         onClick={()=>{handleChange(item)}}
//                         key={index} 
//                         className='flex items-center text-[13px] gap-2 py-[7px] px-3 rounded-md cursor-pointer'>
//                             <div>
//                                 <img src={item?.image} style={{width:item?.width}} />
//                             </div>
//                             <div>{item?.label}</div>
//                         </div>
//                     ))
//                 }
//             </div>
            
//             {
//                 result?.totalItems > 0 ?
//                 <div className='mt-[17px]'>	
//                     <div className="table-section">
//                         <div className="row">
//                             <div className="col-md-12">
//                                 <table className="table prospect-table">
//                                     <thead>
//                                         <tr>
//                                             <th>#</th>
//                                             <th>Profile Status</th>
//                                             <th>Office</th>
//                                             <th>Date Created</th>
//                                             <th>Applicant Name</th>
//                                             <th>Contact No.</th>
//                                             <th>Visa Type</th>
//                                             <th>Intake</th>
//                                             <th>Reg Date</th>
//                                             <th>Prospect Status</th>
//                                             <th>Reffered By</th>
//                                             <th>Not Follow Up</th>
//                                             <th></th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                     {result?.items?.map((item, index) => (
//                                         <tr key={index}>
//                                             <td>{(index + 1) + (result?.currentPage * 25)}</td>
//                                             <td>
//                                                 <LeadStatus item={item} />
//                                             </td>
//                                             <td>{item?.office?.name}</td>
//                                             <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
//                                             <td>
//                                                 {item?.applicant_name} {item?.middlename} {item?.lastname}
//                                             </td>
//                                             <td>
//                                                 <div className="email-mobile flex flex-col">
//                                                     <div>{item.mobile_no}</div>
//                                                     <div>{item.landline_no}</div>
//                                                     <div>{item.whatsapp_no}</div>
//                                                 </div>    
//                                             </td>	
//                                             <td>{item?.visatype?.country?.icon} {item?.visatype?.description}</td>
//                                             <td>{item?.intake?.intake}</td>			
//                                             <td>{item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}</td>		
//                                             <td>
//                                                 <ProspectStatusList item={item} />
//                                             </td>
//                                             <td>{item?.refferal?.refferal}</td>
//                                             <td>{item?.followups.length > 0 ? followupView(item?.followups) : 'No follow-ups'}</td>	
//                                             <td className='flex justify-end'>
//                                                 <div className='grid grid-cols-12 w-[175px] gap-1'>
//                                                     <div className='col-span-9'>
//                                                         <FollowUp item={item}  />
//                                                     </div>
//                                                     <div className='col-span-3'>
//                                                         <ApplicantViewButton item={item}  />
//                                                     </div>
//                                                 </div>
//                                             </td>
//                                         </tr>
//                                     ))}
//                                     </tbody>
//                                 </table>
//                                 {result?.totalPages > 1 &&
//                                 <ul className="pagination pagination-sm mt-2 justify-content-center table-pagination custom-pagination">
//                                     <Pagination
//                                         count={result?.totalPages}
//                                         renderItem={
//                                             (item) => (
//                                                 <div>
//                                                 <PaginationItem
//                                                 component="li"
//                                                 {...item}
//                                                 className={`!bg-white !text-[12px] ${item.selected && '!border-rose-500'}`}
//                                                 />
//                                                 </div>
//                                             )
//                                         }
//                                         variant="outlined"
//                                         page={result?.currentPage + 1}
//                                         shape="rounded"
//                                         onChange={handleChangePagination}
//                                     />
//                                 </ul>
//                             }
//                             </div>
//                         </div>
//                     </div>
//                 </div>:
//                 <div className='w-full flex justify-center py-4'>
//                     <Empty description={<div className='font-poppins font-medium'>Data not found!</div>} />
//                 </div>
//             }

//         </div>
//     </Fragment>
//   )
// }

// export default FollowupPendingLead

import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useApi } from './ApiProvider'
import authQueries from '../../queries/authQueries'

const FollowupPendingLead = () => {

    const { setApiDataAndNotify } = useApi()

    const pendingList = authQueries.useCounselorDashboardAutoFollowupMutation(
		async (response) => {
			setApiDataAndNotify(response)
		}
	)

    useEffect(() => {
		pendingList.mutateAsync()
    }, []);

}

export default FollowupPendingLead

