import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { Modal, useModal, Button, Text } from "@nextui-org/react"
import { userStore } from '../../../store/userStore'


const CounselorRegistration =()=>{
    const user = userStore();
    const [result, setResult] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [status, setStatus] = useState('');
    const [visibleStatus, setVisibleStatus] = useState(false)
    const [applicantresult, setApplicantresult] = useState([])
    let staffTotal=0;
    let office='';
    let serialNo=0;
    let totalRegPrevFinancialYr=0;
    let totalRegCurrFinancialYr=0;
    let totalRegPrevMonth=0;
    let totalRegCurrMonth=0;
    let totalInactive30Day=0;
    let totalInactive60Day=0;
    let totalInactive90Day=0;
    let totalRegPrevMonthastoday=0;
    let totalRegyeartilldate=0;
    let totalfeepaidpryr=0;
    let totalfeepaidcryr=0;
    
    let grandTotalRegPrevFinancialYr=0;
    let grandTotalRegCurrFinancialYr=0;
    let grandtotalRegPrevMonth=0;
    let grandtotalRegPrevMonthastoday=0;
    let grandtotalRegCurrMonth=0;
    let grandtotalInactive30Day=0;
    let grandtotalInactive60Day=0;
    let grandtotalRegyeartilldate=0
    let grandtotalfeepaidpryr=0;
    let grandtotalfeepaidcryr=0;

    const initcall = async function () {
        await counselorRegistrationReportApi.mutateAsync();
        await counselorCountryRegistrationReportApi.mutateAsync();
    }

    useEffect(()=>{
        user.setToggleMenu(false);
        initcall()
        //counselorRegistrationReportApi.mutateAsync();
    },[]);

    function removeDuplicates(arr) {
        return arr.filter((obj, index, self) => {
            return index === self.findIndex((t) => (
                t.user_id === obj.user_id
            ));
        });
    }


    const counselorRegistrationReportApi = authQueries.useCounselorRegistrationReport(

        (response) => {
            console.log("response",response.data[0])
            let uniqueDataArray = removeDuplicates(response.data[0])
            setResult(uniqueDataArray);
        }   
    
    );

    const counselorCountryRegistrationReportApi = authQueries.useCounselorRegistrationCountryReport(

        (response) => {
            console.log("response",response.data[0])
            setCountryList(response.data[0]);        
        }   
    
    );

    const handleCloseStatus = () => {
        setVisibleStatus(false)
    }

    const statusModalOpen =(type, uid)=>{
        setVisibleStatus(true)
        if(type===1)
        {
            setStatus("Registered moved to not Interested within 30 days")
        }else if(type===2)
        {
            setStatus("Registered moved to not Interested within 31 - 60 days")
        }
        else if(type===3)
        {
            setStatus("Registered moved to not Interested within 31 - 60 days")
        }
        else
        {
            setStatus("Registered moved to not interested year till date")
        }

        
       listApplicant.mutateAsync({type:type, uid: uid})
    }

    const listApplicant = authQueries.useCounselorApplicantList(
        (response) => {
            setApplicantresult(response.data[0])
            
        }
    )

    let grandTotalRegPrevFinancialYrfirstRow=0;
    let grandTotalRegCurrFinancialYrfirstRow=0;
    let grandtotalRegPrevMonthfirstRow=0;
    let grandtotalRegPrevMonthastodayfirstRow=0;
    let grandtotalRegCurrMonthfirstRow=0;
    let grandtotalInactive30DayfirstRow=0;
    let grandtotalInactive60DayfirstRow=0;
    let grandtotalInactive90DayfirstRow=0;
    let grandtotalRegyeartilldatefirstrow=0;
    let grandtotalfeepaidpryrfirstrow=0;
    let grandtotalfeepaidcryrfirstrow=0;


    for(let i=0;i<result.length;i++)
    {
        grandTotalRegPrevFinancialYrfirstRow+=result[i].registered_leads_prev_yr;
        grandTotalRegCurrFinancialYrfirstRow+=result[i].registered_leads_curr_yr;
        grandtotalRegPrevMonthfirstRow+=result[i].prev_month_registrations;
        grandtotalRegPrevMonthastodayfirstRow+=result[i].prev_month_registrationastoday;
        grandtotalRegCurrMonthfirstRow+=result[i].curr_month_registrations;
        grandtotalInactive30DayfirstRow+=result[i].registered_inactve_30_days;
        grandtotalInactive60DayfirstRow+=result[i].registered_inactve_60_days;
        grandtotalInactive90DayfirstRow+=result[i].registered_inactve_90_days;
        grandtotalRegyeartilldatefirstrow+=result[i].registered_inactive_financialyear_days;
        grandtotalfeepaidpryrfirstrow+=result[i].feepaid_prev_yr;
        grandtotalfeepaidcryrfirstrow+=result[i].feepaid_curr_yr;

    }

    function formatDateToDDMMYYYY(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    let countrytotal=0;
    return(
        <Fragment>
            <div className="breadcrumb-and-otherbuttons">
                {/* <!-- breadcrumb --> */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Counselor Registration Report</li>
                    </ol>
                </nav>
            </div>
    
            <div className="add_clients">
                    <div className="row">
                        <div className="col-md-6 appointments-hing-wrpr">
                            <i className="appointments-icon">
                                <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                            </i>
                            <h2><span>&nbsp;</span>Counselor Registration Report</h2>	
                        </div>
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
                    </div>
                    
                    <div id="accordion" className="add_clients_accordion_form">
                        <div  className="card" id="appointments_wrpr_employee">
                        <div className="card-header">
                            {/* <a className="collapsed card-link"  href="" style="pointer-events:none;"> */}
                            Country wise month to date student registrations
                            {/* </a> */}
                            <table border={1} className="table report collageprogram">
                            <tbody>
                                {countryList?.map((item, index) => (
                                    <React.Fragment key={index}>
                                            <td>{item.country}</td>
                                    </React.Fragment>
                                ))}
                                   <td><b>Total</b></td>
                            </tbody>
                            <tbody> 
                                {
                               
                                countryList?.map((item, index) => {
                                    countrytotal+=item.curr_month_reg;
                                    return(
                                    <React.Fragment key={index}>
                                            <td>{item.curr_month_reg}</td>
                                    </React.Fragment>
                               ) } )}
                                <td><b>{countrytotal}</b></td>
                            </tbody>
                        </table>
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                               Counselor Registration Report
                                <div className="row ">
                                    <div className="col-md-12">
                                        
                                    <div class="table-head-wrpr" style={{width:"1117px"}}>
                                                    <table class="table collageprogram alt-colors" style={{width:"1100px"}}>
                                                        <thead class="fixedHeader">
                                                            <tr>
                                                                <th rowSpan={2}>Branch</th>
                                                                <th rowSpan={2}>Counselor</th>
                                                                <th colSpan={2}>Previous Financial Year</th>
                                                                <th colSpan={2}>Current Financial Year</th>
                                                                <th rowSpan={2}>Registrations Previous Month</th>
                                                                <th rowSpan={2}>Registrations Previous Month as of Current Date</th>
                                                                <th rowSpan={2}>Registrations Current Month as of Current Date</th>
                                                                <th rowSpan={2}>Registered moved to not Interested within 30 days</th>
                                                                <th rowSpan={2}>Registered moved to not Interested within 31 - 60 days</th>
                                                                <th rowSpan={2}>Registered moved to not Interested within 61 - 90 days</th>
                                                                <th rowSpan={2}>Registered moved to not interested year till date</th>
                                                            </tr>

                                                            <tr>
                                                                <th>Registrations</th>
                                                                <th>Fee Paid</th>
                                                                <th>Registrations</th>
                                                                <th>Fee Paid</th>
                                                            </tr>

                                                            
                                                            
                                                            <tr style={{backgroundColor: "rgb(109, 174, 230)"}}>
                                                                <th>Grand total</th>
                                                                <th></th>
                                                                <th>{grandTotalRegPrevFinancialYrfirstRow}</th>
                                                                <th>{grandtotalfeepaidpryrfirstrow}</th>
                                                                <th>{grandTotalRegCurrFinancialYrfirstRow}</th>
                                                                <th>{grandtotalfeepaidcryrfirstrow}</th>
                                                                <th>{grandtotalRegPrevMonthfirstRow}</th>
                                                                <th>{grandtotalRegPrevMonthastodayfirstRow}</th>
                                                                <th style={{ backgroundColor: '#47a524' }}>{grandtotalRegCurrMonthfirstRow}</th>
                                                                <th>{grandtotalInactive30DayfirstRow}</th>
                                                                <th>{grandtotalInactive60DayfirstRow}</th>
                                                                <th>{grandtotalInactive90DayfirstRow}</th>
                                                                <th>{grandtotalRegyeartilldatefirstrow}</th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                        </div>

                                        <div class="table-body-wrpr" style={{width:"1117px", height:"360px"}} >
											<div style={{position:"relative"}}>
                                                <table border={1} className="table" style={{width:"1100px", fontSize:"12px"}}>
                                                <tbody>
                                                    { counselorRegistrationReportApi.isLoading && "Loading" }
                                                    {result?.map((item, index) =>{
                                                        let offices = result?.filter(function(office){
                                                            return  office.office === item.office;
                                                    });

                                                    if(office!==item.office){
                                                        serialNo=0
                                                        totalRegPrevFinancialYr=0;
                                                        totalRegCurrFinancialYr=0;
                                                        totalRegPrevMonth=0;
                                                        totalRegPrevMonthastoday=0;
                                                        totalRegCurrMonth=0;
                                                        totalInactive30Day=0;
                                                        totalInactive60Day=0;
                                                        totalInactive90Day=0;
                                                        totalRegyeartilldate=0;
                                                        totalfeepaidpryr=0;
                                                        totalfeepaidcryr=0;

                                                        //serialNo=serialNo+1;
                                                        totalRegPrevFinancialYr+=item.registered_leads_prev_yr
                                                        totalRegCurrFinancialYr+=item.registered_leads_curr_yr
                                                        totalRegPrevMonth+=item.prev_month_registrations
                                                        totalRegPrevMonthastoday+=item.prev_month_registrationastoday?item.prev_month_registrationastoday:0;
                                                        totalRegCurrMonth+=item.curr_month_registrations
                                                        totalInactive30Day+=item.registered_inactve_30_days?item.registered_inactve_30_days:0
                                                        totalInactive60Day+=item.registered_inactve_60_days?item.registered_inactve_60_days:0
                                                        totalInactive90Day+=item.registered_inactve_90_days?item.registered_inactve_90_days:0
                                                        totalRegyeartilldate+=item.registered_inactive_financialyear_days?item.registered_inactive_financialyear_days:0
                                                        totalfeepaidpryr+=item.feepaid_prev_yr?item.feepaid_prev_yr:0
                                                        totalfeepaidcryr+=item.feepaid_curr_yr?item.feepaid_curr_yr:0

                                                        grandTotalRegPrevFinancialYr+=item.registered_leads_prev_yr
                                                        grandTotalRegCurrFinancialYr+=item.registered_leads_curr_yr
                                                        grandtotalRegPrevMonth+=item.prev_month_registrations
                                                        grandtotalRegPrevMonthastoday+=item.prev_month_registrationastoday?item.prev_month_registrationastoday:0
                                                        grandtotalRegCurrMonth+=item.curr_month_registrations
                                                        grandtotalInactive30Day+=item.registered_inactve_30_days?item.registered_inactve_30_days:0
                                                        grandtotalInactive60Day+=item.registered_inactve_60_days?item.registered_inactve_60_days:0
                                                        grandtotalRegyeartilldate+=item.registered_inactive_financialyear_days?item.registered_inactive_financialyear_days:0
                                                        grandtotalfeepaidpryr+=item.feepaid_prev_yr?item.feepaid_prev_yr:0
                                                        grandtotalfeepaidcryr+=item.feepaid_curr_yr?item.feepaid_curr_yr:0
                                                        
                                                    }else{
                                                        serialNo=serialNo+1;
                                                        totalRegPrevFinancialYr+=item.registered_leads_prev_yr
                                                        totalRegCurrFinancialYr+=item.registered_leads_curr_yr
                                                        totalRegPrevMonth+=item.prev_month_registrations?item?.prev_month_registrations:0
                                                        totalRegPrevMonthastoday+=item.prev_month_registrationastoday?item.prev_month_registrationastoday:0
                                                        totalRegCurrMonth+=item.curr_month_registrations
                                                        totalInactive30Day+=item.registered_inactve_30_days?item.registered_inactve_30_days:0
                                                        totalInactive60Day+=item.registered_inactve_60_days?item.registered_inactve_60_days:0
                                                        totalInactive90Day+=item.registered_inactve_90_days?item.registered_inactve_90_days:0
                                                        totalRegyeartilldate+=item.registered_inactive_financialyear_days?item.registered_inactive_financialyear_days:0
                                                        totalfeepaidpryr+=item.feepaid_prev_yr?item.feepaid_prev_yr:0
                                                        totalfeepaidcryr+=item.feepaid_curr_yr?item.feepaid_curr_yr:0

                                                        grandTotalRegPrevFinancialYr+=item.registered_leads_prev_yr
                                                        grandTotalRegCurrFinancialYr+=item.registered_leads_curr_yr
                                                        grandtotalRegPrevMonth+=item.prev_month_registrations
                                                        grandtotalRegPrevMonthastoday+=item.prev_month_registrationastoday?item.prev_month_registrationastoday:0
                                                        grandtotalRegCurrMonth+=item.curr_month_registrations
                                                        grandtotalInactive30Day+=item.registered_inactve_30_days?item.registered_inactve_30_days:0
                                                        grandtotalInactive60Day+=item.registered_inactve_60_days?item.registered_inactve_60_days:0
                                                        grandtotalRegyeartilldate+=item.registered_inactive_financialyear_days?item.registered_inactive_financialyear_days:0
                                                        grandtotalfeepaidpryr+=item.feepaid_prev_yr?item.feepaid_prev_yr:0
                                                        grandtotalfeepaidcryr+=item.feepaid_curr_yr?item.feepaid_curr_yr:0

                                                    }
                                                    return(
                                                    <Fragment>
                                                        
                                                        <tr className="ui-sortable-handle">
                                                            {office!==item.office?<td style={{fontSize:"10px !important"}} rowSpan={offices.length}><b>{office=item.office }</b></td>: null}
                                                            <td>{item.username}</td>
                                                            <td>{item.registered_leads_prev_yr?item.registered_leads_prev_yr:0}</td>
                                                            <td>{item.feepaid_prev_yr?item.feepaid_prev_yr:0}</td>
                                                            <td>{item.registered_leads_curr_yr?item.registered_leads_curr_yr:0}</td>
                                                            <td>{item.feepaid_curr_yr?item.feepaid_curr_yr:0}</td>
                                                            <td>{item.prev_month_registrations?item.prev_month_registrations:0}</td>
                                                            <td>{item.prev_month_registrationastoday?item.prev_month_registrationastoday:0}</td>
                                                            <td>{item.curr_month_registrations?item.curr_month_registrations:0}</td>
                                                            <td style={{  backgroundColor: item && item.registered_inactve_30_days >= 2 ? 'yellow' : '' }}>
                                                                <a data-value={ item?.user_id}  style={{ textDecoration: item && item.registered_inactve_30_days > 0 ? 'underline' : '', cursor: "pointer", color: "blue" }}  onClick={() => statusModalOpen(1, item?.user_id)} href="javascript:void(0)">{item.registered_inactve_30_days ? item.registered_inactve_30_days : 0}</a>
                                                            </td>
                                                            <td style={{ backgroundColor: item && item.registered_inactve_60_days >= 4 ? 'yellow' : '' }} >
                                                                <a data-value={ item?.user_id} style={{ textDecoration: item && item.registered_inactve_60_days > 0 ? 'underline' : '', cursor: "pointer", color: "blue" }}   onClick={()=>{statusModalOpen(2, item?.user_id )}}  href="javascript:void(0)">{item.registered_inactve_60_days?item.registered_inactve_60_days:0}</a></td>

                                                            <td style={{ backgroundColor: item && item.registered_inactve_90_days >= 4 ? 'yellow' : '' }} >
                                                                <a data-value={ item?.user_id} style={{ textDecoration: item && item.registered_inactve_90_days > 0 ? 'underline' : '', cursor: "pointer", color: "blue" }}   onClick={()=>{statusModalOpen(3, item?.user_id )}}  href="javascript:void(0)">{item.registered_inactve_90_days?item.registered_inactve_90_days:0}</a></td>
                                                            
                                                            <td style={{ backgroundColor: item && item.registered_inactive_financialyear_days >= 4 ? 'yellow' : '' }} >
                                                                <a data-value={ item?.user_id} style={{ textDecoration: item && item.registered_inactive_financialyear_days > 0 ? 'underline' : '', cursor: "pointer", color: "blue" }}   onClick={()=>{statusModalOpen(4, item?.user_id )}}  href="javascript:void(0)">{item.registered_inactive_financialyear_days?item.registered_inactive_financialyear_days:0}</a></td>
                                                        </tr>
                                                        {offices.length-1 === serialNo ? (
                                                            <tr style={{backgroundColor: "#6daee6"}}>
                                                                <td colSpan={2}><b>Total</b></td>
                                                                <td><b>{totalRegPrevFinancialYr}</b></td>
                                                                <td><b>{totalfeepaidpryr}</b></td>
                                                                <td><b>{totalRegCurrFinancialYr}</b></td>
                                                                <td><b>{totalfeepaidcryr}</b></td>
                                                                <td><b>{totalRegPrevMonth}</b></td>
                                                                <td><b>{totalRegPrevMonthastoday}</b></td>
                                                                <td><b>{totalRegCurrMonth}</b></td>
                                                                <td><b>{totalInactive30Day}</b></td>
                                                                <td><b>{totalInactive60Day}</b></td>
                                                                <td><b>{totalInactive90Day}</b></td>
                                                                <td><b>{totalRegyeartilldate}</b></td>
                                                            </tr>
                                                        ):null}
                                                    </Fragment>
                                                    )}
                                                )}
                                                    <tr>
                                                        <td ><b>Grand Total</b></td>
                                                        <td></td>
                                                        <td><b>{grandTotalRegPrevFinancialYr}</b></td>
                                                        <td><b>{grandtotalfeepaidpryr}</b></td>
                                                        <td><b>{grandTotalRegCurrFinancialYr}</b></td>
                                                        <td><b>{grandtotalfeepaidcryr}</b></td>
                                                        <td><b>{grandtotalRegPrevMonth}</b></td>
                                                        <td><b>{grandtotalRegPrevMonthastoday}</b></td>
                                                        <td><b>{grandtotalRegCurrMonth}</b></td>
                                                        <td><b>{grandtotalInactive30Day}</b></td>
                                                        <td><b>{grandtotalInactive60Day}</b></td>
                                                        <td><b>{grandtotalInactive90DayfirstRow}</b></td>
                                                        <td><b>{grandtotalRegyeartilldate}</b></td>

                                                    </tr>
                                            </tbody>

                                            </table>
                                                
                                            </div>
                                        </div>
                                    
                                        <div className="table-list-search-wrpr  table-container tableContainer">
                                            <div className="row">
                                                <div className="col-md-6">
                                                </div>
                                            
                                                <div className="col-md-6 text-right">
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    
                </div>

                
        <Modal
            scroll
            blur
            closeButton
            width="70%"
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={visibleStatus}
            onClose={handleCloseStatus}
            size="sm"
        >
            <Modal.Header>
                <Text b size="16px" className="text-center">{status}</Text>
            </Modal.Header>
            <Modal.Body>
                <table className="table report collageprogram alt-colors">
                    <thead>
            
                        <tr style={{background:'rgb(50 58 81 )'}}>
                            <th>Applicant Name</th>
                            <th>VisaType</th>
                            <th>Phone</th>  
                            <th>Email</th>
                            <th>Registered date</th>
                            <th>Not Interested date</th>
                            
                        </tr>
                    </thead>
                    <tbody id="sortable6" className="ui-sortable">
                        {
                                applicantresult?.map((item, index) => (
                                    <tr>
                                        <td>{item.applicant_name}</td>
                                        <td>{item?.description}</td>
                                        <td>{item?.mobile_no}</td>
                                        <td>{item?.email}</td>
                                        <td>{formatDateToDDMMYYYY(item?.registered_date)}</td>
                                        <td>{formatDateToDDMMYYYY(item?.notinteresteddate)}</td>
                                    </tr>

                                ))
                        }
                   
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
                
            </div>
        </Fragment>
    )

}
export default CounselorRegistration;