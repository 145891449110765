import React, { useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";

const Employee =()=>{

    const [result, setResult] = useState([]);
    const [status, setStatus] = useState('');
    let staffTotal=0;
    let office='';
    let username='';
    let staffcount='';
    let officeCount ='';
    let address ='';
    let reviewgb ='';
    let reviewmg ='';
    let location ='';

    useEffect(()=>{
        employeeReportApi.mutateAsync();
    },[]);

    const employeeReportApi = authQueries.useEmployeeReport(
        (response) => {
            setResult(response);        
        }
    );

    return(
    <div>
        <div className="breadcrumb-and-otherbuttons">
            {/* <!-- breadcrumb --> */}
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Employee Details and Office Locations</li>
                </ol>
            </nav>
        </div>
        
        <div className="add_clients">
        
            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr">
                    <i className="appointments-icon">
                        <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                    </i>
                    <h2><span>&nbsp;</span>Employee Details and Office Locations</h2>	
                </div>
                    
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>
            

            <div id="accordion" className="add_clients_accordion_form">
                <div  className="card" id="appointments_wrpr_employee">
                    <div className="card-header">
                    {/* <a className="collapsed card-link"  href="" style="pointer-events:none;"> */}
                    Employee Details and Office Locations
                    {/* </a> */}
                    
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                    <div className="card-body">
                            
                        <div className="row ">
                            <div className="col-md-12">
                            
                                <div className="table-list-search-wrpr">
                                    <div className="row">
                                        <div className="col-md-6">
                                            
                                        </div>
                                        <div className="col-md-6 text-right">
                                        </div>
                                </div>

                                
                                
                                    <table border={1} className="table report collageprogram">
                                        <thead>
                                            <tr>
                                
                                            <th >Branch</th>
                                            <th>Employee Name</th>
                                            <th>Employee Designation</th>
                                            <th >Employee Phone Number</th>
                                            <th >Employee Email</th>
                                            <th >Office Address</th>
                                            <th >Google Review GB</th>
                                            <th >Google Review M&G</th>
                                            <th >Office Location</th>
                                            </tr>
                                        </thead>
                                        <tbody  className="ui-sortable">
                                        { employeeReportApi.isLoading && "Loading" }
                                        {   
                                            result?.data?.map((item, index) =>
                                            {  
                                            
                                            let offices = result?.data?.filter(function(office){
                                                return  office.office == item.office;
                                            });

                                            
                                                
                                            return(
                                                <tr className="ui-sortable-handle">
                
                                                {office!=item.office?<td rowSpan={offices.length}>{office=item.office }</td>: null}
                                                <td>{item.firstname+' '+item.lastname}</td>
                                                <td>{item.designation}</td>
                                                <td>{item.phone}</td>
                                                <td>{item.email}</td>
                                                {address!=item.address?<td rowSpan={offices.length}>{address=item.address }</td>: null}
                                                {reviewgb!=item.review_GB?<td rowSpan={offices.length}>{reviewgb=item.review_GB }</td>: null}
                                                {reviewmg!=item.review_MAG?<td rowSpan={offices.length}>{reviewmg=item.review_MAG }</td>: null}
                                                {location!=item.location?<td rowSpan={offices.length}>{location=item.location }</td>: null}
                                                </tr>
                                            )})
                                        }
                                        </tbody>

                                    </table>
                            </div>
                        </div>
                        
                        </div>
                    </div>
                </div>
                
                
                </div>
            
        </div>
        
        </div>
    </div>
    )
}

export default Employee;