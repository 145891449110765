import React, { useEffect, useState } from 'react'
import authQueries from '../../../queries/authQueries'
import { Collapse, Grid, Modal } from '@nextui-org/react'
import moment from 'moment';
import { userStore } from '../../../store/userStore';

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const isBeforeApril = currentDate.getMonth() < 3; // April is the 4th month, 0-indexed

const currentFinancialYearStart = isBeforeApril ? currentYear - 1 : currentYear;
const currentFinancialYearEnd = currentFinancialYearStart + 1;

const nextFinancialYearStart = currentYear;
const nextFinancialYearEnd = nextFinancialYearStart + 1;


const Target =()=> {

    const storeData = userStore();

    const [visible, setVisible] = useState(false)
    const [country, setCountry] = useState([])
    const [user, setUser] = useState({user:null, year: ''})

    useEffect(() => {
        targetList.mutateAsync()
        countryList.mutateAsync({target:'YES'})
    }, [])

    const targetList = authQueries.useTargetOfficeMutationList(
        (response) => {
            storeData.setTarget(response)
        }
    );

    const countryList = authQueries.useCountryMutationList(
        (response) => {
            setCountry(response)
        }
    );

    const handle =(id, year)=> {
        setUser({user: id, year: year })
        setVisible(true)
    }

    const handleClose = () => {
        setVisible(false)
    };

  return (
    <div>

    <Grid.Container style={{display:'block'}}>
        <Grid>
            <Collapse.Group shadow>
            {
                storeData?.target?.data?.map((item, index) => (
                    <Collapse key={index} title={<h5>{item?.name}</h5>} subtitle={`${item?.useroffices?.length} Counselor`}>
                        <table className='table collageprogram'>
                            <thead>
                                <tr>
                                    <th width="1%">Sl.No</th>
                                    <th width="29%">Name</th>
                                    <th width="35%" style={{ textAlign: 'center' }}>
                                    {`Total Target Current Financial (${currentFinancialYearStart} - ${currentFinancialYearEnd})`}
                                    </th>
                                    <th width="35%" style={{ textAlign: 'center' }}>
                                    {`Total Target Next Financial (${nextFinancialYearStart} - ${nextFinancialYearEnd})`}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="ui-sortable">
                                {
                                    item?.useroffices?.map((items, indexes) => (
                                        <tr key={indexes}>
                                            <td>{indexes + 1 }</td>
                                            <td>{items?.user?.userdetail?.firstname} {items?.user?.userdetail?.lastname}</td>
                                            <td style={{textAlign:'center'}}>
                                                <TotalTargetCurrent handle={handle} item={items} />
                                            </td>
                                            <td className="action-icons" style={{textAlign:'center'}}>
                                                <TotalTargetNext handle={handle} item={items} />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </Collapse>
                ))
            }
            </Collapse.Group>
        </Grid>
    </Grid.Container>

    <Modal
        blur
        width='800px'
        scroll
        open={visible}
        onClose={handleClose}
        closeButton
    >
        <Modal.Header>
            <span style={{fontSize:'20px', fontWeight:600}}>
                TARGET&nbsp;
                <span style={{fontSize:'20px'}}>
                    {user?.year === "CURRENT" ? 
                    `${currentFinancialYearStart} - ${currentFinancialYearEnd} ` : 
                    `${currentFinancialYearStart} - ${currentFinancialYearEnd}` 
                    }
                </span> 
            </span>
        </Modal.Header>
        <Modal.Body>
            <table className='table collageprogram'>
                <thead>
                    <tr>
                        <th width="20px">Sl.No</th>
                        <th width="50%">Country</th>
                        <th>Count</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody className="ui-sortable">
                    {
                        country?.data?.items?.map((item, index) => (
                            <CountryWiseTarget index={index} user={user} item={item}/>
                        ))
                    }
                </tbody>
            </table>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
    </Modal>

    </div>
  )
}

export default Target

const CountryWiseTarget =({index, user, item})=> {

    const [edit, setEdit] = useState(false)
    const [count, setCount] = useState('')
    const currentYear = new Date();

    const storeData = userStore();

    useEffect(()=> {
        TargetView.mutateAsync({user: user.user, country:item.id, year: user.year})
    }, []) 

    const TargetView = authQueries.useTargetMutationView(
        (response) => {
            setCount(response?.data?.count)
        }
    )

    const editButton =()=>{
        setEdit(true)
    }

    const updateButton =()=>{
        
        let data = {
            cid: item.id,
            uid: user.user,
            count: count,
            year: user.year
        }

        targetAdd.mutateAsync(data)
        setEdit(false)
    }

    const targetAdd = authQueries.useTargetMutationAdd(
        (response)=> {
            TargetView.mutateAsync({user: user.user, country:item.id, year: user.year})
            targetList.mutateAsync()
        }
    )

    const targetList = authQueries.useTargetOfficeMutationList(
        (response) => {
            storeData.setTarget(response)
        }
    );

    return (
        <tr>
            <td>{index + 1}</td>
            <td>{item?.name}</td>
            <td>
                {
                    !edit ?
                    (count ? count : '0') :
                    <div id="parent">
                        <input 
                            defaultValue={count ? count : '0'}
                            onChange={(e)=> {setCount(e.target.value)}}
                            className="form-control"  
                            name="name"/>
                    </div>
                }
            </td>
            <td className="action-icons">
                <button className="btn-visadata" style={{float:'right'}} onClick={edit? updateButton:editButton}>{edit? `Update` : `Edit`}</button>
            </td>
        </tr>
    )
}



const TotalTargetCurrent =({handle, item})=> {

    const [target, setTarget] = useState('')

    useEffect(()=> {
        targetCalculation()
    },[handle])

    const targetCalculation = () =>{
        let total = 0
        item?.user?.target_roles?.map((items, index) => {
            if(items?.target_date > moment(`${currentFinancialYearStart}-03-31`).format("YYYY-MM-DD") &&  items?.target_date < moment(`${currentFinancialYearEnd}-04-01`).format("YYYY-MM-DD")){
                total = total + items?.count
            }
        })
        setTarget(total)
    }

    return(
        <div style={{fontSize: "20px", fontWeight:500, color:'#1677ff', cursor:'pointer'}} onClick={()=> {handle(item?.uid, 'CURRENT')}}>
            {target}
        </div>
    )
}

const TotalTargetNext =({handle, item})=> {

    const [target, setTarget] = useState('')

    useEffect(()=> {
        targetCalculation()
    },[handle])

    const targetCalculation = () =>{
        let total = 0
        item?.user?.target_roles?.map((items, index) => {
            if(items?.target_date > moment(`${nextFinancialYearStart}-03-31`).format("YYYY-MM-DD") &&  items?.target_date < moment(`${nextFinancialYearEnd}-04-01`).format("YYYY-MM-DD")){
                total = total + items?.count
            }
        })
        setTarget(total)
    }

    return(
        <div style={{fontSize: "20px", fontWeight:500, color:'#1677ff', cursor:'pointer'}} onClick={()=> {handle(item?.uid, 'NEXT')}}>
            {target}
        </div>
    )
}