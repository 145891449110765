import React, { Fragment } from 'react'
import { userStore } from '../../store/userStore'
import { useNavigate } from 'react-router-dom'

const ApplicantEditButton = ({item}) => {

    const store = userStore()
    const navigate = useNavigate()
    
	const handleChange = async() => {
		await store.setApplication(item?.id)
    navigate('/addclient')
  }

  return (
    <Fragment>
        <button 
        onClick={handleChange} 
        className='btn-customized'
        >
        Edit
        </button>
    </Fragment>
  )
}

export default ApplicantEditButton
