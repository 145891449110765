import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import DropDown from "../../Common/DropDown";
import moment from "moment";
import { Checkbox, Loading } from "@nextui-org/react";
import RegisteredCheckSearch from "../../Common/RegisteredCheckSearch";
import SelectSearch from "../../Common/SelectSearch";
import InputSearch from "../../Common/InputSearch";
import LoaderCommon from "../../Common/LoaderCommon";
import { GanttChartSquare } from "lucide-react";
import LeadStatus from "../../Common/LeadStatus";


const CeoNotQualifiedReport =()=>{

    const [result, setResult] = useState([]);
    const [dateLimit, setDateLimit] = useState('MONTH');
    const [to, setTo] = useState(new Date())
    const [from, setFrom] = useState(new Date())
    const [registered, setRegistered] = useState("ALL")
        
    const managerReport = authQueries.useCEONotQualifiedMutation(
        (response) => {
            setResult(response);   
        }
    );

    useEffect(()=>{
        managerReport.mutateAsync({
            datelimit: dateLimit,
            type: registered,
            to: to,
            from: from
        });
    },[dateLimit, registered, to , from]);

    const inputSearchArry = [
        {
            label: "Date Range",
            size: 2,            
            state: dateLimit,
            setState: setDateLimit,
            input: SelectSearch,
            hideBlankValue: true,
            value: [
                {
                    id: "MONTH",
                    name: "Month"
                },
                {
                    id: "WEEK",
                    name: "Week"
                },
                {
                    id: "CUSTOM",
                    name: "Custom Date"
                }
            ],
            name: 'name',
        },
        {
            label: "From Date",
            size: 2,            
            state: moment(from).format('YYYY-MM-DD'),
            setState: setFrom,
            type: "date",
            input: InputSearch,
            hidden: dateLimit === "CUSTOM" ? false:true
        },
        {
            label: "To Date",
            size: 2,            
            state: moment(to).format('YYYY-MM-DD'),
            setState: setTo,
            type: "date",
            input: InputSearch,
            hidden: dateLimit === "CUSTOM" ? false:true
        },
        {
            label: "Registration Status",
            size: 2,            
            state: registered,
            setState: setRegistered,
            input: RegisteredCheckSearch,
        }
    ]

    const counselorName =(counselor)=>{
        return `${counselor?.firstname} ${counselor?.lastname}`
    }

    return(
        <div>
            <div className="">
                <div className="breadcrumb-and-otherbuttons">
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Not Qualified clients</li>
                    </ol>
                    </nav>
                </div>
                
                <div className="add_clients">
                    <div className="flex items-center gap-[4px] mb-4">
                        <i className="followup-icon">
                            <GanttChartSquare size="25" className='text-[#7D6FAB]' />
                        </i>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                                <span className="text-[16px] font-medium">Not Qualified Report</span>	
                            </div>
                        </div>
                    </div>
                    <div id="accordion" className="add_clients_accordion_form mt-[10px]">
                        <div  className="card" id="appointments_wrpr_employee">
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">

                                <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5">
                                    {
                                        inputSearchArry?.map((item, index)=> (
                                            <Fragment>
                                                {
                                                    !item?.hidden &&
                                                    <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                                        <item.input item={item} />
                                                    </div>
                                                }
                                            </Fragment>
                                        ))
                                    }
                                </div>

                                {managerReport.isLoading ?
                                    <LoaderCommon />:
                                    <div className="table-wrpr">
                                        <div className="table-section">
                                            <div className="row">
                                                <div className="col-md-12">                                   
                                                    <table border={1} className="table prospect-table">
                                                        <thead>
                                                            <tr className="!font-bold">
                                                                <th width="30">#</th>
                                                                <th>Profile Status</th>
                                                                <th>Office</th>
                                                                <th>Date Created</th>
                                                                <th>Applicant Name</th>
                                                                <th>Contact Number</th>
                                                                <th>Visa Type</th>
                                                                <th>Intake</th>
                                                                <th>Counselor</th>
                                                                {
                                                                    registered !== "NO" &&
                                                                    <th>Reg. Date</th>
                                                                }
                                                                <th>Prospect Status</th>
                                                                <th>Reffered By</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody  className="ui-sortable">
                                                            {   
                                                                result?.data?.map((item, index) =>(
                                                                    <tr className="ui-sortable-handle">
                                                                        <td>{index+1}</td>
                                                                        <td><LeadStatus item={item} /></td>
                                                                        <td>{item?.office?.name}</td>
                                                                        <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                        <td>{item?.applicant_name} {item?.middlename} {item?.lastname}</td>
                                                                        <td>
                                                                            <div className="email-mobile flex flex-col">
                                                                                <div>{item.mobile_no}</div>
                                                                                <div>{item.landline_no}</div>
                                                                                <div>{item.whatsapp_no}</div>
                                                                            </div>
                                                                        </td>
                                                                        <td>{item?.visatype?.country?.icon} {item?.visatype?.description} </td>
                                                                        <td>{item?.intake?.intake}</td>
                                                                        <td>{counselorName(item?.assignUser?.CounselorAssign?.userdetail)}</td>
                                                                        {
                                                                            registered !== "NO" &&
                                                                            <td>{item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}</td>
                                                                        }
                                                                        <td>{item?.status_of_prospect?.name}</td>
                                                                        <td>{item?.refferal?.refferal}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
    
}
export default CeoNotQualifiedReport;