import React from 'react';
import { Dropdown } from 'antd';
import { Avatar } from '@nextui-org/react';
import LoaderCommon from './LoaderCommon';

const MenuItems = ({handleClick, data}) => {

    const items = [
        {
            label: !data?.isLoading ? <div id="leaderboard" className='w-[500px] my-2 font-poppins text-[12px] flex flex-col justify-center gap-5'>
                <div className='text-center font-poppins text-[16px]'>Top Performance</div>
                <div className='flex items-center'>
                    <div className='flex flex-col items-center justify-center w-40 bg-slate-900 text-white gap-5 h-56 rounded-l-xl p-2'>
                        <Avatar src={data?.data?.data[2]?.image ? `${process.env.REACT_APP_BASE_URL}/api/fileuploadcommon/${data?.data?.data[2]?.image}`:require('../../assets/images/profile-avatar.jpg')} />
                        <div>
                            <div className='mt-1 font-medium text-center'>{data?.data?.data[2]?.name}</div>
                            <div className='text-center text-[14px] font-bold bg-[white] text-black px-4 py-[3px] mt-1 rounded-md'>{data?.data?.data[2]?.count}</div>
                        </div>
                        <div className='absolute top-[65px] bg-slate-900 rounded-full w-9 h-9 flex items-center justify-center text-[16px]'>
                            <div className=' text-black bg-white rounded-full w-7 h-7 flex items-center justify-center text-[16px]'>
                                3
                            </div>
                        </div>              
                    </div>
                    <div className='flex flex-col items-center justify-center w-44 bg-amber-400 text-black gap-5 h-64 rounded-xl p-2'>
                        <Avatar src={data?.data?.data[0]?.image ? `${process.env.REACT_APP_BASE_URL}/api/fileuploadcommon/${data?.data?.data[0]?.image}`:require('../../assets/images/profile-avatar.jpg')} />
                        <div>
                            <div className='mt-1 font-medium text-center'>{data?.data?.data[0]?.name}</div>
                            <div className='text-center text-[14px] font-bold bg-[white] text-black px-4 py-[3px] mt-1 rounded-md'>{data?.data?.data[0]?.count}</div>
                        </div>
                        <div className='absolute top-[50px] bg-amber-400 rounded-full w-9 h-9 flex items-center justify-center text-[16px]'>
                            <div className='text-black bg-white rounded-full w-7 h-7 flex items-center justify-center text-[16px]'>
                                1
                            </div>
                        </div>                
                    </div>
                    <div className='flex flex-col items-center justify-center w-40 bg-slate-900 text-white gap-5 h-56 rounded-r-xl p-2'>
                        <Avatar src={data?.data?.data[1]?.image ? `${process.env.REACT_APP_BASE_URL}/api/fileuploadcommon/${data?.data?.data[1]?.image}`:require('../../assets/images/profile-avatar.jpg')} />
                        <div>
                            <div className='mt-1 font-medium text-center'>{data?.data?.data[1]?.name}</div>
                            <div className='text-center text-[14px] font-bold bg-[white] text-black px-4 py-[3px] mt-1 rounded-md'>{data?.data?.data[1]?.count}</div>
                        </div>
                        <div className='absolute top-[65px] bottom-[18px] bg-slate-900 rounded-full w-9 h-9 flex items-center justify-center text-[16px]'>
                            <div className=' text-black bg-white rounded-full w-7 h-7 flex items-center justify-center text-[16px]'>
                                2
                            </div>
                        </div>  
                    </div>
                </div>
                <div className='flex flex-col'>
                    <div className='border-solid border-t-2 flex justify-between items-center p-2'>
                        <div className='flex items-center'>
                            <Avatar src={data?.data?.data[3]?.image ? `${process.env.REACT_APP_BASE_URL}/api/fileuploadcommon/${data?.data?.data[3]?.image}`:require('../../assets/images/profile-avatar.jpg')} />
                            <div className='pl-2 font-medium'>{data?.data?.data[3]?.name}</div>
                        </div>
                        <div className='text-[14px] font-semibold bg-black px-4 rounded-lg py-1 text-white'>{data?.data?.data[3]?.count}</div>
                    </div>
                    <div className='border-solid border-t-2 flex justify-between items-center p-2'>
                        <div className='flex items-center'>
                            <Avatar src={data?.data?.data[4]?.image ? `${process.env.REACT_APP_BASE_URL}/api/fileuploadcommon/${data?.data?.data[4]?.image}`:require('../../assets/images/profile-avatar.jpg')} />
                            <div className='pl-2 font-medium'>{data?.data?.data[4]?.name}</div>
                        </div>
                        <div className='text-[14px] font-semibold bg-black px-4 rounded-lg py-1 text-white'>{data?.data?.data[4]?.count}</div>
                    </div>
                    <div className='border-solid border-t-2 flex justify-between items-center p-2'>
                        <div className='flex items-center'>
                            <Avatar src={data?.data?.data[5]?.image ? `${process.env.REACT_APP_BASE_URL}/api/fileuploadcommon/${data?.data?.data[5]?.image}`:require('../../assets/images/profile-avatar.jpg')} />
                            <div className='pl-2 font-medium'>{data?.data?.data[5]?.name}</div>
                        </div>
                        <div className='text-[14px] font-semibold bg-black px-4 rounded-lg py-1 text-white'>{data?.data?.data[4]?.count}</div>
                    </div>
                </div>

            </div> :
            <div className='w-[500px]'>
                <LoaderCommon />
            </div>
        }
    ];

  return (
    <Dropdown
    con
    menu={{
    items,
    }}
    placement="bottom"
    arrow
    >
        <a onMouseEnter={handleClick} href="/" data-toggle="dropdown" className="leader-board-icon">
            <img src={require('../../assets/images/leaderboard-icon.jpg')} width="" height="" alt="" />
        </a>
    </Dropdown>
  )
}

export default MenuItems