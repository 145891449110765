import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup';
import Swiper from 'swiper';
import $ from 'jquery'
import authQueries from '../../queries/authQueries';
import LoaderCommon from '../Common/LoaderCommon';
import { IconAnchor, IconCalendarEvent, IconCalendarMinus, IconCalendarPlus, IconCoinPound, IconCoinRupee, IconDiscountCheck, IconHourglass } from '@tabler/icons-react';
import { CheckCircle, Vote } from 'lucide-react';
import FollowupTodayBranchManager from '../Common/FollowupTodayBranchManager';
import FollowupPendingBranchManager from '../Common/FollowupPendingBranchManager';
import { IconCircleCheck } from '@tabler/icons-react';
import FollowupToday from '../Common/FollowupToday';
import FollowupPending from '../Common/FollowupPending';
import { IconMoneybag } from '@tabler/icons-react';
import RouteConstants from '../../Constants/RouteConstans';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../../store/userStore';
import { useAdvancedSearch } from '../../store/advancedSearchStore';

const BranchManagerDashboard = () => {

	const [loader, setLoader] = useState(true)
	const [count, setCount] = useState({})
	const setActiveTab = userStore((state)=> state.setActiveTab)
	const setStatus = useAdvancedSearch((state) => state.setStatus);
	const setRegistered = useAdvancedSearch((state) => state.setRegistered);
	const setFeepaid = useAdvancedSearch((state) => state.setFeepaid);
	const setFinancialYear = useAdvancedSearch((state) => state.setFinancialYear);
	const setFulldocappmoved = useAdvancedSearch((state) => state.setFulldocappmoved);
	const setFundReady = useAdvancedSearch((state) => state.setFundReady);
    const resetAll = useAdvancedSearch((state) => state.resetAll);

	const navigate = useNavigate()

	const CountDashboard = authQueries.useBranchManagerDashboardActiveMutation(
	  async (response) => {
		  setCount(response)
		  setLoader(false)
	  }
  	);

  	useEffect(() => {
		CountDashboard.mutateAsync()
	}, [])

	const dropDown = authQueries.useBranchManagerFollowupDropDown(
        (res)=>{
        }
    )

    useEffect(() => {
        dropDown.mutateAsync()
    }, []);

	const slideArr = [
		{
			color: '#98ABEE',
			title: 'Total Active Clients',
			value: "TOTAL",
			icon: <Vote color='#98ABEE' size="20" /> ,
			count: count?.activeClient,
		},
		{
			color: '#FC6736',
			title: 'Total Active Registered',
			value: "REG",
			icon: <CheckCircle color='#FC6736' size="20" />,
			count: count?.registered,
		},
		{
			color: '#85586F',
			title: 'Registered Clients from Previous Financial Year(s)',
			value: "PREVREG",
			icon: <IconCalendarMinus color='#85586F' size="20" />,
			count: count?.registeredPrevious,
		},
		{
			color: '#0D9276',
			title: 'Registered Clients from Current Financial Year',
			value: "CURRREG",
			icon: <IconCalendarEvent color='#0D9276' size="20" />,
			count: count?.registeredCurrent,
		},
		{
			color: '#637A9F',
			title: 'Registered Clients from Future Financial Year',
			value: "FUTREG",
			icon: <IconCalendarPlus color='#637A9F' size="20" />,
			count: count?.registeredFuture,
		},
	];

	const slideArr2 = [
		{
			color: '#D63484',
			title: 'Ready to mark registered',
			value: "READY",
			icon: <IconCircleCheck color='#D63484' size="20" /> ,
			count: count?.readyToMarkRegistered,
		},
		{
			color: '#365486',
			title: 'Awaiting program Confirmation',
			value: "PROCONFIRM",
			icon: <IconHourglass color='#365486' size="20" />,
			count: count?.programConfirmationNeeded,
		},
		{
			color: '#76453B',
			title: 'Full Docs no Application Moved',
			value: "FULLDOC",
			icon: <IconDiscountCheck color='#76453B' size="20" />,
			count: count?.fullDocsNoAppMoved,
		},
		{
			color: '#DC84F3',
			title: 'Total Fee  paid  for current Financial year',
			value: "FEE",
			icon: <IconMoneybag color='#DC84F3' size="20" />,
			count: count?.feePaidCurrent,
		},
		{
			color: '#7ED7C1',
			title: 'Fund Ready Clients',
			value: "FUND",
			icon: <IconCoinPound color='#7ED7C1' size="20" />,
			count: count?.fundready,
		}
	];

  	const slider = () => {
 
		var swiper = new Swiper(".geebee-carousel.followup-pending", {
			slidesPerView: 6,
			spaceBetween: 10,
			speed: 700,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
				"1": {
					slidesPerView: 1,
					spaceBetween: 10,
                },
				'480': {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				'640': {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				'920': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
				'1200': {
					slidesPerView: 5,
					spaceBetween: 10,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next.carousel-next",
				prevEl: ".swiper-button-prev.carousel-prev",
			},
		});

	}

	useEffect(() => {
		slider();
		$('html,body').animate({ scrollTop: 0 }, 'fast');
	}, [loader]);

	const handleNavigate =(item)=>{
		resetAll()
		if(item?.value === "REG"){
			setRegistered("YES")
		}
		if(item?.value === "READY"){
			setStatus(6)
		}
		if(item?.value === "PROCONFIRM"){
			setStatus(5)
		}
		if(item?.value === "FEE"){
			setFeepaid(true)
			setFinancialYear("CURRENT")
		}
		if(item?.value === "PREVREG"){
			setRegistered("YES")
			setFinancialYear("PREVIOUS")
		}
		if(item?.value === "CURRREG"){
			setRegistered("YES")
			setFinancialYear("CURRENT")
		}
		if(item?.value === "FUTREG"){
			setRegistered("YES")
			setFinancialYear("FUTURE")
		}
		if(item?.value === "FULLDOC"){
			setFulldocappmoved(true)
		}
		if(item?.value === "FUND"){
			setFundReady(true)
		}
		setActiveTab(RouteConstants.BMLIST)
		navigate(RouteConstants.BMLIST)

	}
	
	return (
		<div>
			{loader ? 
				<div style={{display:'flex', justifyContent:'center', flexDirection:'column', marginTop:'10%'}}>
					<LoaderCommon  />
				</div>:
				<div>

					<div className="carousel-section mb-4">
						<div className="row">
							<div className="col-md-12">
								<div className="swiper-container followup-pending geebee-carousel">
									<div className="swiper-wrapper">
										{slideArr.map((item, index) => (
											<div
											onClick={()=>handleNavigate(item)}
											key={index} 
											className={`swiper-slide h-32 rounded-2xl text-white cursor-pointer`} 
											style={{background:item.color}}>
												<div className='flex flex-col p-3 justify-between h-full'>
													<div className='flex justify-between'>
														<div className='bg-white rounded-full flex justify-center items-center w-10 h-10 color-red-500'>{item.icon}</div>
														<div className='text-4xl'>
															<CountUp end={item?.count} duration={1} />
														</div>
													</div>
													<div>
														<div className='text-[14px]'>{item.title}</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
							<div className="col-md-12 mt-4">
								<div className="swiper-container followup-pending geebee-carousel">
									<div className="swiper-wrapper">
										{slideArr2.map((item, index) => (
											<div
											onClick={()=>handleNavigate(item)}
											key={index} 
											className={`swiper-slide h-32 rounded-2xl text-white cursor-pointer`} 
											style={{background:item.color}}>
												<div className='flex flex-col p-3 justify-between h-full'>
													<div className='flex justify-between'>
														<div className='bg-white rounded-full flex justify-center items-center w-10 h-10 color-red-500'>{item.icon}</div>
														<div className='text-4xl'>
															<CountUp end={item?.count} duration={1} />
														</div>
													</div>
													<div>
														<div className='text-[14px]'>{item.title}</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>

					<FollowupTodayBranchManager dropDown={dropDown} />
					<FollowupPendingBranchManager dropDown={dropDown} />

				</div>
			}
		</div>
	)
}

export default BranchManagerDashboard