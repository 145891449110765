/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import Swiper from 'swiper';
import 'swiper/css';
import $ from 'jquery'
import authQueries from '../../queries/authQueries';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../../store/userStore';
import { Card, Col, Row } from 'antd';
import { Loading } from '@nextui-org/react';


const Slides = ({ item }) => {

	const navigate = useNavigate()

	const storeData = userStore()

	const userList =()=> {
		if(item?.id){
			storeData.setBranch({status: item?.id})
		}else{
			storeData.setBranch({status: '0'})
		}
		navigate('/clientlist')
	}

	return (
		<div className="swiper-slide" style={{ cursor: 'pointer' }} onClick={userList}>
			<div className={"icon-and-title-count " + item.color}>

				<div className="icon-and-title">

					<i style={{color:item.color === "blue" ?"#52a0ef" : item.color}} className={item.icon}></i>
					<div className="count">{item?.count}</div>
				</div>
				<h2>{item.title}</h2>

			</div>

		</div>
	)
}

const SlidesReport = ({ item }) => {

	const navigate = useNavigate()

	const userList =()=> {
		navigate(item?.value)
	}

	return (
		<div className="swiper-slide" style={{ cursor: 'pointer' }} onClick={userList}>
			<div className={"icon-and-title-count " + item.color}>

				<div className="icon-and-title">

					<i style={{color:item.color === "blue" ?"#52a0ef" : item.color}} className={item.icon}></i>
					<div className="count">{item?.count}</div>
				</div>
				<h2>{item.title}</h2>

			</div>

		</div>
	)
}


const CEODashboard = () => {

	const [loader, setLoader] = useState(true)
	const [referenceResult, setReferenceResult] = useState([]);
	const [branchResult, setBranchResult] = useState([]);
	const [referenceMore, setReferenceMore] = useState({more: false, slice: 2, row: 6,  col: 12});
	const [branchMore, setBranchMore] = useState({more: false, slice: 1, row: 6,  col: 24});
	const [feePaid, setFeePaid] = useState({
		previous: 0,
		current: 0,
		future: 0,
	})

	useEffect(()=> {
		CeoDashboardCount.mutateAsync()
		feeDashboard.mutateAsync({assign:"NO"})
	},[])

	const CeoDashboardCount = authQueries.useCEODashboardMutation(
		(res) => {
			setLoader(false)
		}
	)

	const feeDashboard = authQueries.useCounselorDashboardFeePaidMutation(
		async (response) => {
		setFeePaid(
			{
			previous: response?.previous ? response?.previous : 0,
			current: response?.current ? response?.current : 0,
			future: response?.future ? response?.future : 0,
			}
		)
    });

	const slideArr = [
		{
			color: 'blue',
			title: 'Followup Required (Before registration)',
			value: "HOT",
            icon:"icon-user-add",
			count: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[0]?.count,
			id: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[0]?.id
		},
		{
			color: 'orange',
			title: 'Ready to Mark Registered',
			value: "WARM",
            icon: "icon-tick",
			count: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[1]?.count,
			id: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[1]?.id
		},
		{
			color: 'red',
			title: 'Not Interested',
			value: "COLD",
            icon: "icon-not_interested",
			count: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[2]?.count,
			id: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[2]?.id
		},
		{
			color: 'purple',
			title: 'Not Qualified',
			value: "REGISTER",
            icon: 'icon-notice1',
			count: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[3]?.count,
			id: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[3]?.id
		},
		{
			color: 'green',
			title: 'Program Confirmed',
			value: "LONG",
            icon: "icon-checkmark3",
			count: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[4]?.count,
			id: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[4]?.id
		},

	];

    const slideArr2 = [
		{
			color: 'tomato',
			title: 'Long Term Follow-up',
			value: "HOT",
            icon:"icon-hourglass_empty",
			count: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[5]?.count,
			id: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[5]?.id
		},

		{
			color: 'silver',
			title: 'Preparing Visa Documents',
			value: "WARM",
            icon: "icon-timer",
			count: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[6]?.count,
			id: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[6]?.id
		},

		{
			color: 'blueviolet',
			title: 'Visa Approved',
			value: "COLD",
            icon: "icon-flight",
			count: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[7]?.count,
			id: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[7]?.id
		},

		{
			color: 'black',
			title: 'Visa Refused',
			value: "REGISTER",
            icon: 'icon-cancel2',
			count: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[8]?.count,
			id: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[8]?.id
		},

		{
			color: 'gold',
			title: 'Others',
			value: "LONG",
            icon: "icon-group",
			count: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[9]?.count,
			id: CeoDashboardCount?.data?.data && CeoDashboardCount?.data?.data[9]?.id
		},

	];

	const slider = () => {

		var swiper = new Swiper(".geebee-carousel.followup-pending", {
			slidesPerView: 5,
			spaceBetween: 10,
			speed: 700,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
				"1": {
					slidesPerView: 1,
					spaceBetween: 10,
                },
				'480': {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				'640': {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				'920': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
				'1200': {
					slidesPerView: 5,
					spaceBetween: 10,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next.carousel-next",
				prevEl: ".swiper-button-prev.carousel-prev",
			},
		});

	}

	useEffect(() => {
		referenceCount.mutateAsync()
		branchCount.mutateAsync()
	}, [])

	const referenceCount = authQueries.useCeoDashboardReferenceCount(
		(response) => {
			setReferenceResult(response?.data);
		}
	)

	const branchCount = authQueries.useCeoDashboardBranchCount(
		(response) => {
			setBranchResult(response?.data);
		}
	)


	useEffect(() => {
		slider();
		$('html,body').animate({ scrollTop: 0 }, 'fast');
	}, [loader]);

	
	return (
		
		<div>

			{loader ? 
				<div style={{display:'flex', justifyContent:'center', flexDirection:'column', marginTop:'15%'}}>
					<Loading  />
					<span style={{marginTop:'10px', textAlign:'center', fontSize:'12px', color:'#888'}}>Loading...</span>
				</div>:
				<Fragment>
					
					<div className="followup-hding-section mb-4">
						<div className="row">
							<div className="col-md-6 followup-wrpr">
								<i className="followup-icon">
									<img src={require("../../assets/images/followup-today-icon.png")} width="" height="" alt="" />
								</i>
								<h2>Status of Prospects</h2>
							</div>
						</div>
					</div>


					<div className="carousel-section" style={{border:'none', marginBottom:'0px'}}>
						<div className="row">
							<div className="col-md-12">
								<div className="swiper-container followup-pending geebee-carousel">
									<div className="swiper-wrapper">
										{
											slideArr2.map((item, index) => (
												<Slides item={item} key={index} />
											))
										}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="carousel-section" style={{border:'none', marginBottom:'0px'}}>
						<div className="row">
							<div className="col-md-12">
								<div className="swiper-container followup-pending geebee-carousel">
									<div className="swiper-wrapper">
										{

											slideArr.map((item, index) => (
												<Slides item={item} key={index} />
											))

										}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='col-md-12'>
						<hr />
					</div>

					<div className="performance-report-section mt-5 mb-5">
						<div className="row">
							<div className="col-md-6">
								<div className="card border-0">
									<div className="row">
										<div className="col-md-12">
											<div className="pr-boxes-wrpr myallocatedClients">
												<h2>Fee Paid</h2>
												<div className="fee-paid-list">
													<div className="visa-column previous">
														<div className="list-item list-none">Previous Financial Year</div>
														<div className="visa-count"><span>{feePaid.previous}</span></div>
													</div>
													<div className="visa-column current">
														<div className="list-item list-none">Current Financial Year</div>
														<div className="visa-count"><span>{feePaid.current}</span></div>
													</div>
													<div className="visa-column future">
														<div className="list-item list-none">Future Financial Year</div>
														<div className="visa-count"><span>{feePaid.future}</span></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>	
							</div>
						</div>	
					</div>

					<div className='col-md-12'>
						<hr />
					</div>

					<div className="followup-hding-section mb-4">
						<div className="row">
							<div className="col-md-6 followup-wrpr">
								<i className="followup-icon">
									<img src={require("../../assets/images/followup-today-icon.png")} width="" height="" alt="" />
								</i>
								<h2>Other Reports</h2>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className={`col-md-${referenceMore?.row ? referenceMore?.row : 6}`} style={{marginBottom:'10px'}}>
							<Card
								style={{
									width: '100%',
									backgroundColor: 'rgba( 255, 255, 255, 0.25 )',
									boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
									backdropFilter: 'blur( 4px )',
									borderRadius: '10px',
									border: '1px solid rgba( 255, 255, 255, 0.18 )',
								}}
								title="Reference Report"
								extra={
									referenceMore?.more ?
									<a onClick={()=> setReferenceMore({more: false, slice: 2, row: 6, col: 12 })} style={{color:'#050df5'}}>Less</a>:
									<a onClick={()=> setReferenceMore({more: true, slice: referenceResult?.length, row: 12, col: 6 })} style={{color:'#050df5'}}>More</a>
								}
							>
								<Row gutter={8}>
									{referenceResult?.slice(0, referenceMore?.slice ? referenceMore?.slice : 2).map((item, index) => (
										<Col span={referenceMore?.col ? referenceMore?.col : 12}>
											<Card key={index} style={{boxShadow:'1px 1px 7px rgba(154, 154, 204, 0.1)', marginBottom:'10px'}}>
												<span style={{fontFamily:'Poppins', fontSize:12, fontWeight:500}}>{item?.name}</span>
												<div className='row'>
													<div className='col-md-6'>
														<div style={{float:'left', textAlign:'center'}}>
															<span style={{fontFamily:'Poppins', fontSize:30, fontWeight:500, float:'center'}}>{item?.count}</span><br />
															<span>Leads</span>
														</div>
													</div>
													<div className='col-md-6'>
														<div style={{float:'right', textAlign:'center'}}>
															<span style={{fontFamily:'Poppins', fontSize:30, fontWeight:500, float:'center'}}>{item?.visaCount}</span><br />
															<span>Visa</span>
														</div>
													</div>
												</div>
											</Card>
										</Col>
									))}
								</Row>

							</Card>
						</div>

						<div className={`col-md-${branchMore?.row ? branchMore?.row : 6}`} style={{marginBottom:'10px'}}>
							<Card
								style={{
									width: '100%',
									backgroundColor: 'rgba( 255, 255, 255, 0.25 )',
									boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
									backdropFilter: 'blur( 4px )',
									borderRadius: '10px',
									border: '1px solid rgba( 255, 255, 255, 0.18 )',
								}}
								title="Branch Report"
								extra={
									branchMore?.more ?
									<a onClick={()=> setBranchMore({more: false, slice: 1, row: 6, col: 24 })} style={{color:'#050df5'}}>Less</a>:
									<a onClick={()=> setBranchMore({more: true, slice: branchResult?.length, row: 12, col: 12 })} style={{color:'#050df5'}}>More</a>
								}
							>
								<Row gutter={8}>
									{branchResult?.slice(0, branchMore?.slice ? branchMore?.slice : 1).map((item, index) => (
										<Col span={branchMore?.col ? branchMore?.col : 24}>
											<Card key={index} style={{boxShadow:'1px 1px 7px rgba(154, 154, 204, 0.1)', marginBottom:'10px'}}>
												<span style={{fontFamily:'Poppins', fontSize:12, fontWeight:500}}>{item?.name}</span>
												<div className='row'>
													<div className='col' style={{display:'flex', justifyContent:'center'}}>
														<div style={{float:'left', textAlign:'center'}}>
															<span style={{fontFamily:'Poppins', fontSize:30, fontWeight:500, float:'center'}}>{item?.count}</span><br />
															<span>Leads</span>
														</div>
													</div>
													<div className='col' style={{display:'flex', justifyContent:'center'}}>
														<div style={{float:'left', textAlign:'center'}}>
															<span style={{fontFamily:'Poppins', fontSize:30, fontWeight:500, float:'center'}}>{item?.feepaid}</span><br />
															<span>Fee Paid</span>
														</div>
													</div>
													<div className='col' style={{display:'flex', justifyContent:'center'}}>
														<div style={{float:'left', textAlign:'center'}}>
															<span style={{fontFamily:'Poppins', fontSize:30, fontWeight:500, float:'center'}}>{item?.visaApproved}</span><br />
															<span>Visa Approved</span>
														</div>
													</div>
													<div className='col' style={{display:'flex', justifyContent:'center'}}>
														<div style={{float:'left', textAlign:'center'}}>
															<span style={{fontFamily:'Poppins', fontSize:30, fontWeight:500, float:'center'}}>{item?.notInterested}</span><br />
															<span>Not Interested</span>
														</div>
													</div>
													<div className='col' style={{display:'flex', justifyContent:'center'}}>
														<div style={{float:'left', textAlign:'center'}}>
															<span style={{fontFamily:'Poppins', fontSize:30, fontWeight:500, float:'center'}}>{item?.onHold}</span><br />
															<span>On Hold</span>
														</div>
													</div>
												</div>

											</Card>
										</Col>
									))}
								</Row>

							</Card>
						</div>

					</div>

				</Fragment>
			}

		</div>

	)
}

export default CEODashboard

