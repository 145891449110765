import moment from "moment";
import create from "zustand";

export const useAdvancedSearch = create((set) => {
    const initialState = {
        name: "",
        email: "",
        mobile: "",
        visa: "",
        status: "",
        intake: "",
        office: "",
        district: "",
        college: "",
        reffered: "",
        appQualify: "",
        appStatus: "",
        registered: "ALL",
        counselor: "",
        hot: true,
        warm: true,
        cold: true,
        long: true,
        hold: true,
        feepaid: false,
        financialYear: "",
        fulldocappmoved: false,
        fundReady: false,
        notproceeding: false,
        prgmCoordinatorFile: "ALL",
        documentStaff: "",
        pgCoordinator: "",
        admissionStaff: "",
        feeCoordinator: "",
        visaStaff: "",
        createdFrom: "",
        // createdTo: "",
        createdTo: moment().format("YYYY-MM-DD"), 
    }
    return{
      ...initialState,
      setName: (element) => set({ name: element }),
      setEmail: (element) => set({ email: element }),
      setMobile: (element) => set({ mobile: element }),
      setVisa: (element) => set({ visa: element }),
      setStatus: (element) => set({ status: element }),
      setIntake: (element) => set({ intake: element }),
      setOffice: (element) => set({ office: element }),
      setDistrict: (element) => set({ district: element }),
      setCollege: (element) => set({ college: element }),
      setReffered: (element) => set({ reffered: element }),
      setAppQualify: (element) => set({ appQualify: element }),
      setAppStatus: (element) => set({ appStatus: element }),
      setCounselor: (element) => set({ counselor: element }),
      setHot: (element) => set({ hot: element }),
      setWarm: (element) => set({ warm: element }),
      setCold: (element) => set({ cold: element }),
      setLong: (element) => set({ long: element }),
      setHold: (element) => set({ hold: element }),
      setRegistered: (element) => set({ registered: element }),
      setFeepaid: (element) => set({ feepaid: element }),
      setFinancialYear: (element) => set({ financialYear: element }),
      setFulldocappmoved: (element) => set({ fulldocappmoved: element }),
      setFundReady: (element) => set({ fundReady: element }),
      setNotProceeding: (element) => set({ notproceeding: element }),
      setPgmCoordinatorFile: (element) => set({ prgmCoordinatorFile: element }),
      setDocumentStaff: (element) => set({ documentStaff: element }),
      setPgCoordinator: (element) => set({ pgCoordinator: element }),
      setAdmissionStaff: (element) => set({ admissionStaff: element }),
      setFeeCoordinator: (element) => set({ feeCoordinator: element }),
      setVisaStaff: (element) => set({ visaStaff: element }),
      setCreatedFrom: (element) => set({ createdFrom: element }),
      setCreatedTo: (element) => set({ createdTo: element }),
      onlyHot: (element) => set({ hot: true, warm: false, cold: false, long: false, hold: false, registered: "NO" }),
      onlyWarm: (element) => set({ hot: false, warm: true, cold: false, long: false, hold: false, registered: "NO" }),
      onlyCold: (element) => set({ hot: false, warm: false, cold: true, long: false, hold: false, registered: "NO" }),
      onlyHold: (element) => set({ hot: false, warm: false, cold: false, long: false, hold: true, registered: "NO" }),
      onlyLong: (element) => set({ hot: false, warm: false, cold: false, long: true, hold: false, registered: "NO" }),
      resetAll: () => set(initialState)
    }
  });