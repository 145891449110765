import React, { Fragment } from 'react'

const InputSelectForm = ({item, register, errors, options,optionProps}) => {
  return (
    <Fragment>
        <div className="text-[12px] h-4 font-[500]">{item?.label} {item?.required &&<span className="text-[red]">*</span>}</div>
        <select
        className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
        type={item?.type}
        placeholder={item?.label}
        name={item?.name}
        {...register(`${item.name}`)}
        >
            <option value="">Select</option>
            {
              options?.map((item, index)=>(
                <option key={index} value={item?.[optionProps?.value]}>{item?.[optionProps?.name]}</option>
              ))
            }
        </select>
        <div className='text-[12px] text-red-600'>{errors?.[item?.name]?.message}</div>
    </Fragment>
  )
}

export default InputSelectForm