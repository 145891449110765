import { yupResolver } from '@hookform/resolvers/yup'
import React,{useState, useEffect, Fragment} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import authQueries from '../../../queries/authQueries'
import * as yup from 'yup'
import { message as messages } from 'antd'
import { Checkbox, Loading, Switch } from '@nextui-org/react'
import { userStore } from '../../../store/userStore'
import moment from 'moment'
import {AutoCompleteAntd, AutoCompleteAntdOffice, AutoCompleteChild, AutoCompleteChilds, AutoCompleteIntake}  from './AutoComplete'
import 'react-phone-input-2/lib/bootstrap.css'
import EducationalDetails from './EducationalDetails'
import WorkExp from './WorkExp'
import IntendedCollege from './IntendedCollege'
import DocumentDetails from './DocumentDetails'
import EnglishProficiency from './EnglishProficiency'
import OverseasEducation from './OverseasEducation'
import Academic from './Academic'
import TravelHistory from './TravelHistory'
import OtherDetails from './OtherDetails'
import LoaderCommon from '../../Common/LoaderCommon'
import toast from 'react-hot-toast'
import PhoneInput from 'react-phone-input-2'
import { IconBackspace, IconDeviceFloppy } from '@tabler/icons-react'
import parsePhoneNumberFromString, { parsePhoneNumber } from 'libphonenumber-js'

const ApplicantDetails = ({lead_status}) => {

    const appStore = userStore();

    const [resultVisa, setResultVisa] = useState([]);
    const [intakeResult, setIntakeResult] = useState([]);
    const [refResult, setRefResult] = useState([]);
    const [officeResult, setOfficeResult] = useState([]);
    const [qualifyResult, setQualifyResult] = useState([]);
    const [userResult, setUserResult] = useState([])
    const [childAge, setChildAge] = useState(0);
    const [agee, setAgee] = useState(null); 
    const [spouseData, setSpouseData] = useState(false)
    const [office, setOffice] = useState(null)
    const [code, setCode] = useState('in');

    const [name, setName] = useState('');
    const [mName, setMName] = useState('')
    const [lName, setLName] = useState('')
    const [mobile, setMobile] = useState(appStore?.appAllData?.applicant?.mobile_no);
    const [whatsapp, setWhatsapp] = useState(appStore?.appAllData?.applicant?.whatsapp_no);
    const [landline, setLandLine] = useState(appStore?.appAllData?.applicant?.landline_no);
    const [email, setEmail] = useState('');
    const [today, setToday] = useState(moment().format('YYYY-MM-DD'));
    const [ageeSpouse,setAgeeSpouse] = useState(null);
    const [visaid, setVisaId] = useState(appStore?.appAllData?.applicant?.visa_type_id);
    const [image, setImage] = useState(appStore?.appAllData?.applicant?.photo)
    const [loader, setLoader] = useState(true)
    const [emailExist, setEmailExist] = useState('')
    const [mobileExist, setMobileExist] = useState('')
    const [mobileValid, setMobileValid] = useState('')
    const [whatsappExist, setWhatsappExist] = useState('')
    const [landLineExist, setLandLineExist] = useState('')

    const [visaData, setVisaData] = useState('');
    const [intakeData, setIntakeData] = useState('');
    const [refferData, setRefferData] = useState('');
    const [officeData, setOfficeData] = useState('');
    const [qualData, setQualData] = useState('');
    const [spoQualData, setSpoQualData] = useState('');
    const [follow, setFollow] = useState(false)

    const [users, setUsers] = useState({loan: 'NO', interview: 'NO', travel: 'NO', gic: 'NO', fundready: 'NO'})

    const visaType = (response) => {
            setVisaData(appStore?.appAllData?.applicant?.visa_type_id)
            let dataArray = []
            for(let datas of response){
                const dataValues = {
                    label:datas?.name,
                    value:datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }
            setResultVisa(dataArray)
    }


    const inTake = (response) => {
        setIntakeData(appStore?.appAllData?.applicant?.intake_id) 
        let dataArray = []
        for(let datas of response){
            const dataValues = {
                label:datas?.name,
                value:datas?.id
            }

            dataArray = [...dataArray, dataValues]
        }
        setIntakeResult(dataArray)
    }

    const Refferal = (response) => {
        setRefferData(appStore?.appAllData?.applicant?.referral_id)
        let dataArray = []
        for(let datas of response){
            const dataValues = {
                label:datas?.name,
                value:datas?.id
            }

            dataArray = [...dataArray, dataValues]
        }
        setRefResult(dataArray)
    }

    const Office = (response) => {
        setOfficeData(appStore?.appAllData?.applicant?.office_id)
        let dataArray = []
        for(let datas of response){
            const dataValues = {
                label:datas?.name,
                value:datas?.id
            }

            dataArray = [...dataArray, dataValues]
        }
        setOfficeResult(dataArray)
    }

    const Qualify = (response) => {
        setQualData(appStore?.appAllData?.applicant?.qid)
        setSpoQualData(appStore?.appAllData?.applicant?.spouse?.qid)
        let dataArray = []
        for(let datas of response){
            const dataValues = {
                label:datas?.name,
                value:datas?.id
            }
            dataArray = [...dataArray, dataValues]
        }
        setQualifyResult(dataArray)
    }

    const formMastersList = authQueries.useApplicantFormMastersList(
		(res)=>{
            visaType(res?.data?.visatype)
            inTake(res?.data?.intake)
            Office(res?.data?.office)                
            Refferal(res?.data?.refferal)
            Qualify(res?.data?.qualification)
            setUserResult(res?.data?.users)
            setLoader(false)
		}
	)

    useEffect(() => {
        reset({...appStore?.appAllData?.applicant})
        if(appStore.appid && appStore?.appAllData?.applicant?.dob){
            const isAge = ageCalculate(appStore?.appAllData?.applicant?.dob)
            setAgee(isAge)
        }
        if(appStore.appid && appStore?.appAllData?.applicant?.spouse?.dob){
            const isAge = ageCalculate(appStore?.appAllData?.applicant?.spouse?.dob)
            setAgeeSpouse(isAge)
        }
        if(appStore?.user?.role === "COUNSELOR"){
            setValue('followassign', appStore?.user?.id)
        }
        setSpouseData(appStore?.appAllData?.applicant?.spouse_status === "TRUE" ? true: false)
        setChildAge(appStore?.appAllData?.applicant?.no_of_children ? appStore?.appAllData?.applicant?.no_of_children : 0)
        setUsers({loan: appStore?.appAllData?.applicant?.loan, interview: appStore?.appAllData?.applicant?.interview, travel: appStore?.appAllData?.applicant?.travel, gic: appStore?.appAllData?.applicant?.gic, fundready: appStore?.appAllData?.applicant?.fundready})
    }, []);
  
    const masterCall = async ()=> {
        formMastersList.mutateAsync({visa: visaid ? visaid :appStore?.appAllData?.applicant?.visa_type_id, office: office})
    }

    useEffect(()=>{  
        masterCall();
    },[visaid, office])

    const applicantSchema = yup.object({
        visa_type_id: yup
            .string('⚠ Please enter visa type')
            .required('⚠ Visa type is required'),
        intake_id: yup
            .string('⚠ Please enter intake')
            .required('⚠ Intake is required'),
        office_id: yup
            .string('⚠ Please enter office')
            .required('⚠ Office is required'),
        referral_id: yup
            .string('⚠ Please enter reffered')
            .required('⚠ Reffered is required'),
        applicant_name: yup
            .string('⚠ Please enter applicant firstname')
            .required('⚠ Applicant firstname is required'),
        lastname: yup
            .string('⚠ Please enter applicant lastname')
            .required('⚠ Applicant lastname is required'),
        email: yup
            .string('⚠ Please enter applicant email')
            .required('⚠ Applicant email is required')
            .nullable(),
        mobile_no: yup
            .string('⚠ Please enter applicant mobile')
            .required('⚠ Applicant mobile is required'),
        comment: yup
            .string('⚠ Please enter Prospect comment')
            .required('⚠ Prospect comment is required'),
        foldate: yup
            .string()
            .when('followup', {
                is: true,
                then: yup.string().required('⚠ Follow-up date is required'),
                otherwise: yup.string()
            }),
        followassign: yup
            .string()
            .when('followup', {
                is: true,
                then: yup.string().required('⚠ Follow-up Assign is required'),
                otherwise: yup.string()
            })
    });
    

    const applicantSchemaUpdate = yup.object({
        visa_type_id: yup
            .string('⚠ Please enter visa type')
            .required('⚠ Visa type is required'),
        intake_id: yup
            .string('⚠ Please enter intake')
            .required('⚠ Intake is required'),
        office_id: yup
            .string('⚠ Please enter office')
            .required('⚠ Office is required'),
        referral_id: yup
            .string('⚠ Please enter reffered')
            .required('⚠ Reffered is required'),
        applicant_name: yup
            .string('⚠ Please enter applicant name')
            .required('⚠ Applicant name is required'),
        email: yup
            .string('⚠ Please enter applicant email')
            .required('⚠ Applicant email is required')
            .nullable(),
        mobile_no: yup
            .string('⚠ Please enter applicant mobile')
            .required('⚠ Applicant mobile is required'),
    });

    const spouseSchema = yup.object({
        visa_type_id: yup
            .string('⚠ Please enter visa type')
            .required('⚠ Visa type is required'),
        intake_id: yup
            .string('⚠ Please enter intake')
            .required('⚠ Intake is required'),
        office_id: yup
            .string('⚠ Please enter office')
            .required('⚠ Office is required'),
        referral_id: yup
            .string('⚠ Please enter reffered')
            .required('⚠ Reffered is required'),
        applicant_name: yup
            .string('⚠ Please enter applicant name')
            .required('⚠ Applicant name is required'),
        email: yup
            .string('⚠ Please enter applicant email')
            .required('⚠ Applicant email is required')
            .nullable(),
        mobile_no: yup
            .string('⚠ Please enter applicant mobile')
            .required('⚠ Applicant mobile is required'),
        spouse: yup.object().shape({
            spouse_name: yup
                .string('⚠ Please enter spouse name')
                .required('⚠ Spouse name is required')
                .nullable(),
        }),
        comment: yup
            .string('⚠ Please enter Prospect comment')
            .required('⚠ Prospect comment is required'),
        foldate: yup
            .string()
            .when('followup', {
                is: true,
                then: yup.string().required('⚠ Follow-up date is required'),
                otherwise: yup.string()
            }),
        followassign: yup
            .string()
            .when('followup', {
                is: true,
                then: yup.string().required('⚠ Follow-up Assign is required'),
                otherwise: yup.string()
            })
    });

    const spouseSchemaUpdate = yup.object({
        visa_type_id: yup
            .string('⚠ Please enter visa type')
            .required('⚠ Visa type is required'),
        intake_id: yup
            .string('⚠ Please enter intake')
            .required('⚠ Intake is required'),
        office_id: yup
            .string('⚠ Please enter office')
            .required('⚠ Office is required'),
        referral_id: yup
            .string('⚠ Please enter reffered')
            .required('⚠ Reffered is required'),
        applicant_name: yup
            .string('⚠ Please enter applicant name')
            .required('⚠ Applicant name is required'),
        email: yup
            .string('⚠ Please enter applicant email')
            .required('⚠ Applicant email is required')
            .nullable(),
        mobile_no: yup
            .string('⚠ Please enter applicant mobile')
            .required('⚠ Applicant mobile is required'),
        spouse: yup.object().shape({
            spouse_name: yup
                .string('⚠ Please enter spouse name')
                .required('⚠ Spouse name is required')
                .nullable(),
        }),
    });

    const navigate = useNavigate();

    const { register, reset, control, handleSubmit, formState:{ errors }, setValue, getValues, setError, clearErrors} = useForm({
         resolver: yupResolver(appStore.appid? (spouseData?spouseSchemaUpdate:applicantSchemaUpdate):(spouseData?spouseSchema:applicantSchema))
    });

    const addApplication = authQueries.useApplicantMutationAdd(
        async (response) => {
            if(response?.message === 'Error'){
                const mobError = response?.error?.find(item => item.mobile_no)
                if(mobError){
                    setError('mobile_no', {message: mobError?.mobile_no})
                }
            }
            if(response?.message === "Success"){ 
                if(appStore.user.role === "TELECALLER"){
                    navigate('/dashboard')
                }
                await appStore.setApplication(response?.data?.id)
                viewAllApplicationEditData.mutateAsync(response?.data?.id) 
                toast.success("successfully created")
            }
        }   
    );

	const viewAllApplicationEditData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
                reset({...response.data.applicant})
            }
        }
    )

    const onSubmit = (data) => {

        if(emailExist || mobileExist || mobileValid || whatsappExist || landLineExist){
            return
        }

        data.lead_status = lead_status
        data.spouse_status = spouseData ? "TRUE" : "FALSE"
        data.photo = image
        data.follow = follow
        data.loan = users?.loan
        data.travel = users?.travel
        data.fundready = users?.fundready
        addApplication.mutateAsync(data);

    }


    const editApplication = authQueries.useApplicantMutationEdit(
        (response) => {
            if(response?.message === 'Error'){
                const mobError = response?.error?.find(item => item.mobile_no)
                if(mobError){
                    setError('mobile_no', {message: mobError?.mobile_no})
                }
            }
            if(response?.message === "Success"){ 
                toast.success("successfully updated")
                viewAllApplicationEditData.mutateAsync(appStore.appid)
                if(appStore.user.role === "TELECALLER"){
                    navigate('/dashboard')
                }
            }
        }   
    );

    const onEdit = (data) => {
        if(emailExist){
            return
        }
        if(mobileExist && appStore?.appAllData?.applicant?.registered_status !== "YES"){
            return
        }
        if(whatsappExist && appStore?.appAllData?.applicant?.registered_status !== "YES"){
            return
        }
        if(landLineExist && appStore?.appAllData?.applicant?.registered_status !== "YES"){
            return
        }
        if(mobileValid){
            return
        }

        data.lead_status = lead_status
        data.spouse_status = spouseData ? "TRUE" : "FALSE"
        data.photo = image
        data.loan = users?.loan
        data.travel = users?.travel
        data.fundready = users?.fundready
        data.id = appStore.appid
        editApplication.mutateAsync(data);
    }

	const changeBirthHandler =(e)=> {
        const isAge = ageCalculate(e.target.value)
        setAgee(isAge)
	}

    const changeBirthHandlerSpouse =(e)=> {
        const isAge = ageCalculate(e.target.value)
        setAgeeSpouse(isAge)
	}

    const emailValidation =({email, visa})=>{
        if(email && email.length > 8){
            emailValidApplicant.mutateAsync({email: email, visa:visa, appId:appStore.appid?appStore.appid:null})
        }
    }

    const emailValidApplicant = authQueries.useEmailValidApplicant(
        (response) => {
            if(response?.message === "Email already exist"){
                setEmailExist("Email already exist")
            }else{
                setEmailExist('')
            }
        }   
    );

    const mobileValidation =({number, visa, type})=>{
        mobileValidApplicant.mutateAsync({mobile: number, visa:visa, appId:appStore.appid?appStore.appid:null, type: type})
    }

    const mobileValidApplicant = authQueries.useMobileValidApplicant(
        (response) => {
            if(response?.message === "Mobile already exist"){
                if(response.type === "MOBILE"){
                    setMobileExist("Mobile number already exist")
                }else if(response.type === "WHATSAPP"){
                    setWhatsappExist("Whatsapp number already exist")
                }else if(response.type === "LANDLINE"){
                    setLandLineExist("Landline number already exist")
                }
            }else{
                setMobileExist('')
                setWhatsappExist('')
                setLandLineExist('')
            }
        }   
    );

    const handleChange =(e)=>{
        const data = new FormData();
        data.append('file', e)
        imageUploadCreate.mutateAsync(data)
    }


    const imageUploadCreate = authQueries.useFileUploadCommon(
        (response) => {
            if(response?.message === "success"){
                setImage(response?.file)
                return
            }

            toast.error(response?.message)

        }   
    );


  return (

    <div>

    {loader ? 
        <LoaderCommon /> :
        <Fragment>

                <div className="card">
                    <div className="card-header">
                        <a className="card-link" data-toggle="collapse" href="#applicant-details">
                            Applicant Details -
                            <span className="applicant-details-wrpr pl-4">
                                <div className='flex gap-2 flex-wrap'>
                                    <div className='flex gap-2'>
                                        <i className="icon-user text-[14px]"></i>
                                        <div>
                                            {name ? name : appStore?.appAllData?.applicant?.applicant_name}&nbsp;
                                            {mName ? mName : appStore?.appAllData?.applicant?.middlename}&nbsp;
                                            {lName ? lName : appStore?.appAllData?.applicant?.lastname }&nbsp;
                                        </div>
                                    </div>
                                    <div className='flex gap-2'>
                                        <i className="icon-envelope3 text-[14px]"></i>
                                        <div>
                                            {email ? email : appStore?.appAllData?.applicant?.email}
                                        </div>
                                    </div>
                                    <div className='flex gap-2'>
                                        <i className="icon-whatsapp mt-[2px]"></i>
                                        <div>
                                            {whatsapp ? whatsapp : appStore?.appAllData?.applicant?.whatsapp_no}
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </a>
                        {appStore.appId &&
                        <div data-fieldspending={"3"} className="tab-info fields-notcompleted">
                            <span className="icon-cancel1"></span>
                        </div>
                        }
                    </div>
                    <div id="applicant-details" className="collapse show" data-parent="#accordion">
                        <form  onSubmit={handleSubmit(appStore.appid === ''?onSubmit:onEdit)}>
                            <div className="card-body">
                                <div className="row">
                                    <div className='col-md-12 flex justify-between items-center mb-4'>
                                        <div className="form-group include-spouse mb-0">
                                            <div className="custom-control custom-switch" style={{display:'flex', gap: 12, alignItems:'center'}}>
                                                <Switch size="sm" onChange={()=>setSpouseData(!spouseData)} checked={spouseData} />
                                                <span>Include spouse Details</span>
                                            </div>
                                        </div>
                                        <div className="profile-wrpr" style={{marginLeft: 0, marginRight: 0}}>
                                            <div id="image-preview" className='profile-img' style={{backgroundSize:"cover", 
                                            backgroundImage:image?
                                            `url(${image})`:`url(${require('../../../assets/images/profile-avatar.jpg')})`
                                            }}>
                                                <label htmlFor="image-upload" id="image-label">Choose File</label>
                                                <input onChange={(e)=>{handleChange(e.target.files[0])}} type="file" name="image" id="image-upload" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div>
                                            <label htmlFor="typevisa">Type of Visa:<em className="mandatory">*</em></label>
                                                <AutoCompleteChilds
                                                    dis={appStore?.appAllData?.applicant?.registered_status === "YES" ? true : false}  
                                                    control={control} 
                                                    data={resultVisa} 
                                                    name={"visa_type_id"} 
                                                    value={visaData} 
                                                    emailValid={emailValidation} 
                                                    mobileValid={mobileValidation}
                                                    whatsappValid={mobileValidation}
                                                    landlineValid={mobileValidation}
                                                    email={email}
                                                    mobile={mobile}
                                                    whatsapp={whatsapp}
                                                    landline={landline}
                                                    setVisaId={setVisaId}
                                                    error={errors.visa_type_id}
                                                />
                                                <div className='error-msg'>{errors.visa_type_id?.message}</div>
                                            </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div>
                                            <label htmlFor="intake">Intake:<em className="mandatory">*</em></label>
                                            <AutoCompleteAntd
                                                dis={(appStore?.appAllData?.applicant?.registered_status === "YES" && appStore?.user?.role === "COUNSELOR") ? true : false}  
                                                control={control} 
                                                data={intakeResult} 
                                                name={"intake_id"} 
                                                value={intakeData} 
                                                error={errors.intake_id}
                                            />
                                            <div className="error-msg">{errors.intake_id?.message}</div>

                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div>
                                            <label htmlFor="reffered-by">Reffered By:<em className="mandatory">*</em></label>
                                            <AutoCompleteAntd
                                                dis={(appStore?.appAllData?.applicant?.registered_status === "YES" || appStore?.user?.role === "REGISTRATION" || appStore?.user?.role === "ADMISSIONSTAFF") ? true : false}  
                                                control={control} 
                                                data={refResult} 
                                                name={"referral_id"}
                                                value={refferData} 
                                                error={errors.referral_id}
                                            />
                                            <div className="error-msg">{errors.referral_id?.message}</div>

                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div>
                                            <label htmlFor="enrolling-office">Enrolling Office:<em className="mandatory">*</em></label>
                                            <AutoCompleteAntdOffice
                                                dis={(appStore?.user?.role === "REGISTRATION" || appStore?.user?.role === "ADMISSIONSTAFF") ? true : false}
                                                setOffice={setOffice}
                                                control={control}
                                                data={officeResult} 
                                                name={"office_id"} 
                                                value={officeData} 
                                                error={errors.office_id}
                                            />
                                            <div className="error-msg">{errors.office_id?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className={errors.applicant_name?"form-group error":"form-group"}>
                                            <label htmlFor="applicant_name">First Name:<em className="mandatory">*</em></label>
                                            <input
                                                {...register('applicant_name', {
                                                    onChange: (e) => {setName(e.target.value)},
                                                    onBlur: (e) => {},
                                                })}
                                                name='applicant_name' 
                                                id="applicant_name" 
                                                type="text" 
                                                // onChange={(e)=>{setName(e.target.value)}} 
                                                className="form-control" /> 
                                            <div className="error-msg">{errors.applicant_name?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div>
                                            <label htmlFor="applicant_name">Middle Name</label>
                                            <input
                                                {...register('middlename', {
                                                    onChange: (e) => {setMName(e.target.value)},
                                                    onBlur: (e) => {},
                                                })}
                                                name='middlename' 
                                                id="applicant_name" 
                                                type="text"
                                                className="form-control" /> 
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className={errors.lastname?"form-group error":"form-group"}>
                                            <label htmlFor="applicant_name">Last Name:<em className="mandatory">*</em></label>
                                            <input
                                                {...register('lastname', {
                                                    onChange: (e) => {setLName(e.target.value)},
                                                    onBlur: (e) => {},
                                                })}
                                                name='lastname' 
                                                id="applicant_name" 
                                                type="text"
                                                className="form-control" /> 
                                            <div className="error-msg">{errors.lastname?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className={errors.marital_status?"form-group error":"form-group"}>
                                            <label htmlFor="marital">Marital:</label>
                                            <select {...register("marital_status")} id="marital" className="form-control" name="marital_status">
                                                <option value="SINGLE">Single</option>
                                                <option value="MARRIED">Married</option>
                                                <option value="WIDOWED">Widowed</option>
                                                <option value="DIVORCED">Divorced</option>
                                                <option value="SEPARATED">Separated</option>
                                            </select> 
                                            <div className="error-msg">{errors.marital_status?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className={errors.email?"form-group error":"form-group"}>
                                            <label htmlFor="spouse_name">Email Address:<em className="mandatory">*</em></label>
                                            <input 
                                                disabled={(appStore?.user?.role === "REGISTRATION" || appStore?.user?.role === "ADMISSIONSTAFF") ? true : false}
                                                {...register('email', {
                                                    onChange: (e) => {setEmail(e.target.value); emailValidation({email:e.target.value, visa:visaid});},
                                                    onBlur: (e) => {},
                                                })}
                                                id="spouse_name" 
                                                type="email" 
                                                className="form-control" 
                                                name="email" />
                                            <div className="error-msg">{errors.email?.message} {emailExist}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className={errors.mobile_no?"form-group error":"form-group"}>
                                            <label htmlFor="spouse_name">Mobile Number:<em className="mandatory">*</em></label>
                                                <Controller
                                                    name='mobile_no'
                                                    control={control}
                                                    className="form-control"
                                                    render={({ field: { onChange } }) => (
                                                        <PhoneInput
                                                            enableSearch={true}
                                                            country={code}
                                                            value={appStore?.appAllData?.applicant?.mobile_no}
                                                            onChange={(value, country, e, formattedValue) => {
                                                                const digitsOnly = formattedValue.replace(/\D/g, ''); // Remove non-digit characters
                                                                const countryCodeLength = country.countryCode.length; // Get length of country code
                                                                const extractedPhoneNumber = digitsOnly.slice(countryCodeLength);
                                                            
                                                                const parsedPhoneNumber = parsePhoneNumberFromString(`+${value}`);
                                                                if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                                                                    setCode(country.countryCode)
                                                                    onChange(value)    
                                                                    setMobile(extractedPhoneNumber);
                                                                    setMobileValid('')
                                                                    mobileValidation({number:extractedPhoneNumber, visa:visaid, type:"MOBILE"});
                                                                } else {
                                                                    setMobileExist('')
                                                                    setMobileValid("Provide valid Mobile no")
                                                                }
                                                            }}
                                                        /> 
                                                    )}
                                                    rules={{ required: true }}
                                                />
                                                <div className="text-orange-500 text-[12px]">
                                                    {!appStore?.appAllData?.applicant?.mobile_no && appStore?.appAllData?.applicant?.mobile_old}
                                                </div>
                                            <div className="error-msg">{errors.mobile_no?.message} {mobileExist} {mobileValid}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="spouse_name">Landline No:</label>
                                            <input
                                            {...register("landline_no", {
                                                onChange: (e) => {setLandLine(e.target.value); mobileValidation({number:e.target.value, visa:visaid, type:"LANDLINE"})},
                                            })} 
                                            id="spouse_name" type="number" 
                                            className="form-control" name="landline_no" /> 
                                            <div className="error-msg">{landLineExist}</div>   
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="spouse_name">Whatsapp Number:</label>
                                            <input
                                            {...register("whatsapp_no", {
                                                onChange: (e) => {setWhatsapp(e.target.value); mobileValidation({number:e.target.value, visa:visaid, type:"WHATSAPP"})},
                                            })} 
                                            id="spouse_name"
                                            type="number" 
                                            className="form-control" 
                                            name="whatsapp_no" 
                                            />
                                            <div className="error-msg">{whatsappExist}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="spouse_name">Pin Code: </label>
                                            <input {...register("pincode")} id="spouse_name" type="number" className="form-control" name="pincode" />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="spouse_name">District</label>
                                            <select {...register("did")} id="spouse_name" type="number" className="form-control" name="did">
                                                <option value="">Select District</option>
                                                {
                                                    formMastersList?.data?.data?.district?.map((item, index) => (
                                                        <option value={item.id} key={index}>{item?.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="spouse_name">Address</label>
                                            <textarea {...register("address")} id="spouse_name" type="number" className="form-control" name="address" />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div>
                                            <label htmlFor="spouse_name">Qualification of Applicant:</label>
                                            <AutoCompleteAntd 
                                                control={control} 
                                                data={qualifyResult} 
                                                name={"qid"} 
                                                value={qualData} 
                                                error={errors.qid}
                                            />
                                            <div className="error-msg">{errors.qid?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className={errors.occupation?"form-group error":"form-group"}>
                                            <label htmlFor="spouse_name">Occupation of Applicant:</label>
                                            <input {...register("occupation")} id="spouse_name" type="text" className="form-control" name="occupation" />
                                            <div className="error-msg">{errors.occupation?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div>
                                            <label htmlFor="spouse_name">Gender:</label>
                                            <select {...register("gender")} name="gender" className="form-control">
                                                <option value="">Select</option>
                                                <option value="MALE">Male</option>
                                                <option value="FEMALE">Female</option>
                                                <option value="OTHERS">Others</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className={errors.dob?"form-group error":"form-group"}>
                                            <label htmlFor="spouse_name">Date of Birth:</label>
                                            <input 
                                            max={today} 
                                            type="date" 
                                            {...register("dob", {
                                                onChange: (e)=> {changeBirthHandler(e)} 
                                            })} 
                                            name='dob' 
                                            className="form-control applicant-date" 
                                            />                                               
                                            <div className="error-msg">{errors.dob?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="form-group spouse-toggle-visible active">
                                            <label htmlFor="spouse_name">Age:</label>
                                            <input value={agee} disabled id="spouse_name" type="number" className="form-control font-semibold text-black" name="age" />
                                            <div className="error-msg">{errors.age?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="spouse_name">Passport No.:</label>
                                            <input {...register("passport_no")} id="spouse_name" type="text" className="form-control" name='passport_no' />
                                        </div>	
                                    </div>
                                    <div className="col-md-2">
                                        <div className={errors.aadhar_no?"form-group error":"form-group"}>
                                            <label htmlFor="spouse_name">Aadhar No.:</label>
                                            <input {...register("aadhar_no")} id="spouse_name" type="text" className="form-control" name='aadhar_no' />
                                            <div className="error-msg">{errors.aadhar_no?.message}</div>
                                        </div>	
                                    </div>
                                    <div className="col-md-3">
                                        <div className={spouseData? errors?.spouse?.spouse_name? "form-group error": "form-group spouse-toggle-visible active":"form-group spouse-toggle-visible"}>
                                            <label htmlFor="spouse_name">Full Name of the Spouse:</label>
                                            <input disabled={spouseData?false:true} {...register("spouse.spouse_name")} id="spouse_name" type="text" className="form-control" name="spouse.spouse_name" />
                                            <div className="error-msg">{spouseData?errors?.spouse?.spouse_name?.message:null}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className={spouseData? "form-group spouse-toggle-visible active":"form-group spouse-toggle-visible"}>
                                            <label htmlFor="spouse_name">Occupation of Spouse:</label>
                                            <input disabled={spouseData?false:true} {...register("spouse.occupation")} id="spouse_name" type="text" className="form-control" name="spouse.occupation"/>  
                                            {/* <div className="error-msg">{errors.spoOccu?.message}</div> */}
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className={spouseData? errors.spoDob? "form-group error": "form-group spouse-toggle-visible active":"form-group spouse-toggle-visible"}>
                                            <label htmlFor="spouse_name">Spouse Date of Birth:</label>
                                            <input 
                                            disabled={spouseData?false:true} 
                                            max={today} type="date" 
                                            {...register("spouse.dob", {
                                                onChange: (e) => {changeBirthHandlerSpouse(e)} 
                                            })} 
                                            name='spouse.dob' 
                                            className="form-control applicant-date" 
                                            />                                               
                                            <div className="error-msg">{errors.spoDob?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className={spouseData? "form-group spouse-toggle-visible active":"form-group spouse-toggle-visible"}>
                                            <label htmlFor="spouse_name">Spouse Age:</label>
                                            <input value={ageeSpouse} disabled id="spouse_name" type="number" className="form-control font-semibold text-black" name="spouse.age" />
                                            <div className="error-msg">{errors.spoAge?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div  className={spouseData? "form-group spouse-toggle-visible active":"form-group spouse-toggle-visible"}>
                                            <label htmlFor="spouse_name">Qualification of Spouse:</label>
                                            <AutoCompleteAntd 
                                                control={control} 
                                                data={qualifyResult} 
                                                name={"spouse.qid"} 
                                                value={spoQualData}
                                                error={errors.spoQualification}
                                            />
                                            <div className="error-msg">{errors.spoQualification?.message}</div>

                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className={spouseData? errors.spoAadhno? "form-group error": "form-group spouse-toggle-visible active":"form-group spouse-toggle-visible"}>
                                            <label htmlFor="spouse_name">Spouse Aadhar No.:</label>
                                            <input disabled={spouseData?false:true} {...register("spouse.aadhar_no")} id="spouse_name" type="text" className="form-control" name='spouse.aadhar_no' />
                                            <div className="error-msg">{errors.spoAadhno?.message}</div>
                                        </div>	
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="spouse_name">Status (If not holding Passport):</label>
                                            <select {...register("passport_status")} id="enrolling-office" className="form-control" name='passport_status'>
                                                <option value="NOT">Not applicable</option>
                                                <option value="YES">Applied </option>
                                                <option value="NO">Not Applied</option>
                                            </select>
                                        </div>	
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="spouse_name">GeeBee Student ID:</label>
                                            <input style={{fontWeight:900, color:'red'}} {...register('geebee_student_id')} name="geebee_student_id" disabled id="spouse_name" type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                                
                                            <div className="col-md-3">
                                                <div className={spouseData?"form-group spouse-toggle-visible active":"form-group spouse-toggle-visible"}>
                                                    <label htmlFor="spouse_name" style={{whiteSpace:'nowrap'}}>No. of Children:</label>
                                                    <select {...register("no_of_children")} className="form-control numchildren" onChange={(e)=>{setChildAge(e.target.value)}} name='no_of_children'>
                                                        <option>0</option>
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className={childAge>="1" && spouseData?"form-group agegroup age1 showage":"form-group agegroup age1"}>
                                                    <label htmlFor="spouse_name">Age:</label>
                                                    <input {...register("childrens[0].age")} id="spouse_name" type="text" placeholder="Age" className="form-control" name='childrens[0].age'/>
                                                </div>	
                                            </div>
                                            <div className="col-md-3">
                                                <div className={childAge>="2" && spouseData?"form-group agegroup age2 showage":"form-group agegroup age1"}>
                                                    <label htmlFor="spouse_name">Age:</label>
                                                    <input {...register("childrens[1].age")} id="spouse_name" type="text" placeholder="Age" className="form-control" name='childrens[1].age'/>
                                                </div>	
                                            </div>
                                            <div className="col-md-3">
                                                <div className={childAge>="3" && spouseData?"form-group agegroup age3 showage":"form-group agegroup age1"}>
                                                    <label htmlFor="spouse_name">Age:</label>
                                                    <input {...register("childrens[2].age")} id="spouse_name" placeholder="Age" type="text" className="form-control" name='childrens[2].age'/>
                                                </div>	
                                            </div>  
                                        </div>
                                    </div>

                                    {
                                        appStore.appid === '' &&
                                        <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className={errors.pros_status?"form-group error":"form-group"}>
                                                        <label htmlFor="applicant_name">Prospect Status:<em className="mandatory">*</em></label>
                                                        <select  className="form-control" name='pros_status'  {...register("pros_status")}>
                                                            <option value='1'>Followup Required (Before registration)</option>
                                                        </select>
                                                        <div className="error-msg">{errors.pros_status?.message}</div> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className={errors.comment?"form-group error":"form-group"}>
                                                        <label htmlFor="applicant_name">Comment:<em className="mandatory">*</em></label>
                                                        <textarea  className="form-control" name='comment'  {...register("comment")}>
                                                        </textarea>
                                                        <div className="error-msg">{errors.comment?.message}</div> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }


                                    {
                                        appStore.appid === '' &&
                                        <div className="col-md-12 mt-2">
                                            <Checkbox 
                                                onChange={(e) => {
                                                    setFollow(e)
                                                    setValue('followup', e)
                                                }}
                                            >
                                                <span style={{fontSize:"12px", fontFamily:400, paddingRight: "12px"}}>Do you want add follow up</span>
                                            </Checkbox>
                                            {
                                                follow &&
                                                <div className="row mt-2">
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label htmlFor="applicant_name">Followup Date:<em className="mandatory">*</em></label>
                                                            <input type="date"  className="form-control" name='foldate'  {...register("foldate")} />
                                                            <div className="error-msg">{errors.foldate?.message}</div> 
                                                        </div>
                                                    </div>
                                                    {
                                                        appStore?.user?.role !== "COUNSELOR" &&
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="applicant_name">Followup Assigned to:<em className="mandatory">*</em></label>
                                                                <select className="form-control" name='followassign'  {...register("followassign")}>
                                                                    <option value="">Select User</option>
                                                                    {
                                                                        userResult?.map((item, index)=> (
                                                                            <option key={index} value={item?.id}>
                                                                                {item?.userdetail?.firstname} {item?.userdetail?.lastname}
                                                                            </option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                <div className="error-msg">{errors?.followassign?.message}</div> 
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                    
                                    <div className="col-md-12 mt-2">
                                        <Checkbox isSelected={users?.loan === 'YES' ?true: false} onChange={(e) => setUsers({...users, loan: e ? 'YES' : 'NO'}) }>
                                            <span style={{fontSize:"12px", fontFamily:400, paddingRight: "12px"}}>Student Requires Loan Assistance</span>
                                        </Checkbox>     
                                        <Checkbox isSelected={users?.travel === 'YES' ?true: false} onChange={(e) => setUsers({...users, travel: e ? 'YES' : 'NO'}) }>
                                            <span style={{fontSize:"12px", fontFamily:400, paddingRight: "12px"}}>Student Requires Travel Assistance</span>
                                        </Checkbox>     
                                        {/* <Checkbox checked={users?.gic === 'YES' ?true: false} onChange={(e) => setUsers({...users, gic: e.target.checked ? 'YES' : 'NO'}) }>
                                            <span style={{fontSize:"12px", fontFamily:400, paddingRight: "12px"}}>GIC Executive</span>
                                        </Checkbox> */}
                                        {/* <Checkbox checked={users?.interview === 'YES' ?true: false} onChange={(e) => setUsers({...users, interview: e.target.checked ? 'YES' : 'NO'}) }>
                                            <span style={{fontSize:"12px", fontFamily:400, paddingRight: "12px"}}>Interview Trainer</span>
                                        </Checkbox> */}
                                        <Checkbox isSelected={users?.fundready === 'YES' ?true: false} onChange={(e) => setUsers({...users, fundready: e ? 'YES' : 'NO'}) }>
                                            <span style={{fontSize:"12px", fontFamily:400, paddingRight: "12px"}}>Fund ready Student</span>
                                        </Checkbox>  
                                    </div>  

                                    <div className="col-md-12 text-center my-4" style={{display:'flex', gap: 12, justifyContent:'center'}}>
                                        <button 
                                        type='button' 
                                        onClick={()=>{navigate(-1)}} 
                                        className="btn-customized-colorless bg-purple-700 w-[120px] h-[40px] gap-2"
                                        ><IconBackspace size="18" />Go Back</button>
                                        <button
                                        disabled={(addApplication.isLoading || editApplication.isLoading)}
                                        type='submit' 
                                        className="btn-customized-large w-[120px] h-[40px] gap-2"
                                        >
                                            {
                                                (addApplication.isLoading || editApplication.isLoading) ?
                                                <Loading size='xs' color="white" />:
                                                <IconDeviceFloppy size="18" />
                                            }
                                        Save</button>
                                    </div>                
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {((appStore?.user?.role === "COUNSELOR" || appStore?.user?.role === "BMANAGER" || appStore?.user?.role === "MANAGER") && appStore.appid) &&
                    <Fragment>
                        <EducationalDetails spouseData={appStore?.appAllData?.applicant?.spouse_status === "TRUE" ? true : false} qualification={qualifyResult} />
                        <WorkExp spouseData={appStore?.appAllData?.applicant?.spouse_status === "TRUE" ? true : false} />
                        <IntendedCollege />
                        <DocumentDetails />
                        <EnglishProficiency spouseData={appStore?.appAllData?.applicant?.spouse_status === "TRUE" ? true : false} />
                        <OverseasEducation />
                        <Academic />
                        <TravelHistory />
                        <OtherDetails />
                    </Fragment>
                }
                {(appStore?.user?.role === "ADMISSIONSTAFF" || appStore?.user?.role === "REGISTRATION") &&
                    <Fragment>
                        <EducationalDetails spouseData={appStore?.appAllData?.applicant?.spouse_status === "TRUE" ? true : false}/>
                        <DocumentDetails />
                        <IntendedCollege />
                    </Fragment>
                }
        </Fragment>
    }
    
    </div>

  )
}

export default ApplicantDetails

const ageCalculate =(dob)=> {
    const birthDate = moment(dob)
    const today = moment()
    const ageInYears = today.diff(birthDate, 'years');
    return ageInYears
}