import React, { useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { Modal, useModal, Button, Text } from "@nextui-org/react";
import CountryWise from "./CountryWise";


const Uniquecountrywise =({fromDate,toDate})=>{

    const [result, setResult] = useState([]);
    const [status, setStatus] = useState('');
    const [applicantresult, setApplicantresult] = useState([]);
    const [visaDesc, setvisaDesc] = useState('');
    const [dataType, setDataType] = useState('');

    let office='';
    let officeCount ='';
    let applicantName='';
    let PhoneNum='';
    useEffect(()=>{
        uniquecountrywiseReportApi.mutateAsync({from: fromDate, to:toDate});
    },[]);


    useEffect(()=>{
        uniquecountrywiseReportApi.mutateAsync({from: fromDate, to:toDate});
    },[fromDate,toDate]);
    
    const uniquecountrywiseReportApi = authQueries.useUniqueCountrywiseReport(

        (response) => {
            console.log("response",response.data[0])
            setResult(response.data[0]);        
        }   
    
    );

    let DocpendingTotal=0;
    let docpendingIelts=0;
    let fullDocs=0;
    let awaitingOfr=0;
    let ConditionalOffer=0;
    let UnConditionalOffer=0;
    let OfferAccepted=0;
    let NotProceeding=0;
    let TotalProcessingVisa=0;
    let TotalVisaApplied=0;
    let TotalVisaApproved=0;
    let RefundRequest=0;
    let VisaRefused=0;
    let ExpectedVisa=0;
    let total_expected_visa=0;
    let totaldefereRequested=0;
    const [visibleStatus, setVisibleStatus] = useState(false);
    
    const handleCloseStatus = () => {
        setVisibleStatus(false)
    };

    // const handlefromDate = (event) => {
    //     const value = event.target.value;
    //     console.log("fromdate",value)
    //     setfromDate(value);
    // };

    // const handletoDate = (event) => {
    //     const value = event.target.value;
    //     console.log("todate",value)
    //     settoDate(value);
    // };

    const filterData = (e) => {
        uniquecountrywiseReportApi.mutateAsync({ from: fromDate, to:toDate });
        setStatus(e.target.value)
      }

    const statusModalOpen =(type, country, desc,visa )=>{
        setVisibleStatus(true)
        setvisaDesc(desc)
        setDataType(type);
        if(type==1)
        {
            setStatus("Document Pending Details");
        }else if(type==2)
        {
            setStatus("Document Pending with ielts Details");
        }else if(type==3)
        {
            setStatus("Full Docs");
        }else if(type==4)
        {
            setStatus("Awaiting Offer");
        }else if(type==5)
        {
            setStatus("Conditional Offer");
        }else if(type==6)
        {
            setStatus("Unconditional Offer");
        }else if(type==7)
        {
            setStatus("Offer Accepted");
        }
        listApplicant.mutateAsync({type:type, visa: visa,country:country, from: fromDate, to:toDate })
    }

    const listApplicant = authQueries.useApplicantList(
        (response) => {
            setApplicantresult(response.data[0]);
             
            console.log("response data==>",)
        }
    );
    
    function formatDate(inputDate) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const dateObj = new Date(inputDate);
        const day = dateObj.getDate();
        const month = months[dateObj.getMonth()];
        const year = dateObj.getFullYear();
        return `${month.toUpperCase()} ${day} ${year}`;
    }

    return(
        <div>
            <div className="add_clients">			
                    <div className="row">
                        <div className="col-md-6 appointments-hing-wrpr">
                            <i className="appointments-icon">
                                <img src={require("../../../assets/images/appointments-today-icon.png")} width="" height="" alt="" />
                            </i>
                            <h2><span>&nbsp;</span>Unique Country Wise - Visa Status Report</h2>	
                        </div>
                            
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
                    </div>
                    <div id="accordion" className="add_clients_accordion_form" style={{borderBottom:'none'}}>
                        <div  className="card" id="appointments_wrpr_employee">
                        <div className="card-header">
                            Unique Country Wise - Visa Status Report
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className="row ">
                                    <div className="col-md-12">
                                        <div className="table-list-search-wrpr">                         
                                            <table border={1} className="table report collageprogram countrywise">
                                                <thead>
                                                    <tr>
                                                    <th >Country</th>
                                                    <th style={{ width: '100px' }}>Visa Type</th>
                                                    <th>Docs Pending</th>
                                                    <th >Docs Pending with IELTS</th>
                                                    <th >Full Docs (No Active Application)</th>
                                                    <th >Awaiting Offer</th>
                                                    <th >Conditional Offer</th>
                                                    <th >UnConditional Offer</th>
                                                    <th >Not Proceeding with offer</th>
                                                    <th >Offer Accepted</th>
                                                    <th >Preparing Visa/Preparing Visa After refusal</th>
                                                    <th >Visa Applied</th>
                                                    <th >Visa Approved</th>
                                                    <th >Visa Refused</th>
                                                    <th >Defer requested</th>
                                                    <th >Refund Requested</th>
                                                   
                                                    <th >Expected Visa</th>
                                                    <th >Total Expected Visa</th>
                                                    </tr>
                                                </thead>
                                                <tbody  className="ui-sortable">
                                                { uniquecountrywiseReportApi.isLoading && "Loading" }
                                                {   
                                                    result?.map((item, index) =>
                                                    {  
                                                        
                                                        DocpendingTotal=DocpendingTotal+item.DocPending;
                                                        docpendingIelts=docpendingIelts+item.Docs_Pending_IELTS;
                                                        fullDocs=fullDocs+item.full_docs;
                                                        awaitingOfr=awaitingOfr+item.awaiting_offer;
                                                        ConditionalOffer=ConditionalOffer+item.ConditionalOffer;
                                                        UnConditionalOffer=UnConditionalOffer+item.ConditionalOffer;
                                                        OfferAccepted=OfferAccepted+item.OfferAccepted;
                                                        NotProceeding=NotProceeding+item.NotProceeding;
                                                        TotalProcessingVisa=TotalProcessingVisa+item.TotalProcessingVisa;
                                                        TotalVisaApplied=TotalVisaApplied+item.TotalVisaApplied;
                                                        TotalVisaApproved=TotalVisaApproved+item.TotalVisaApproved;
                                                        RefundRequest=RefundRequest+item.RefundRequest;
                                                        VisaRefused=VisaRefused+item.VisaRefused;
                                                        ExpectedVisa=ExpectedVisa+item.ExpectedVisa;
                                                        total_expected_visa=total_expected_visa+item.total_expected_visa;
                                                        totaldefereRequested=totaldefereRequested+item.defereRequested;

                                                    return(
                                                        <tr className="ui-sortable-handle">
                                                        <td className="zui-sticky-col">{item.country }</td>
                                                        <td className="zui-sticky-col2">{item.description}</td>
                                                        <td><a  onClick={()=>{statusModalOpen(1, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.DocPending?item.DocPending:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(2, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.Docs_Pending_IELTS?item.Docs_Pending_IELTS:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(3, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.full_docs?item.full_docs:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(4, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.awaiting_offer?item.awaiting_offer:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(5, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.ConditionalOffer?item.ConditionalOffer:0}</a></td>
                                                        <td><a  onClick={()=>{statusModalOpen(6, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.UnConditionalOffer?item.UnConditionalOffer:0}</a></td>
                                                        <td>{item.NotProceeding?item.NotProceeding:0}</td>
                                                        <td><a  onClick={()=>{statusModalOpen(7, item.id, item.description, item?.visaTypeID)}}  href="javascript:void(0)">{item.OfferAccepted?item.OfferAccepted:0}</a></td>
                                                        <td>{item.TotalProcessingVisa?item.TotalProcessingVisa:0}</td>
                                                        <td>{item.TotalVisaApplied?item.TotalVisaApplied:0}</td>
                                                        <td>{item.TotalVisaApproved?item.TotalVisaApproved:0}</td>
                                                        <td>{item.VisaRefused?item.VisaRefused:0}</td>
                                                        <td>{item.defereRequested?item.defereRequested:0}</td>
                                                        <td>{item.RefundRequest?item.RefundRequest:0}</td>
                                                        
                                                        <td>{item.ExpectedVisa?item.ExpectedVisa:0}</td>
                                                        <td>{item.total_expected_visa?item.total_expected_visa:0}</td>
                                                        </tr>
                                                    )})
                                                }
                                                <tr>
                                                    <td className="zui-sticky-col" colSpan={2}><b>Total</b></td>
                                                    <td ><b>{DocpendingTotal}</b></td>
                                                    <td><b>{docpendingIelts}</b></td>
                                                    <td><b>{fullDocs}</b></td>
                                                    <td><b>{awaitingOfr}</b></td>
                                                    <td><b>{ConditionalOffer}</b></td>
                                                    <td><b>{UnConditionalOffer}</b></td>
                                                    <td><b>{OfferAccepted}</b></td>
                                                    <td><b>{NotProceeding}</b></td>
                                                    <td><b>{TotalProcessingVisa}</b></td>
                                                    <td><b>{TotalVisaApplied}</b></td>
                                                    <td><b>{TotalVisaApproved}</b></td>
                                                    <td><b>{totaldefereRequested}</b></td>
                                                    <td><b>{RefundRequest}</b></td>
                                                    <td><b>{VisaRefused}</b></td>
                                                    <td><b>{ExpectedVisa}</b></td>
                                                    <td><b>{total_expected_visa}</b></td>
                                                </tr>
                                            </tbody>

                                            </table>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    
                </div>
                
            </div>


        <Modal
            scroll
            blur
            closeButton
            width="70%"
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={visibleStatus}
            onClose={handleCloseStatus}
        >
            <Modal.Header>
                <Text b size="16px" className="text-center">Unique {status} - {visaDesc}</Text>
            </Modal.Header>
            <Modal.Body>
                <table className="table report collageprogram alt-colors">
                    <thead>
            
                        <tr style={{background:'rgb(50 58 81 )'}}>
                        {dataType!=7?<th width="10%">Branch</th>:null}
                        <th width="20%">Name</th>
                        <th width="20%">Phone</th>
                        {dataType==7?<th width="10%">Email</th>:null}
                        {dataType==7?<th width="10%">Branch</th>:null}
                        <th width="20%">Active Intake</th>
                        {dataType==7?<th width="10%">Counselor</th>:null}
                        <th width="20%">College</th>
                        <th width="20%">Program</th>
                        {dataType==7?<th width="10%">Status</th>:null}
                        {dataType!=7?<th width="20%">Program Code</th>:null}
                        {dataType!=7?<th width="20%">Program Intake</th>:null}
                        {dataType!=7?<th width="20%">Counselor</th>:null}
                        {dataType!=7?<th width="20%">Doc Staff</th>:null}
                        {dataType!=7?<th width="20%">Visa Staff</th>:null}
                        {dataType==3?<th width="20%">Application status</th>:null}
                        {dataType==4 || dataType==5 || dataType==6 ?<th width="20%">Offer Type</th>:null}
                        {dataType==4 || dataType==5 || dataType==6 ?<th width="20%">Application Sub Date</th>:null}
                        {dataType==4 || dataType==5 || dataType==6 ? <th width="20%">No of days after submitting the application</th>:null}
                        {dataType==1?<th width="30%">No Of days Followup Pending</th>:null}
                        {dataType==3?<th width="30%">No Of days after moved to full docs</th>:null}
                        </tr>

                    </thead>
                    <tbody id="sortable6" className="ui-sortable">
                    {
                            applicantresult?.map((item, index) => 
                            {

                                let offices = applicantresult?.filter(function(office){
                                    return  office.office == item.office;
                                });

                                console.log("offices",offices)

                                let applicants = applicantresult?.filter(function(applicant){
                                    return  applicant.applicant_name == item.applicant_name;
                                });

                                let phone = applicantresult?.filter(function(phon){
                                   // return  phon.mobile_no == item.mobile_no;
                                    return  phon.mobile_no == item.mobile_no && item.mobile_no!='' && item.mobile_no!=null;
                                });

                                return(
                               
                                    <tr className="ui-sortable-handle">
                                       
                                        {office!=item.office && dataType!=7?<td rowSpan={offices.length}>{office=item.office }</td>: null}
                                        {applicantName!=item.applicant_name?<td rowSpan={applicants.length}>{applicantName=item.applicant_name }</td>: null}
                                        {PhoneNum!=item.mobile_no && item.mobile_no!=null ?<td rowSpan={phone.length==0?1:phone.length}>{ PhoneNum=item.mobile_no }</td>: null} {item.mobile_no==null?<td></td>:null}
                                        {dataType==7?<td>{item.email}</td>:null}
                                        {dataType==7?<td>{item.office}</td>:null}
                                        <td>{item.intake}</td> 
                                        {dataType==7?<td>{item.counselor}</td> :null}
                                        <td>{item.intended_college_or_university}</td> 
                                        <td>{item.pname}</td> 
                                        {dataType!=7? <td>{item.intended_program}</td>  :null}
                                        {dataType!=7? <td>{item.intake}</td> :null}
                                        {dataType!=7?<td>{item.counselor}</td> :null}
                                        {dataType!=7?<td>{item.admission}</td> :null}
                                        {dataType!=7?<td>{item.visastaff}</td> :null}
                                        {dataType==7?<td>{item.status}</td> :null}
                                        {dataType==4 || dataType==5 || dataType==6 ?<td>{item.offertype}</td>:null}
                                        {dataType==4 || dataType==5 || dataType==6 ?<td>{formatDate(item.application_submision_date)}</td>:null}
                                        {dataType==3?<td>{item.application_status}</td>:null}
                                        {dataType!=7?<td>0</td>:null}
                                    </tr>
                            )})
                            
                    }
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>

    </div>
    )

    


    
}
export default Uniquecountrywise;