/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import authQueries from '../../queries/authQueries';
import LoaderCommon from '../Common/LoaderCommon';
import Swiper from 'swiper';
import $ from 'jquery'
import CountUp from 'react-countup';
import FollowupToday from '../Common/FollowupToday';
import FollowupPending from '../Common/FollowupPending';


const FeeCoordinatorDashboard = () => {

    const [count, setCount] = useState({})
	const [loader, setLoader] = useState(true);

    const countDashboard = authQueries.useFeeDashboardCountMutation(
		async (response) => {
      	    setCount(response)
            setLoader(false)
        }
    )

    useEffect(() => {
        countDashboard.mutateAsync()
    }, []);

    const slideArr = [
		{
			color: 'blue',
			title: 'Total No of Students Assigned',
			name: 'Total Active Files',
			image: "icon-expand1",
			value: "ACTIVE",
			count: count?.all,
			id: null
		},
		{
			color: 'green',
			title: 'Full Fee paid Students',
			name: 'Pending Files',
			image: "icon-hour-glass",
			value: "PENDING",
			count: count?.all - count?.paid,
			id: null
		},
		{
			color: 'orange',
			title: 'Partial fee paid students',
			name: 'Completed Files',
			image: "icon-input-checked",
			value: "COMPLETE",
			count: count?.paid,
			id: null
		},
	];

	const slider = () => {
 
		var swiper = new Swiper(".geebee-carousel.followup-pending", {
			slidesPerView: 5,
			spaceBetween: 10,
			speed: 700,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
                "1": {
					slidesPerView: 1,
					spaceBetween: 10,
                },
				'480': {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				'640': {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				'920': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
				'1200': {
					slidesPerView: 5,
					spaceBetween: 10,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next.carousel-next",
				prevEl: ".swiper-button-prev.carousel-prev",
			},
		});

	}

	useEffect(() => {
		slider();
		$('html,body').animate({ scrollTop: 0 }, 'fast');
	}, [loader]);
    
  return (
    <div>

        {
            loader ?
            <div style={{display:'flex', justifyContent:'center', flexDirection:'column', marginTop:'10%'}}>
                <LoaderCommon  />
            </div>:
            <div>
                <div className="carousel-section mb-5">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="swiper-container followup-pending geebee-carousel">
                                <div className="swiper-wrapper">
                                    {slideArr.map((item, index) => (
                                        <div style={{cursor:'pointer'}} className="swiper-slide" key={index}>
                                            <div className={`icon-and-title-count ${item.color}`}>
                                                <div className="icon-and-title">
                                                    <i className={`${item?.image} icon-customimage`} style={{fontSize: item?.value !== "COMPLETE" && "14px"}}></i>
                                                    <div className="count">
                                                        {item?.count === 0 ? 0 : <CountUp end={item.count} duration={1} />}
                                                    </div>
                                                </div>
                                                <h2>{item.title} Clients</h2>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FollowupToday />
                <FollowupPending />


            </div>
        }

    </div>
  )
}

export default FeeCoordinatorDashboard