import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import DropDown from "../../Common/DropDown";
import moment from "moment";
import { Checkbox, Loading } from "@nextui-org/react";
import { IconPlaneDeparture } from "@tabler/icons-react";
import SelectSearch from "../../Common/SelectSearch";
import InputSearch from "../../Common/InputSearch";
import LoaderCommon from "../../Common/LoaderCommon";


const VisaApprovedReport =()=>{

    const [result, setResult] = useState([]);
    const [dateLimit, setDateLimit] = useState('MONTH');
    const [to, setTo] = useState(new Date())
    const [from, setFrom] = useState(new Date())

    const visaReport = authQueries.useCEOVisaApprovedMutation(
        (response) => {
            setResult(response);   
        }
    );

    useEffect(()=>{
        visaReport.mutateAsync({
            datelimit: dateLimit,
            to: to,
            from: from
        });
    },[dateLimit, to, from]);

    

    const inputSearchArry = [
        {
            label: "Date Range",
            size: 2,            
            state: dateLimit,
            setState: setDateLimit,
            input: SelectSearch,
            hideBlankValue: true,
            value: [
                {
                    id: "MONTH",
                    name: "Month"
                },
                {
                    id: "WEEK",
                    name: "Week"
                },
                {
                    id: "CUSTOM",
                    name: "Custom Date"
                }
            ],
            name: 'name',
        },
        {
            label: "From Date",
            size: 2,            
            state: moment(from).format('YYYY-MM-DD'),
            setState: setFrom,
            type: "date",
            input: InputSearch,
            hidden: dateLimit === "CUSTOM" ? false:true
        },
        {
            label: "To Date",
            size: 2,            
            state: moment(to).format('YYYY-MM-DD'),
            setState: setTo,
            type: "date",
            input: InputSearch,
            hidden: dateLimit === "CUSTOM" ? false:true
        },
    ]

    const counselorName =(counselor)=>{
        return `${counselor?.firstname} ${counselor?.lastname}`
    }



    return(
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Visa Approved clients</li>
                </ol>
                </nav>
            </div>
            
            <div className="add_clients">
                <div className="flex items-center gap-[4px] mb-4">
                    <i className="followup-icon">
                        <IconPlaneDeparture size="25" className='text-[#7D6FAB]' />
                    </i>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                            <span className="text-[16px] font-medium">Visa Approved Report</span>	
                        </div>
                    </div>
                </div>
                <div id="accordion" className="add_clients_accordion_form mt-[10px]">
                    <div  className="card" id="appointments_wrpr_employee">
                        <div className="card-header">
                            Visa Approved Report
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">

                            <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5">
                                {
                                    inputSearchArry?.map((item, index)=> (
                                        <Fragment>
                                            {
                                                !item?.hidden &&
                                                <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                                    <item.input item={item} />
                                                </div>
                                            }
                                        </Fragment>
                                    ))
                                }
                            </div>

                            {visaReport.isLoading ?
                                <LoaderCommon />:
                                <div className="table-wrpr">
                                    <div className="table-section">
                                        <div className="row">
                                            <div className="col-md-12">                                   
                                                <table border={1} className="table prospect-table">
                                                    <thead>
                                                        <tr className="!font-bold">
                                                            <th>Name</th>
                                                            <th>Visa Type</th>
                                                            <th>Mobile</th>
                                                            <th>Email</th>
                                                            <th>Reffered By</th>
                                                            <th>Office</th>
                                                            <th>Counselor</th>
                                                            <th>Status</th>
                                                            <th>Intake</th>
                                                            <th>Registered Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody  className="ui-sortable">

                                                    {   
                                                        result?.data?.map((item, index) =>(
                                                            <tr className="ui-sortable-handle">
                                                                <td>{item?.applicant_name} {item?.middlename} {item?.lastname}</td>
                                                                <td>{item?.visatype?.country?.icon} {item?.visatype?.description} </td>
                                                                <td>{item?.mobile_no}</td>
                                                                <td>{item.email}</td>
                                                                <td>{item?.refferal?.refferal}</td>
                                                                <td>{item?.office?.name}</td>
                                                                <td>{counselorName(item?.assignUser?.CounselorAssign?.userdetail)}</td>
                                                                <td>{item?.status_of_prospect?.name}</td>
                                                                <td>{item?.intake?.intake}</td>
                                                                <td>{item?.registered_date && moment(item?.registered_date).format('MMM Do, YYYY')}</td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}
export default VisaApprovedReport;