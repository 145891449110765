import React, { Fragment, useState } from 'react'
import { Controller } from 'react-hook-form'

const InputTextArea = ({item, errors, control}) => { 
  return (
    <Fragment>
        <div className="text-[12px] h-4 font-[500]">{item?.label} {item?.required &&<span className="text-[red]">*</span>}</div>
        <Controller
          name={item.name}
          control={control}
          render={({ field }) => (
            <textarea
            className="bg-white border border-solid border-gray-300 rounded-md w-full h-10 p-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 font-poppins min-h-[100px]" 
            type={item?.type}
            placeholder={item.placeholder ? item?.placeholder : item?.label}
            name={item?.name}
            {...field}
            />
          )}
      />
      <div className='text-[12px] text-red-600 font-poppins'>{errors?.[item?.name]?.message}</div>
    </Fragment>
  )
}

export default InputTextArea