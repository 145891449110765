import React, { useEffect, useState } from 'react'
import authQueries from '../../../queries/authQueries'
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import { userStore } from '../../../store/userStore';
import { DatePicker, message as messages, Popconfirm } from 'antd';
import moment from 'moment';
import InputUseForm from '../../Common/InputUseForm';
import { generateValidationSchema } from '../../Common/validationSchema';
import InputSelectForm from '../../Common/InputSelectForm';
import AutoCompleteInput from '../../Common/AutoCompleteInput';
import toast from 'react-hot-toast';

const EduFormUpdate =({item, qualification})=> {

    useEffect(()=> {
        reset(item)
    }, [])

    const [edit, setEdit] = useState(0)

    const appStore = userStore();

    const inputFields = [
        {
            label: "Qualification",
            size: 2,
            iptype: "select",
            required: true,
            options: qualification,
            placeholder: "Search or Select Qualification",
            name: 'degree',
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            label: "University / Board",
            size: 2,
            name: 'university',
            required: true,
            iptype: "text"
        },
        {
            label: "Year of Passing",
            size: 2,
            name: 'year_and_month_ofpass',
            required: true,
            iptype: "number"
        },
        {
            label: "Duration Years",
            size: 2,
            name: 'duration_years',
            required: true,
            max: 2,
            iptype: "number"
        },
        {
            label: "% of Marks",
            size: 2,
            name: 'mark_percentage',
            required: true,
            max: 3,
            iptype: "number"
        },
    ]

    const viewAllApplicationEditData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
            }
        }
    )

    const deleteEducational = authQueries.useEducationMutationDelete(
        (response) => {
            viewAllApplicationEditData.mutateAsync(appStore?.appid)
        }   
    )

    const editEducational = authQueries.useEducationMutationEdit(
        (response) => {
            setEdit(0)
            toast.success("Successfully updated")
            viewAllApplicationEditData.mutateAsync(appStore?.appid)
        }   
    );

    const { register ,reset, control, getValues ,handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    });

    const editButton =(e)=>{
        e.preventDefault();
        setEdit(item?.id)
    }

    const onDelete =()=> {
        deleteEducational.mutateAsync(item?.id);
        messages.success("successfully deleted")
    }

    const onSubmit = (data) => {

        const datavalues = {
            id:item?.id,
            applicant_id : appStore.appid,
            certificate_check: "YES",
            degree: data.degree,
            university: data.university,
            year_and_month_ofpass: data.year_and_month_ofpass,
            duration_years: data.duration_years,
            mark_percentage: data.mark_percentage,
            status:"ACTIVE"
        };
        
        editEducational.mutateAsync(datavalues);
    }
    
    const deleteButton =(e)=>{
        e.preventDefault();
        onDelete();
    }

    return(
        <form>
            <div className='grid grid-cols-12 max-[1347px]:grid-cols-10 max-[1053px]:grid-cols-8 max-[910px]:grid-cols-6 max-[661px]:grid-cols-4 max-[481px]:grid-cols-2 gap-x-4 mb-3'>
                {
                    inputFields?.map((input, index)=>(
                        <div className={`col-span-${input?.size} flex flex-col gap-2`} key={index}>
                            {
                                input?.iptype === "select" ?
                                <AutoCompleteInput
                                item={input}
                                disabled={edit === item?.id? false:true}
                                register={register}
                                control={control}
                                errors={errors}
                                options={input?.options}
                                optionProps={input?.optionProps}
                                />:
                                (input?.iptype === "text" || input?.iptype === "number") &&
                                <InputUseForm
                                disabled={edit === item?.id? false:true}
                                item={input}
                                control={control}
                                register={register}
                                errors={errors}
                                />
                            }
                        </div>
                    ))
                }

                <div className="col-span-1">
                    <div className='flex flex-col gap-2'>
                        <div className="text-[12px] h-4 font-[500]" />
                        {edit !== item?.id?
                            <button
                            type='button' 
                            onClick={(e)=>editButton(e)}
                            className="btn-customized-large h-[44px]"
                            >Edit</button>:
                            <button
                            onClick={handleSubmit(onSubmit)}
                            type='submit' 
                            className="btn-customized-large h-[44px]"
                            >Update</button>
                        }
                    </div>
                </div>

                <div className="col-span-1">
                    <div className='flex flex-col gap-2'>
                        <div className="text-[12px] h-4 font-[500]" />
                        <Popconfirm
                        placement="left"
                        title="Are you sure to delete this Details ?"
                        onConfirm={(e) => {deleteButton(e)}}
                        okText="Yes"
                        cancelText="No"
                        >
                            <button type='button' className="btn-customized-large h-[44px] !bg-[#dc3545] text-white">Remove</button>
                        </Popconfirm>
                    </div>
                </div>

            </div>
        </form> 
    )
}



const EduFormCreate =({qualification , spouseEnabled}) => {

    const inputFields = [
        {
            label: "Qualification",
            size: 2,
            iptype: "select",
            required: true,
            options: qualification,
            placeholder: "Search or Select Qualification",
            name: 'degree',
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            label: "University / Board",
            size: 2,
            name: 'university',
            required: true,
            iptype: "text"
        },
        {
            label: "Year of Passing",
            size: 2,
            name: 'ympass',
            required: true,
            iptype: "number"
        },
        {
            label: "Duration Years",
            size: 2,
            name: 'duration',
            required: true,
            max: 2,
            iptype: "number"
        },
        {
            label: "% of Marks",
            size: 2,
            name: 'mark',
            required: true,
            max: 3,
            iptype: "number"
        },
    ]
    

    const appStore = userStore();

    const viewAllApplicationEditData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
            }
        }
    )

    const { register ,reset, control, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    });

    const addEducational = authQueries.useEducationMutationAdd(
        (response) => {
            let datas = ({
                degree:"",
                university:"",
                ympass:"",
                duration:"",
                mark:""
            })
            reset({ ...datas });
            viewAllApplicationEditData.mutateAsync(appStore?.appid)
        }   
    );
      
    
    const onSubmit = (data) => {
        const datavalues = {
            applicant_id : appStore.appid,
            spouse_enabled : spouseEnabled,
            certificate_check: "YES",
            degree: data.degree,
            university: data.university,
            year_and_month_ofpass: data.ympass,
            duration_years: data.duration,
            mark_percentage: data.mark,
            status:"ACTIVE"
        };     
        addEducational.mutateAsync(datavalues);
    }

   
  return (
    <div>
        <form onSubmit={handleSubmit(onSubmit)}>   
            <div className='grid grid-cols-12 gap-4'>
                {
                    inputFields?.map((item, index)=>(
                        <div className={`col-span-${item?.size} flex flex-col gap-2`} key={index}>
                            {
                                item?.iptype === "select" ?
                                <AutoCompleteInput
                                    item={item}
                                    register={register}
                                    control={control}
                                    errors={errors}
                                    options={item?.options}
                                    optionProps={item?.optionProps}
                                />:
                                (item?.iptype === "text" || item?.iptype === "number") &&
                                <InputUseForm
                                    item={item}
                                    control={control}
                                    register={register}
                                    errors={errors}
                                />
                            }
                        </div>
                    ))
                }

                <div className="col-span-1">
                    <div className='flex flex-col gap-2'>
                        <div className="text-[12px] h-4 font-[500]" />
                        <button 
                            type='submit' 
                            className="btn-customized-large"
                        >Create</button>
                    </div>
                </div>

            </div>
        </form>
    </div>
  )
}


const EducationalDetails =({spouseData, qualification}) => {

    const [quaResult, setQuaResult] = useState([])
    const [spouseEnabled, setSpouseEnabled] = useState("NO")

    useEffect(() => {
        const quaArr = [];
        qualification?.map(item => {
            const newData = {
                id: item?.value,
                name: item?.label
            }
            quaArr.push(newData)
        })
        setQuaResult(quaArr)
    }, []);

    const appStore = userStore();

    const qualificationData = authQueries.useQualificationMutationList(
        (response) => {
            // setQuaResult(response) 
        }   
    );
   
  return (

    <div>

        <div className="card">
            <div className="card-header">
            <a className="collapsed card-link" data-toggle="collapse" href="#educational_details">
                Educational Details
            </a>
            <div className={appStore?.appAllData?.educationals?.length > 0 ? "tab-info fields-completed" :  "tab-info fields-notcompleted" }>
                <span className={appStore?.appAllData?.educationals?.length > 0 ? 'icon-checkmark1' : 'icon-cancel1'}></span>
            </div>
            </div>
            <div id="educational_details" className="collapse" data-parent="#accordion">
            <div className="card-body">
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a style={{cursor:'pointer'}} onClick={()=>{setSpouseEnabled("NO")}} className={spouseEnabled === "NO" ? "nav-link active" : "nav-link"}>Primary Applicant</a>
                    </li>
                    {spouseData &&
                    <li className="nav-item">
                        <a style={{cursor:'pointer'}} onClick={()=>{setSpouseEnabled("YES")}} className={spouseEnabled === "YES" ? "nav-link active" : "nav-link"}>Spouse</a>
                    </li>
                    }
                    
                </ul>
                    {
                        !qualificationData.isLoading &&
                        <div className="tab-content">
                            <div id="eduprimaryApplicant" className="tab-pane active"><br />
                                {appStore?.appAllData?.educationals?.map((item, index) => (
                                    <div>
                                        {
                                            spouseEnabled === item?.spouse_enabled && 
                                            <EduFormUpdate key={index} item={item} qualification={quaResult} />
                                        }
                                    </div>
                                    ))
                                }
                                <EduFormCreate qualification={quaResult} spouseEnabled={spouseEnabled} />
                            </div>
                        </div>
                    }
                    
                </div>

            </div>

        </div>

    </div>

  )
}

export default EducationalDetails