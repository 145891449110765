import { Spin,  Popconfirm } from 'antd'
import { decode } from 'html-entities'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import authQueries from '../../queries/authQueries';
import { usePaginationStore, userStore } from '../../store/userStore';
import { DeleteMessage } from '../Common/AlertMessage';
import { Avatar, Dropdown, Modal } from '@nextui-org/react';
import PaginationComponent from '../Common/PaginationComponent';
import MasterHeader from '../Common/MasterHeader';
import LoaderCommon from '../Common/LoaderCommon';

const UserList =()=> {

    const datas = useLocation().search;

    const roleid = new URLSearchParams(datas).get("role");

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const [result, setResult] = useState([]);
    const [userRoles, setUserRoles] = useState([])
    const [search, setSearch] = useState('');
    const [role, setRole] = useState(roleid);
    const [status, setStatus] = useState('ACTIVE');

    const navigate = useNavigate();

    const {rid} = useParams();

    const user = userStore();

    useEffect(()=>{
        userListing.mutateAsync({search:search, role:role, page:currentPage, size:25, status:status})
    },[search, role, status, currentPage])

    const appStore = userStore();

    useEffect(()=>{
        UserRoleListing.mutateAsync({page:0, size:100})
    },[])
    
    
    const UserRoleListing = authQueries.useRoleMutationList(
        (response) => {
            setUserRoles(response)
        }   
    ); 

    const userListing = authQueries.useUserRoleMutationList(
        (response) => {
            setResult(response)
        }   
    ); 

    const editUser =(id, roles, office, visa)=>{

        let roleArray = []
        roles.map((item) => { 
            roleArray.push(item.rid)
        });

        let officeArray = []
        office.map((item) => {
            officeArray.push(item.oid)
        });

        let visaArray = []
        visa.map((item) => {
            visaArray.push(item.vid)
        });

        user.setRoleId(roleArray)
        user.setUserVisaId(visaArray)
        user.setUserOfficeId(officeArray)

        navigate('/useredit/'+id)
    }

    const addUser =()=>{
    navigate('/useradd')
    }

    const deleteUser =(id)=>{
    deleteApplication.mutateAsync(id)
    }

    const deleteApplication = authQueries.useUserRoleMutationDelete(
        (response) => {
            userListing.mutateAsync({search:search, role:role, page:currentPage, size:25, status:status})
            DeleteMessage({type: 'success', content: 'Successfully Deleted'});
        }
    ) 

    const userList =(rid, id)=> {
        user.setPagination({page:1, size:50})
        user.setUserId({role: rid, uid:id})
        navigate('/leadlist')
    }


  return (

    <div>

        <div>
            <div className="breadcrumb-and-otherbuttons mb-2">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Users</li>
                    </ol>
                </nav>
                <div className="other-buttons">
                    <button className="bg-[#323a51] text-[12px] text-white px-4 py-[10px] rounded-lg" onClick={addUser}>Create User</button>
                </div>
            </div>
        </div>

        <div className="add_clients">
            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr">
                    <i className="appointments-icon">
                        <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <h2><span>My</span>Users</h2>	
                </div>
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>

            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header">
                        <a className="collapsed card-link"  href="" style={{pointerEvents:'none'}}>
                            Users
                        </a>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4 search-results-count-wrpr" style={{marginBottom:'17px', display:'flex', alignItems:'center', }}>
                                {
                                    result?.data?.totalItems > 0 ?
                                    `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                    `O results found`
                                }
                                </div>
                                <div className="col-md-8 search-results-count-wrpr" style={{marginBottom:'0px'}}>
                                    <div className="table-list-search-wrpr">
                                        <div className="row">
                                            <div className="col-md-6" />
                                            <div className="col-md-6 text-right">
                                                <div className="table-search-filter-wrpr">
                                                    <div className="table-search-wrpr">
                                                        <input onChange={(e)=>{setSearch(e.target.value)}} style={{minWidth: '300px'}} className="form-control form-control-sm" type="text" placeholder="Search Username" />
                                                    </div>
                                                    <div className="table-filter-wrpr">
                                                        <select onChange={(e)=>{setRole(e.target.value)}} className="form-control  form-control-sm" >
                                                            <option value="">Select Role</option>
                                                            {
                                                                userRoles?.data?.items?.map((item, index) =>(
                                                                    <option key={index} value={item.id}>{item.label}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="table-filter-wrpr" style={{marginLeft:10}}>
                                                        <select onChange={(e)=>{setStatus(e.target.value)}} className="form-control  form-control-sm" >
                                                            <option value="">Select Status</option>
                                                            <option value="ALL">All</option>
                                                            <option value="ACTIVE">Active</option>
                                                            <option value="INACTIVE">Inactive</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    {
                                        userListing.isLoading ?
                                        <LoaderCommon />:
                                        <div className="table-wrpr">
                                            <div className="table-section">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <table style={{fontSize:"12px"}} className="table prospect-table">
                                                            <thead>
                                                                <tr>
                                                                    <th width="2%">#</th>
                                                                    <th width="2%"></th>
                                                                    <th width="18%">Name</th>
                                                                    <th width="18%">User Name</th>
                                                                    <th width="15%">Phone</th>
                                                                    <th width="15%">Email</th>
                                                                    <th width="10%">Primary Role</th>
                                                                    <th width="10%">&nbsp;</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="ui-sortable">
                                                                {result?.data?.items.map((item, index) => (
                                                                    <tr className="ui-sortable-handle" key={index}>
                                                                        <th className="">{(index+1) + (result?.data?.currentPage * 25)}</th>
                                                                        <td>
                                                                            <Avatar
                                                                            size="md"
                                                                            src={item?.userdetail?.image ? `${item?.userdetail?.image}` : require('../../assets/images/profile-avatar.jpg')}
                                                                            bordered
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            {decode(item?.userdetail?.firstname)} {decode(item?.userdetail?.lastname)}
                                                                        </td>
                                                                        <td>
                                                                            {item.username}
                                                                        </td>
                                                                        <td>{item?.userdetail?.phone}</td>
                                                                        <td>{item?.userdetail?.email}</td>
                                                                        <td>{item?.role?.label}</td>
                                                                        <td className="action-icons">
                                                                            <div className="items-end" style={{display:'flex', flexDirection:'column'}}>
                                                                                <div style={{display:'flex', flexDirection:'row', gap:"5px"}}>
                                                                                    <button 
                                                                                    style={{marginLeft:"0px", width:'50px'}} 
                                                                                    onClick={()=>{
                                                                                        editUser(
                                                                                            item.id, 
                                                                                            item?.rolemultiples,
                                                                                            item?.useroffices,
                                                                                            item?.uservisas,
                                                                                        )
                                                                                    }} 
                                                                                    className="btn-visadata"
                                                                                    >
                                                                                        Edit
                                                                                    </button> 

                                                                                    <Popconfirm
                                                                                        placement="left"
                                                                                        title="Are you sure to delete this User ?"
                                                                                        onConfirm={() => {deleteUser(item.id)}}
                                                                                        okText="Yes"
                                                                                        cancelText="No"
                                                                                    >
                                                                                        <button style={{background:"#d24b55", marginLeft:"0px", width:'50px'}}  className="btn-visadata delete">Delete</button>
                                                                                    </Popconfirm>
                                                                                </div>
                                                                                <div>
                                                                                    <button 
                                                                                    onClick={()=>userList(item?.role_id,item?.id)} 
                                                                                    style={{marginLeft:"0px", width:'105px'}} 
                                                                                    className="btn-visadata"
                                                                                    >
                                                                                        Bulk Assign
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


  )
}

export default UserList