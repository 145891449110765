import React, { Fragment, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import authQueries from '../../queries/authQueries';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";
import moment from 'moment';
import toast from 'react-hot-toast';
import { generateValidationSchema } from '../Common/validationSchema';
import { useApplicationFee } from '../../store/userStore';


const FeeTypeCreate =({intendedView, option})=> {

    const [type, setType] = useState(null)
    const program = useApplicationFee((state) => state.program);

    const inputFields = [
        {
            name: 'feetype',
            required: true,
            label: 'Fee Type'
        },
        {
            name: 'due',
            required: type === 1 ? false:true,
            label: 'Due Date'
        },
        {
            name: 'amount',
            required: true,
            label: 'Amount'
        }
    ]

    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields)),
    });

    const onSubmit = (data) => {
        const dataValue = {
            ...data,
            id: program?.id,
        }
        setType(null)
        admissionFeeTypeCreate.mutateAsync(dataValue);
    }

    const admissionFeeTypeCreate = authQueries.useAdmissionFeeTypeCreateMutation(
        async (response) => {
            if(response?.message === 'already exist'){
                return toast.error('Fee Type Already Exist');
            }
            await intendedView.mutateAsync(program?.id);
            setValue('feetype', '')
            setValue('due', '')
            setValue('amount', '')
            toast.success('Fee Type Added Successfully');
        }   
    );
    

    return(
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 max-[1365px]:grid-cols-10 max-[1208px]:grid-cols-8 max-[888px]:grid-cols-6 max-[690px]:grid-cols-4 max-[505px]:grid-cols-2 gap-x-6 mt-4">
                <div className="col-span-2">
                    <label>Select Fee Type:</label>
                    <select
                        className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 shadow-md"
                        {...register('feetype', {
                            onChange: (e) => {setType(JSON.parse(e.target.value))},
                            onBlur: (e) => {},
                        })}
                        name="feetype"
                        >
                        <option value="">Select Fee Type...</option>
                        {
                            option?.map((item, index) => (
                                <option key={index} value={item?.id}>{item?.name}</option>
                            ))
                        }
                    </select>
                    <div className='text-[red] text-[12px] mt-[2px]'>
                        {errors?.feetype?.message}
                    </div>
                </div>
                {type !== 1 &&
                <div className="col-span-2">
                    <label >Due Date:</label>
                    <input 
                    {...register("due")} 
                    name="due" 
                    type="date" 
                    className="bg-white border border-solid pl-[10px] pr-[10px] border-gray-300 rounded-md w-full h-10 text-[12px] focus:border-none outline-blue-500 outline-offset-0 shadow-md"
                    />
                    <div className='text-[red] text-[12px] mt-[2px]'>
                        {errors?.due?.message}
                    </div>
                </div>
                }
                <div className="col-span-2">
                    <label >Due Amount:</label>
                    <input 
                    {...register("amount")} 
                    name="amount" 
                    type="number" 
                    step="0.01"
                    className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 shadow-md"
                    />
                    <div className='text-[red] text-[12px] mt-[2px]'>
                        {errors?.amount?.message}
                    </div>
                </div>
                <div className="col-span-2">
                    <label >&nbsp;</label>
                    <button 
                    type="submit"
                    className="btn-customized-large w-[100px]" 
                    >Save</button>
                </div>
            </div>
        </form>
    )
}

const FeeTypeUpdate =({intendedView, value, option})=> {

    const [type, setType] = useState(value?.feetype)
    const program = useApplicationFee((state) => state.program);

    const inputFields = [
        {
            name: 'feetype',
            required: true,
            label: 'Fee Type'
        },
        {
            name: 'due',
            required: type === 1 ? false:true,
            label: 'Due Date'
        },
        {
            name: 'amount',
            required: true,
            label: 'Amount'
        }
    ]
    
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    });

    const [edit, setEdit] = useState(true)

    useEffect(() => {
        setValue('feetype', value?.feetype)
        setValue('due', moment(value?.due_date).format('YYYY-MM-DD'))
        setValue('amount', value?.amount)
    }, [value])

    const onSubmit = (data) => {
        if(data.due === "Invalid date" && type !== 1){
            return toast.error("Invalid date please select a valid date")
        }
        const dataValue = {
            ...data,
            intid: program?.id,
            id: value.id
        }
        admissionFeeTypeUpdate.mutateAsync(dataValue);
    }

    const admissionFeeTypeUpdate = authQueries.useAdmissionFeeTypeUpdateMutation(
        async (response) => {
            if(response?.message === 'already exist'){
                return toast.error('Fee Type Already Exist');
            }
            await intendedView.mutateAsync(program?.id);
            toast.success('Fee Type Added Successfully');
            setEdit(true)
        }   
    );


    return(
        <form>
            <div className="grid grid-cols-12 max-[1365px]:grid-cols-10 max-[1208px]:grid-cols-8 max-[888px]:grid-cols-6 max-[690px]:grid-cols-4 max-[505px]:grid-cols-2 gap-x-6 mt-4">
                <div className="col-span-2">
                    <label >Select Fee Type:</label>
                    <select disabled={edit?true:false}
                        className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 shadow-md" 
                        {...register('feetype', {
                            onChange: (e) => {setType(JSON.parse(e.target.value))},
                            onBlur: (e) => {},
                        })}
                        name="feetype">
                        <option 
                        value="">Select Fee Type...</option>
                        {
                            option?.map((item, index) => (
                                <option key={index} value={item?.id}>{item?.name}</option>
                            ))
                        }
                    </select>
                    <div className='text-[red] text-[12px] mt-[2px]'>
                        {errors?.feetype && errors?.feetype?.message}
                    </div>
                </div>
                {
                    type !== 1 &&  
                    <div className="col-span-2">
                        <label>Due Date:</label>
                        <input 
                        disabled={edit?true:false} 
                        {...register(`due`)} 
                        type="date" 
                        className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 shadow-md"
                        />
                        <div className='text-[red] text-[12px] mt-[2px]'>
                            {errors?.due && errors?.due?.message}
                        </div>
                    </div>
                }
                <div className="col-span-2">
                    <label >Amount:</label>
                    <input
                    {...register(`amount`)}
                    name="amount"
                    disabled={edit?true:false} 
                    type="number" 
                    step="0.01"
                    className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 shadow-md"
                    />
                    <div className='text-[red] text-[12px] mt-[2px]'>
                        {errors?.amount && errors?.amount?.message}
                    </div>
                </div>
                {
                    ( value?.feetype === 1 && type === 1 ) &&
                    <div className='col-span-2'/>
                }
                <div className="col-span-2">
                    <label >&nbsp;</label>
                    { edit?
                    <button 
                        onClick={() => setEdit(false)}
                        type="button" 
                        className="btn-customized-large w-[100px] max-[690px]:w-full" 
                    >Edit Fee</button>:
                    <button
                        onClick={handleSubmit(onSubmit)}
                        type="button"
                        className="btn-customized-large w-[100px] max-[690px]:w-full"
                    >Update Fee</button>
                    }
                </div>
            </div>
        </form>
    )
}


const AdmissionFeeForm = () => {
    
    const [result, setResult] = useState({})
    const program = useApplicationFee((state) => state.program);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        feeTypeList.mutateAsync()
        intendedView.mutateAsync(program?.id)
    }, []);

    const intendedView = authQueries.useIntProgramView(
        (res)=>{
            setResult(res?.data)
        }
    )

    const feeTypeList = authQueries.useFeeTypeMutationList(
        (response) => {
        }
    );

  return (
    <div>

        <div className="prg-coordinator">

            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Application Details</li>
                    </ol>
                </nav>
            </div>

            <div className='flex gap-10 bg-white rounded-2xl px-12 py-8 text-[13px] flex-wrap'>

                <div className='flex flex-col justify-center items-center pb-4 border-b border-slate-200 w-full'>
                    <div className="profile-wrpr">
                        <div id="image-preview" style={{
                            backgroundImage: result?.application?.photo ? `url(${result?.application?.photo})` : `url(${require('../../assets/images/profile-avatar.jpg')})`, 
                            backgroundSize:'cover'}}>
                        </div>
                    </div>
                    <div className='font-bold text-[16px] mt-3'>{result?.application?.applicant_name} {result?.application?.middlename} {result?.application?.lastname}</div>
                    <div className='font-semibold text-[14px]'><a href={`tel:+${result?.application?.mobile_no}`}>+{result?.application?.mobile_no}</a></div>
                </div>

                <div className="grid grid-cols-12 max-[1183px]:grid-cols-6 max-[572px]:grid-cols-3 gap-6 w-full">
                    <div className="col-span-3 flex flex-col gap-1">
                        <div>Email</div>
                        <div className='font-semibold'>{result?.application?.email}</div>
                    </div>
                    <div className="col-span-3 flex flex-col gap-1">
                        <div>Visa Type</div>
                        <div className='font-semibold'>{result?.application?.visatype?.description}</div>
                    </div>
                    <div className="col-span-3 flex flex-col gap-1">
                        <div>Candidate Intake</div>
                        <div className='font-semibold'>{result?.application?.intake?.intake}</div>
                    </div>
                    <div className="col-span-3 flex flex-col gap-1">
                        <div>Application Intake</div>
                        <div className='font-semibold'>{result?.intake?.intake}</div>
                    </div>
                    <div className="col-span-3 flex flex-col gap-1">
                        <div>Program Name</div>
                        <div className='font-semibold'>
                            {
                                result?.program?.programlink ?
                                <a href={result?.program?.programlink} target="_blank">{result?.program?.name}</a> : 
                                (result?.program?.name)
                            }
                        </div>
                    </div>
                    <div className="col-span-3 flex flex-col gap-1">
                        <div>College</div>
                        <div className='font-semibold flex-wrap'>{result?.college?.name}</div>
                    </div>
                    <div className="col-span-3 flex flex-col gap-1">
                        <div>Program Duration</div>
                        <div className='font-semibold'>{result?.program?.duration && `${result?.program?.duration} Year`}</div>
                    </div>
                    <div className="col-span-3 flex flex-col gap-1">
                        <div>Program Code</div>
                        <div className='font-semibold'>{result?.program?.program_code} </div>
                    </div>
                    <div className="col-span-3 flex flex-col gap-1">
                        <div>Placement</div>
                        <div className='font-semibold'>{result?.placement}</div>
                    </div>
                    <div className="col-span-3 flex flex-col gap-1">
                        <div>Application Status</div>
                        <div className='font-semibold'>{result?.status}</div>
                    </div>

                </div>

                <div className="grid grid-cols-12 mb-4 gap-4 change-application-status-wrpr">
                    <div className='col-span-12 px-10 max-[580px]:px-1'>
                        <div>
                            {
                                result?.admission_feetypes?.map((item, index) => (
                                    <Fragment key={index}>
                                        <FeeTypeUpdate intendedView={intendedView} value={item} option={feeTypeList?.data?.data} />
                                    </Fragment>
                                )
                            )}
                        </div>
                    </div>
                    <div className='col-span-12 px-10 max-[580px]:px-1'>
                        <div>
                            <FeeTypeCreate intendedView={intendedView} option={feeTypeList?.data?.data} />
                        </div>
                    </div>
                    <div className='col-span-12 px-10 max-[580px]:px-1'> 
                        <div className="grid grid-cols-12 max-[1365px]:grid-cols-10 max-[1208px]:grid-cols-8 max-[888px]:grid-cols-6 max-[690px]:grid-cols-4 max-[505px]:grid-cols-2 gap-x-6 mt-4">
                            <div className="col-span-2" />
                            <div className="col-span-2 text-[16] font-bold custom-textcolor">
                                Total Amount
                            </div>
                            <div className="col-span-2 text-[16] font-bold custom-textcolor">
                                <TotalFeeCalculate amount={result?.admission_feetypes} />
                            </div>
                            <div className="col-md-1" />
                        </div>
                    </div>
                </div>               

            </div>

        </div>
    </div>
  )
}

export default AdmissionFeeForm


const TotalFeeCalculate = (props) => {

    const [total, setTotal] = useState(0);

    useEffect(() => {
        const total = props?.amount?.reduce((accumulator, item) => {
            return accumulator + parseFloat(item.amount || 0);
        }, 0);
        const roundedTotal = Math.round(total * 100) / 100;
        let formattedNumber = roundedTotal.toFixed(2);
        setTotal(formattedNumber)
    }, [props])

 
    return  (
        <div>
            {total}
        </div>
    )

}