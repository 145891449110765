import React, { Fragment, useState } from 'react'
import AssociatesTileModal from './AssociatesTileModal'
import authQueries from '../../queries/authQueries';

const AssociatesTile = ({ slideArr, slideArr2, data, fYear, setFYear}) => {

    const [visible, setVisible] = useState(false);
    const [result, setResult] = useState([]);
    const [item, setItem] = useState({});

    const handleOpen = (item, element) => {
        if(element < 1){
            return
        }
        setItem(item);
        tileListing.mutateAsync({value:item?.value, fyear: item?.fy ? fYear: "", page: 0})
        setVisible(true);
    }

    const tileListing = authQueries.useAssociateTileListMutation(
        (res)=>{
            if(res?.message === "success"){
                setResult(res)
            }
        }
    )

    const handleClose = () => {
        setVisible(false);
    }

    const handlePageChange = (e, value) => {
        console.log(value)
        tileListing.mutateAsync({value:item?.value, fyear: item?.fy ? fYear: "", page: value-1})
    }

  return (
    <Fragment>

        <div className='flex justify-end w-full'>
            <div className='w-60'>
                <div className="form-group">
                    <select
                    onChange={(e)=>setFYear(e.target.value)}
                    className="form-control text-[12px]"
                    >
                    <option value="CURRENT">Current FY</option>
                    <option value="PREVIOUS">Previous FY</option>
                    <option value="FUTURE">Future FY</option>
                    </select>
                </div>
            </div>
        </div>


        <div className="carousel-section border-none mb-4 pb-0">
            <div className="row">
                <div className="col-md-12">
                    <div className="swiper-container followup-pending geebee-carousel">
                        <div className="swiper-wrapper">
                            {
                                slideArr.map((item, index) => (
                                    <Slides handleOpen={handleOpen} item={item} data={data} key={index} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="carousel-section border-none mb-4 pb-0">
            <div className="row">
                <div className="col-md-12">
                    <div className="swiper-container followup-pending geebee-carousel">
                        <div className="swiper-wrapper">
                            {
                                slideArr2.map((item, index) => (
                                    <Slides handleOpen={handleOpen} item={item} data={data} key={index} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <AssociatesTileModal visible={visible} handleClose={handleClose} result={result} handlePageChange={handlePageChange} />
      
    </Fragment>
  )
}

export default AssociatesTile

const Slides = ({ item, handleOpen, data }) => {

    const element = data?.[item?.value]

	return (
        <button
        disabled={item?.count < 1}
        onClick={()=>handleOpen(item, element)}
        className="swiper-slide h-32 rounded-2xl text-white cursor-pointer swiper-slide-active w-[261px] mr-[10px]"
        style={{background: item?.color, cursor: item?.count < 1 ? "not-allowed" : "pointer"}}>
            <div className="flex flex-col p-3 justify-between h-full">
                <div className="flex justify-between">
                    <div className="bg-white rounded-full flex justify-center items-center w-10 h-10">
                        {item?.icon}
                    </div>
                    <div className="text-4xl">{element}</div>
                </div>
                <div>
                    <div className="text-[14px] text-left">{item?.title}</div>
                </div>
            </div>
        </button>
	)
}

