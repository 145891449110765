/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Pagination, Select, Spin } from 'antd'
import { decode } from 'html-entities'
import { useNavigate } from 'react-router-dom';
import authQueries from '../../queries/authQueries';
import { userStore } from '../../store/userStore';
import Swiper from 'swiper';
import 'swiper/css';
import moment from 'moment';
import HOT from '../../assets/images/hot-icon-2.png'
import WARM from '../../assets/images/warm-icon-2.png'
import COLD from '../../assets/images/cold-icon-2.png'
import REG from '../../assets/images/registered-icon-2.png'
import LONG from '../../assets/images/longterm-icon-3.png'
import HOLD from '../../assets/images/on-hold-icon.png'
import IELTS from '../../assets/images/ielts-icon-2.png'
import TOT from '../../assets/images/tot-icon-2.png'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import $ from 'jquery'
import Charts from '../Charts/Charts';
import CountUp from 'react-countup';
import { Checkbox, Loading, Modal } from '@nextui-org/react';
import { Autocomplete, TextField } from '@mui/material';
import { CheckCircle2, Vote } from 'lucide-react';
import { IconHourglass } from '@tabler/icons-react';
import FollowUp from '../Common/FollowUp';
import FollowupToday from '../Common/FollowupToday';
import FollowupPending from '../Common/FollowupPending';
import FollowupPendingLead from '../Common/FollowupPendingLead';


const RegistrationManagerDashboard = () => {

    const [count, setCount] = useState({})
	const [loader, setLoader] = useState(true);

    const counts = authQueries.useMarkRegMutationCounts(
		async (response) => {
      	if(response?.message === "success")
		setLoader(false)
      	setCount(response)
    });

    useEffect(() => {
        counts.mutateAsync()
	}, [])


	const slideArr = [
		{
			color: '#7B66FF',
			title: 'Total Active Files',
			icon: <Vote color='#7B66FF' size="20" />,
			count: count.all,
		},
		{
			color: '#FF6666',
			title: 'Pending Files',
			icon: <IconHourglass color='#FF6666' size="20" />,
			count: count.pending,
		},
		{
			color: '#00DFA2',
			title: 'Completed Files',
			icon: <CheckCircle2 color='#00DFA2' size="20" />,
			count: count.completed,
		},
	];

	const slider = () => {
 
		var swiper = new Swiper(".geebee-carousel.followup-pending", {
			slidesPerView: 5,
			spaceBetween: 10,
			speed: 700,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
				"1": {
					slidesPerView: 1,
					spaceBetween: 10,
                },
				'480': {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				'640': {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				'920': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
				'1200': {
					slidesPerView: 5,
					spaceBetween: 10,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next.carousel-next",
				prevEl: ".swiper-button-prev.carousel-prev",
			},
		});

	}

	useEffect(() => {
		slider();
		$('html,body').animate({ scrollTop: 0 }, 'fast');
	}, [loader]);


	return ( 
	<div>



		{
			loader ?
			<div style={{display:'flex', justifyContent:'center', flexDirection:'column', marginTop:'15%'}}>
				<Loading  />
				<span style={{marginTop:'10px', textAlign:'center', fontSize:'12px', color:'#888'}}>Loading...</span>
			</div>:
			<div>
				<div className="carousel-section mb-5">
					<div className="row">
						<div className="col-md-12">
							<div className="swiper-container followup-pending geebee-carousel">
								<div className="swiper-wrapper">
									{slideArr.map((item, index) => (
										<div
										key={index}
										className="swiper-slide h-32 rounded-2xl text-white swiper-slide-active w-[261px] mr-[10px]"
										style={{background: item?.color}}>
											<div className="flex flex-col p-3 justify-between h-full">
												<div className="flex justify-between">
													<div className="bg-white rounded-full flex justify-center items-center w-10 h-10">
														{item?.icon}
													</div>
													<div className="text-4xl">{item?.count}</div>
												</div>
												<div>
													<div className="text-[14px] text-left">{item?.title}</div>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>


				<FollowupToday />
				<FollowupPending />

			</div>
		}

	</div>
	)
}

export default RegistrationManagerDashboard