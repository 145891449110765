/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from 'react'
import authQueries from '../../queries/authQueries';
import { useForm } from 'react-hook-form';
import { usePaginationStore, userStore } from '../../store/userStore';
import moment from 'moment';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { decode } from 'html-entities';
import LoaderCommon from '../Common/LoaderCommon';
import FollowUp from '../Common/FollowUp';
import Swiper from 'swiper';
import $ from 'jquery'
import CountUp from 'react-countup';
import VisaStaffVisaData from '../Common/VisaStaffVisaData';
import FollowupToday from '../Common/FollowupToday';
import FollowupPending from '../Common/FollowupPending';
import LeadStatus from '../Common/LeadStatus';
import ProspectStatusList from '../Common/ProspectStatusList';
import ApplicantViewButton from '../Common/ApplicantViewButton';
import DocumentUploadModal from '../Common/DocumentUploadModal';
import ApplicationStatusModal from '../Common/ApplicationStatusModal';
import { useApi } from '../Common/ApiProvider';
import PaginationComponent from '../Common/PaginationComponent';
import VisaStaffMyClients from '../VisaStaff/VisaStaffMyClients';

const VisaStaffDashboard = () => {

    const [result, setResult] = useState([]);
    const [active, setActive] = useState({index:null, status:false});
    const [count, setCount] = useState({})
	  const [loader, setLoader] = useState(true);
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [college, setCollege] = useState("")
    const [intake, setIntake] = useState("")
    const [counselor, setCounselor] = useState("")
    const [office, setOffice] = useState("")
    const [visaType, setVisaType] = useState("")

    const currentPage = usePaginationStore((state)=> state.currentPage)
    const setCurrentPage = usePaginationStore((state)=> state.setCurrentPage)

    const { apiData } = useApi()

    const store = userStore()

    const expantButton =(index)=> {

        if(active.index === index){
            setActive({
                index: null,
                status: false
            })
        }else{
            setActive({
                index: index,
                status: true
            })
        }
    }

    const countDashboard = authQueries.useVisaStaffDashboardCountMutation(
		    async (response) => {
      	    setCount(response)
            setLoader(false)
        }
    )

    const visaStaffListing = authQueries.useApplicantAssignVisaStaffMutationList(
        (response) => {
            setListLoader(false)
            setResult(response)
        }   
    )

    const collegeListing = authQueries.useCollegeMutationList(
      (response) => {
      }
    )

    const visaListing = authQueries.useVisaTypeMutationList(
      (response) => {
      }
    )

    const counselorList = authQueries.useCounselorAssignList(
      (response) => {
      }   
    )

    const officeList = authQueries.useOfficeMutationList(
      (response) => {
      }   
    )

    const inTakeList = authQueries.useIntakeVisaMutationList(
      (response) => {
      }
    )

    const selectVisa =(id)=> {
      setVisaType(id)
      inTakeList.mutateAsync(id);
    }

    useEffect(() => {
      countDashboard.mutateAsync()
      collegeListing.mutateAsync({search: '', status: '', page:0, size:20000});
      visaListing.mutateAsync({search: '', status: '', page:0, size:20000});
      officeList.mutateAsync({search: '', status: '', page:0, size:20000});
      counselorList.mutateAsync({role:3, office: ''})
	  },[])

    useEffect(() => {
        visaStaffListing.mutateAsync({
          size: 25, 
          page: currentPage,
          appName: name,
          mobile: mobile,
          email: email,
          college: college,
          visa: visaType,
          intake: intake,
          office: office,
          counselor: counselor
        })
    },[name, visaType, email, mobile, intake, college, counselor, office, apiData, currentPage])


    const [listLoader, setListLoader] = useState(true)

  const slideArr = [
		{
			color: 'blue',
			title: 'Total Assigned',
			name: 'Total Active Files',
			image: "icon-expand1",
			value: "ACTIVE",
			count: count?.total,
			id: null
		},
		{
			color: 'yellow',
			title: 'Visa Applied',
			name: 'Pending Files',
			image: "icon-hour-glass",
			value: "PENDING",
			count: count?.applied,
			id: null
		},
		{
			color: 'green',
			title: 'Visa Approved',
			name: 'Completed Files',
			image: "icon-input-checked",
			value: "COMPLETE",
			count: count?.approved,
			id: null
		},
    {
			color: 'orange',
			title: 'Visa Refused',
			name: 'Total Active Files',
			image: "icon-expand1",
			value: "ACTIVE",
			count: count?.refused,
			id: null
		},
		{
			color: 'red',
			title: 'Refund Requested',
			name: 'Pending Files',
			image: "icon-hour-glass",
			value: "PENDING",
			count: count?.requested,
			id: null
		},
		{
			color: 'purple',
			title: 'Refund Completed',
			name: 'Completed Files',
			image: "icon-input-checked",
			value: "COMPLETE",
			count: count?.completed,
			id: null
		},
	];

	const slider = () => {
 
		var swiper = new Swiper(".geebee-carousel.followup-pending", {
			slidesPerView: 5,
			spaceBetween: 10,
			speed: 700,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
        "1": {
					slidesPerView: 1,
					spaceBetween: 10,
        },
				'480': {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				'640': {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				'920': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
				'1200': {
					slidesPerView: 6,
					spaceBetween: 10,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next.carousel-next",
				prevEl: ".swiper-button-prev.carousel-prev",
			},
		});

	}

	useEffect(() => {
		slider();
		$('html,body').animate({ scrollTop: 0 }, 'fast');
	}, [loader]);


  const counselorName =(item)=> {
		const name = item?.firstname +" "+ item?.lastname 
		return name
	}


  return (
    <div>

        {
            loader ?
            <div style={{display:'flex', justifyContent:'center', flexDirection:'column', marginTop:'15%'}}>
                <LoaderCommon  />
            </div> :
            <div>
                <div className="carousel-section mb-5">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="swiper-container followup-pending geebee-carousel">
                                <div className="swiper-wrapper">
                                    {slideArr.map((item, index) => (
                                        <div style={{cursor:'pointer'}} className="swiper-slide" key={index}>
                                            <div className={`icon-and-title-count ${item.color}`}>
                                                <div className="icon-and-title">
                                                    <i className={`${item?.image} icon-customimage`} style={{fontSize: item?.value !== "COMPLETE" && "14px"}}></i>
                                                    <div className="count">
                                                        {item?.count === 0 ? 0 : <CountUp end={item.count} duration={1} />}
                                                    </div>
                                                </div>
                                                <h2>{item.title} Clients</h2>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FollowupToday />
                <FollowupPending />

            </div>
        }

    </div>
  )
}

export default VisaStaffDashboard