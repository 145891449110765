import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../queries/authQueries'
import LoaderCommon from './LoaderCommon'
import { decode } from 'html-entities'
import moment from 'moment'
import { Empty } from 'antd'
import { Checkbox } from '@nextui-org/react'

const ProgramSuggestion = () => {

    const [result, setResult] = useState([])
    const [dropDownData, setDropdownData] = useState([])
    const [name, setName] = useState("")
    const [visa, setVisa] = useState("")
    const [qid, setQid] = useState("")
    const [sid, setSid] = useState("")
    const [college, setCollege] = useState("")
    const [counselor, setCounselor] = useState("")
    const [approved, setApproved] = useState(false)
    const [active, setActive] = useState("")

    const dropdown = authQueries.useProgramSuggestionMasterList(
        (res)=>{
            setDropdownData(res?.data)
        }
    )

    useEffect(()=>{
        dropdown.mutateAsync()
    },[])

    const programSuggestionList = authQueries.useProgramSuggestionListMutation(
        (res)=>{
            setResult(res)
        }
    )

    const onSearch =()=> {
        programSuggestionList.mutateAsync({
            name: name,
            qid: qid, 
            visa: visa,
            approved: approved ? 'yes' : 'no',
            sid: sid,
            college: college,
            counselor: counselor
        })
    }

    const expantButton = (id) => {
        if(active === id){
            setActive("")
            return
        }
        setActive(id)
    }

  return (
    <div>

        <div className="add_clients">
            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr">
                    <i className="appointments-icon">
                        <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <h2><span>Program</span>Suggestions</h2>
                </div>
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>
            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header">
                        <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                            Program Suggestions
                        </a>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">
                            <div className="table-list-search-wrpr">
                                <div className='grid grid-cols-12 gap-4 max-[1214px]:grid-cols-10 max-[958px]:grid-cols-8 max-[824px]:grid-cols-6 max-[622px]:grid-cols-4 max-[374px]:grid-cols-2 !w-full mb-4'>
                                    <div className='col-span-2'>
                                        <label>Applicant Name</label>
                                        <input value={name} onChange={(e)=>{setName(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]' />
                                    </div>
                                    <div className='col-span-2'>
                                        <label>Visa Type</label>
                                        <select value={visa} onChange={(e)=>{setVisa(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]'>
                                            <option value="">Select Visa Type</option>
                                            {
                                                dropDownData?.visatype?.map((item, index)=>(
                                                    <option key={index} value={item?.id}>{item?.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className='col-span-2'>
                                        <label>Qualification</label>
                                        <select value={qid} onChange={(e)=>{setQid(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]'>
                                            <option value="">Select Qualification</option>
                                            {
                                                dropDownData?.qualification?.map((item, index)=>(
                                                    <option key={index} value={item?.id}>{item?.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className='col-span-2'>
                                        <label>Application Status</label>
                                        <select value={sid} onChange={(e)=>{setSid(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]'>
                                            <option value="">Select Application Status</option>
                                            {
                                                dropDownData?.application_status?.map((item, index)=>(
                                                    <option key={index} value={item?.id}>{item?.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className='col-span-2'>
                                        <label>College</label>
                                        <select value={college} onChange={(e)=>{setCollege(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]'>
                                            <option value="">Select College</option>
                                            {
                                                dropDownData?.college?.map((item, index)=>(
                                                    <option key={index} value={item?.id}>{item?.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className='col-span-2'>
                                        <label>Counselor</label>
                                        <select value={counselor} onChange={(e)=>{setCounselor(e.target.value)}} className='form-control form-control-sm !w-full !min-h-[38px]'>
                                            <option value="">Select Counselor</option>
                                            {
                                                dropDownData?.users?.map((item, index)=> {
                                                    const details = `${item?.userdetail?.firstname} ${item?.userdetail?.lastname}` 
                                                    return(
                                                        <option key={index} value={item?.id}>{details}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-span-2 flex flex-col'>
                                        <label>&nbsp;</label>
                                        <Checkbox  onChange={(e)=>{setApproved(e)}} className='!min-h-[38px] mt-[2px]'>
                                            <div className='text-[12px]'>
                                                Only show Visa Approved
                                            </div>
                                        </Checkbox>
                                    </div>
                                    <div className='col-span-1 flex flex-col'>
                                        <label>&nbsp;</label>
                                        <button onClick={onSearch} className='btn-customized-large text-[14px]'>
                                            Search
                                        </button>
                                    </div>
                                </div>
                                <div className="search-results-count-wrpr">
                                    {
                                    result?.data?.length > 0 ?
                                    `Search Results of ${result?.data?.length}` :
                                    `O results found`
                                    }
                                </div>
                                {
                                    programSuggestionList.isLoading ?
                                    <LoaderCommon />
                                    :
                                        result?.data?.length > 0 ?
                                        <div className="table-wrpr">
                                            <div className="table-section">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <table className="table prospect-table">
                                                            <thead>
                                                                <tr className='alt-colors'>
                                                                    <th width="30">#</th>
                                                                    <th>Applicant Name{active}</th>
                                                                    <th>Visa Type</th>
                                                                    <th>Email/Mobile</th>
                                                                    <th>Office</th>
                                                                    <th>Created Date</th>
                                                                    <th>Counselor</th>
                                                                    <th>Program Coordinator</th>
                                                                    <th>Admission Staff</th>
                                                                    <th>Fee Coordinatore</th>
                                                                    <th>Visa Staff</th>
                                                                    <th>Intake</th>
                                                                    <th>Prospect Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="ui-sortable">
                                                                {result?.data?.map((item, index) => (
                                                                    <Fragment key={index}>
                                                                        <tr>
                                                                            <th className="">{index + 1}</th>
                                                                            <td>
                                                                                <a style={{ cursor: 'pointer', color: '#1000FF' }} onClick={() => { expantButton(item?.id) }}>
                                                                                    {decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)} <i className='icon-arrow-down-thick'></i>
                                                                                </a> 
                                                                            </td>
                                                                            <td>{item?.visatype?.description}</td>
                                                                            <td>
                                                                                <div className="email-mobile">
                                                                                    <span>{item.email}</span>
                                                                                    <span>{item.mobile_no}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td>{item?.office?.name}</td>
                                                                            <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                            <td>
                                                                                <UserFind user={item?.assignUser?.CounselorAssign} />
                                                                            </td>
                                                                            <td>
                                                                                <UserFind user={item?.assignUser?.ProgramAssign} />
                                                                            </td>
                                                                            <td>
                                                                                <UserFind user={item?.assignUser?.AdmissionAssign} />
                                                                            </td>
                                                                            <td>
                                                                                <UserFind user={item?.assignUser?.FeeAssign} />
                                                                            </td>
                                                                            <td>
                                                                                <UserFind user={item?.assignUser?.VisaAssign} />
                                                                            </td>
                                                                            <td>{item?.intake?.intake}</td>
                                                                            <td>{item?.status_of_prospect?.name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan="13" className={active === item?.id ? "dropdown-table-wrpr active" : "dropdown-table-wrpr"}>
                                                                                <table className="table dropdown-table">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>#</th>
                                                                                            <th>College/University</th>
                                                                                            <th>Program Name</th>
                                                                                            <th>Campus</th>
                                                                                            <th>Application Status</th>
                                                                                            <th>Intake</th>
                                                                                            <th>Placement</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {item?.intended_programs?.map((items, indexes) => (
                                                                                            <tr className="ui-sortable-handle" key={indexes}>
                                                                                                <td>{indexes + 1}</td>
                                                                                                <td>{items?.college?.name}</td>
                                                                                                <td>{items?.program?.name}</td>
                                                                                                <td>{items?.intake?.intake}</td>
                                                                                                <td>{items?.appstaffstatuss?.name}</td>
                                                                                                <td>{items?.intake?.intake}</td>
                                                                                                <td>{items?.placement}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </Fragment>

                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className='mt-24 mb-24'>
                                            <Empty
                                            description={<div className="font-sans text-sm text-blue-700">Clients not found!</div>}
                                            />
                                        </div>
                                    
                                }

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
      
    </div>
  )
}

export default ProgramSuggestion


const UserFind =({user})=> {
    if(user){
        return `${user?.userdetail?.firstname} ${user?.userdetail?.lastname}`
    }else{
        return
    }
}