import React, { Fragment, useEffect } from 'react'
import { useColorScheme } from '../../store/colorSchemeStore';

const ColorSheme = () => {

    const color = useColorScheme((state)=> state.bgColor)
    const setColor = useColorScheme((state)=> state.setBg)
    const activeTextColor = useColorScheme((state)=> state.activeTextColor)
    const setActiveTextColor = useColorScheme((state)=> state.setActiveTextColor)

    useEffect(() => {
        document.documentElement.style.setProperty('--primary-color', color);
        document.documentElement.style.setProperty('--active-sidebar', activeTextColor)
    }, [color, activeTextColor]);
    
    const colors = [
        '#323a51', '#186F65', '#FE7A36', 
        '#FF004D', '#AAD9BB', '#4942E4', 
        '#756AB6', '#88AB8E', '#FF0303', 
        '#FC6736', '#5F0F40', '#D0A2F7',
        '#F4CE14', '#008170', '#D2DE32',
        '#E90064', '#16FF00', '#A084DC',
        '#FF0032', '#EAC7C7', '#FF7B54',
        '#A31ACB', '#A555EC', '#E5BA73',
        '#3CCF4E', '#231955', '#06283D',
        '#94B49F', '#FFA500', '#FF06B7',
        '#354259', '#00FFAB', '#15133C',
        '#001D6E', '#383838', '#0E3EDA',
        '#019267', '#5463FF', '#084594'
    ]

    const handleChange =async(item)=>{
       setColor(item)
    }

    const handleActiveTextChange =async(item)=>{
        setActiveTextColor(item)
    }

    return(
        <Fragment>
            <div className='text-[14px] font-semibold mb-2'>
                Background Colors
            </div>
            <div className='flex gap-4 flex-wrap'>
                {
                    colors?.map((item, index)=> (
                        <div
                        key={index}
                        style={{background:item}}
                        className='w-10 min-w-10 h-10 rounded-full cursor-pointer' onClick={()=>{handleChange(item)}} />
                    ))
                }
            </div>
            <div className='text-[14px] font-semibold my-4'>
                Sidebar Active Text Colors
            </div>
            <div className='flex gap-4 flex-wrap'>
                {
                    colors?.map((item, index)=> (
                        <div
                        key={index}
                        style={{background:item}}
                        className='w-10 min-w-10 h-10 rounded-full cursor-pointer' onClick={()=>{handleActiveTextChange(item)}} />
                    ))
                }
            </div>
        </Fragment>
    )
}

export default ColorSheme
