
import React, { useEffect } from "react";
import AppRoutes from "./Routes/AppRoutes";
import SocketNotification from "./socket/SocketNotification";
import { useColorScheme } from "./store/colorSchemeStore";
import { Toaster } from "react-hot-toast";

const App = () => {

  const color = useColorScheme((state)=> state.bgColor)
  const activeTextColor = useColorScheme((state)=> state.activeTextColor)

  useEffect(() => {
      document.documentElement.style.setProperty('--primary-color', color);
      document.documentElement.style.setProperty('--active-sidebar', activeTextColor);
  }, []);

  return (
    <div>
      <SocketNotification />
      <AppRoutes />
      <Toaster
      position="top-center"
      reverseOrder={false}
      />
    </div>
  );
}

export default App;
