import { Modal } from '@nextui-org/react'
import React, { Fragment, useState } from 'react'
import ModalHeaderText from './ModalHeaderText'
import moment from 'moment'
import authQueries from '../../queries/authQueries'
import { useApi } from './ApiProvider'
import LoaderCommon from './LoaderCommon'
import { Pencil } from 'lucide-react'
import { IconCheck, IconEditCircle, IconPencil } from '@tabler/icons-react'

const PaymentCreateModal = ({item}) => {

    const [open, setOpen] = useState(false)
    const [result, setResult] = useState([])
    const [loader, setLoader] = useState(true)

    const modalFeeData = authQueries.useFeeCoordinatorMutationModalList(
        (response) => {
            setResult(response)
            setLoader(false)
        }
    )

    const handleOpen =()=>{
        setLoader(true)
        setOpen(true)
        modalFeeData.mutateAsync(item?.id)
    }

    const handleClose =()=>{
        setOpen(false)
    }

  return (
    <Fragment>
        <button className='btn-customized' onClick={handleOpen}>Payment</button>
        <Modal
        closeButton
        blur
        scroll
        width="68%"
        aria-labelledby="modal-title"
        open={open}
        onClose={handleClose}
        >
        <Modal.Header>
            <ModalHeaderText label="Payment Details" />
        </Modal.Header>
        <Modal.Body>
            {
                loader?
                <LoaderCommon />:
                <div className="table-wrpr">
                    <div className="table-section">
                        <div className="row">
                            <div className='col-md-12'>
                                <table className="table prospect-table">
                                    <thead className='text-[14px]'>
                                        <tr>
                                            <th width="5%">#</th>
                                            <th width="12%">Due Date</th>
                                            <th width="15%">Type</th>
                                            <th width="12.5%">Amount</th>
                                            <th width="12.5%">Paid</th>
                                            <th width="43%">Pending</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-[14px]'>
                                    {
                                        result?.split?.map((element, index) => (
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{element?.due_date && moment(element?.due_date).format('DD-MM-YYYY')}</td>
                                                <td>{element?.feetype?.name}</td>
                                                <td>{element?.amount}</td>
                                                <td>{element?.paid}</td>
                                                <td>
                                                    <PendingFeeAdd item={item} pendingItem={element} modalFeeData={modalFeeData} />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                    <tfoot className="h-[65px] text-[15px] font-bold">
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>Total</td>
                                            <td>{result?.total?.totalFee}</td>
                                            <td>{result?.total?.totalPaid}</td>
                                            <td>{result?.total?.totalPending}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                                
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer> 
        </Modal>
    </Fragment>
  )
}

export default PaymentCreateModal


const PendingFeeAdd = ({item, pendingItem, modalFeeData}) => {
        
    const [edit, setEdit] = useState(false);
    const [amount, setAmount] = useState(pendingItem?.pending);
    const [method, setMethod] = useState("")

    const { setApiDataAndNotify } = useApi()

    const paymentCreate = authQueries.useFeeCoordinatorMutationModalCreate(
        (response) => {
            alert(response.message)
            setApiDataAndNotify(response)
            modalFeeData.mutateAsync(item?.id).then(
                (res)=>{
                    if(res?.message === "Success"){
                        const newData = res?.split?.find(item=> item?.id === pendingItem?.id)
                        setAmount(newData?.pending)
                        setEdit(false)
                    }
                }
            )
            setMethod('')
        }
    ) 

    const onSubmit = () => {
        const dataValues = {
            appid: item?.applicant_id,
            feetype: pendingItem?.id,
            method: method,
            amount: amount,
        }

        if(method === ""){
            return alert("Please select payment method")
        }
        else if(parseInt(amount) < 1){
            return alert("0 Not allowed")
        }

        paymentCreate.mutateAsync(dataValues)

    }

    const handleSubmit =()=>{
        console.log(pendingItem?.pending)
        if(pendingItem?.pending > 0){
            return setEdit(true)
        }
        
        if(pendingItem?.pending < 1){
            alert('Fee is already paid')
        }
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-5" style={{paddingLeft:"5px", paddingRight:"5px"}}>
                    <div>
                        <select 
                        disabled={!edit}
                        style={{opacity: !edit ? 0.7:1}}
                        value={method} 
                        onChange={(e)=>setMethod(e.target.value)} 
                        className='bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 font-poppins'
                        >
                            <option value="">Select Payment</option>
                            <option>Flyware</option>
                            <option>Paul Merchant</option>
                            <option>CIBC</option>
                            <option>Paymytuition</option>
                            <option>Wire Transfer (Bank Transfer)</option>
                            <option>Card Payments (Debit / Credit)</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-3" style={{paddingLeft:"5px", paddingRight:"5px"}}>
                    <div>
                        <input 
                        type="number"
                        style={{opacity: !edit ? 0.7:1}}
                        disabled={!edit} 
                        value={amount}
                        step="0.01"
                        onChange={(e)=>setAmount(e.target.value)} 
                        className='bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 font-poppins'
                        />
                    </div>
                </div>
                <div className="col-md-2" style={{paddingLeft:"5px", paddingRight:"5px"}}>
                    <div>
                    {
                        !edit  ?
                            <button onClick={handleSubmit} className='bg-red-500 h-10 px-[12px] rounded-md '>
                            <IconPencil size="18" color="white" />
                            </button> : 
                            <button onClick={onSubmit} className='bg-green-500 h-10 px-[12px] rounded-md '>
                            <IconCheck size="18" color="white" />
                            </button>
                    }

                    </div>
                </div>
            </div>
        </div>
    )
}