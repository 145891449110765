import React, { Fragment, useEffect, useState } from 'react'
import LoaderCommon from '../Common/LoaderCommon'
import { Empty } from 'antd'
import moment from 'moment'
import authQueries from '../../queries/authQueries'
import { usePaginationStore } from '../../store/userStore'
import PaginationComponent from '../Common/PaginationComponent'
import LeadStatus from '../Common/LeadStatus'
import ProspectStatusList from '../Common/ProspectStatusList'
import ApplicantViewButton from '../Common/ApplicantViewButton'
import InputSearch from '../Common/InputSearch'
import SelectSearch from '../Common/SelectSearch'

const AssociatesMyClients = () => {

    const [result, setResult] = useState([]);
    const [prosStatus, setProsStatus] = useState('')
    const [selectDate, setSelectDate] = useState("")
    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(new Date())
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [loader, setLoader] = useState(true)

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const listAssociates = authQueries.useAssociatesMutationList(
        (response) => {
            setLoader(false)
            setResult(response)
        }   
    );

    const prospectStatus = authQueries.useProspectList(
        (res)=>{
        }
    )

    const handleSelectDate =(e)=>{
        setSelectDate(e)
    }

    const inputSearchArry = [
        {
            label: "Applicant Name",
            size: 2,
            state: name,
            setState: setName,
            type: "text",
            input: InputSearch,
            simple: true
        },
        {
            label: "Contact Number",
            size: 2,
            state: mobile,
            setState: setMobile,
            type: "number",
            input: InputSearch,
            simple: true
        },
        {
            label: "Email",
            size: 2,
            state: email,
            setState: setEmail,
            type: "text",
            input: InputSearch,
            simple: true
        },
        {
            label: "Prospect Status",
            size: 2,            
            state: prosStatus,
            setState: setProsStatus,
            input: SelectSearch,
            value: prospectStatus?.data?.data?.items,
            name: 'name',
        },
        {
            label: "Select Date",
            size: 2,            
            state: selectDate,
            setState: handleSelectDate,
            input: SelectSearch,
            value: [{id:"CURRENT",name:"Current FY"}, {id:"CUSTOM",name:"Custom"}],
            name: 'name',
        },
        {
            label: "From Date",
            size: 2,            
            state: moment(fromDate).format("YYYY-MM-DD"),
            setState: setFromDate,
            type: "date",
            input: InputSearch,
            hidden: selectDate === "CUSTOM" ? false : true,
        },
        {
            label: "To Date",
            size: 2,            
            state: moment(toDate).format("YYYY-MM-DD"),
            setState: setToDate,
            type: "date",
            input: InputSearch,
            hidden: selectDate === "CUSTOM" ? false : true,
        },
    ]

    useEffect(() => {
        listAssociates.mutateAsync({
            page: currentPage, 
            size:25,
            status:"ALL",
            name:name,
            email:email,
            mobile:mobile,
            prosStatus:prosStatus,
            selectDate:selectDate,
            fromDate:fromDate,
            toDate:toDate
        });
    }, [currentPage, prosStatus, selectDate, fromDate, toDate, name, email, mobile]);

    useEffect(() => {
        prospectStatus.mutateAsync({page:0, size:1000000})
    }, []);

  return (
    <Fragment>
        
        <div id="accordion" className="add_clients_accordion_form mt-4">
            <div className="card" id="appointments_wrpr">
                <div className="card-header flex justify-between">
                    <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                        My Clients
                    </a>
                </div>
                <div id="appointment_any" className="collapse show" data-parent="#accordion">
                    <div className="card-body">

                        <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5">
                            {
                                inputSearchArry?.map((item, index)=> {
                                    if(item?.hidden){
                                        return
                                    }
                                    return(
                                    <Fragment key={index}>
                                        <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                            <item.input item={item} />
                                        </div>
                                    </Fragment>
                                )})
                            }
                        </div>

                        <div className="table-list-search-wrpr simple-search">

                            <div className="search-results-count-wrpr">
                                {
                                result?.data?.totalItems > 0 ?
                                `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                `O results found`
                                }
                            </div>

                            {loader? 
                                <LoaderCommon />:	
                                result?.data?.totalItems > 0 ?
                                <div className="table-wrpr">
                                    <div className="table-section">
                                        <div className="row">
                                            <div className="col-md-12 mt-2">
                                                <table className="table prospect-table">
                                                    <thead>
                                                        <tr>
                                                            <th width="30">#</th>
                                                            <th>Profile Status</th>
                                                            <th>Office</th>
                                                            <th>Date Created</th>
                                                            <th>Applicant Name</th>
                                                            <th>Contact Number</th>
                                                            <th>Visa Type</th>
                                                            <th>Intake</th>
                                                            <th>Counselor</th>
                                                            <th>Reg. Date</th>
                                                            <th>Prospect Status</th>
                                                            <th>Reffered By</th>
                                                            <th width='150'>&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="ui-sortable">
                                                        {result?.data?.items?.map((item, index) => (
                                                            <tr className="ui-sortable-handle" key={index}>
                                                                <th className="">{(index+1) + (result?.data?.currentPage * 25)}</th>
                                                                <td>
                                                                    <LeadStatus item={item} />
                                                                </td>
                                                                <td>{item?.office?.name}</td>
                                                                <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                <td>
                                                                    {item?.applicant_name} {item?.middlename} {item?.lastname} 
                                                                </td>
                                                                <td>
                                                                    <div className="email-mobile flex flex-col">
                                                                        <div>{item.mobile_no}</div>
                                                                        <div>{item.landline_no}</div>
                                                                        <div>{item.whatsapp_no}</div>
                                                                    </div>                                                                    
                                                                </td>
                                                                <td>{item?.visatype?.description}</td>
                                                                <td>{item?.intake?.intake}</td>
                                                                <td>{item?.assignUser?.CounselorAssign?.userdetail?.firstname} {item?.assignUser?.CounselorAssign?.userdetail?.lastname}</td>
                                                                <td>
                                                                    {item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                                </td>
                                                                <td>
                                                                    <ProspectStatusList item={item} />
                                                                </td>
                                                                <td>{item?.refferal?.refferal}</td>
                                                                <td className="flex justify-end">
                                                                    <div className='w-[60px]'>
                                                                        <ApplicantViewButton item={item} />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}												  
                                                    </tbody>
                                                </table>
                                                <PaginationComponent result={result} setCurrentPage={setCurrentPage} currentPage={currentPage} fixed={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>:
                                <div className='flex justify-center mt-16 mb-16 w-full'>
                                    <Empty description={<div className='font-poppins'> No applicants found!</div>} />
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Fragment>
  )
}

export default AssociatesMyClients