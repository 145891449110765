import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import DropDown from "../../Common/DropDown";
import moment from "moment";
import { Checkbox, Loading } from "@nextui-org/react";
import { Empty } from "antd";


const FeePaidReport =()=>{

    const [result, setResult] = useState([]);
    const [dateLimit, setDateLimit] = useState('MONTH');
    const [dateRange, setDateRange] = useState(false)
    const [to, setTo] = useState(new Date())
    const [from, setFrom] = useState(new Date())
    const [loader, setLoader] = useState(false)

    const dateRangePick =(e)=> {
        if(e.target.value === "CUSTOM"){
            setDateRange(true)
            setDateLimit(e.target.value)
        }else{
            setDateRange(false)
            setDateLimit(e.target.value)
        }
    }


    useEffect(()=>{
        feePaidReport.mutateAsync({
            datelimit: dateLimit,
            to: to,
            from: from
        });
    },[dateLimit, to, from]);

    
    const feePaidReport = authQueries.useCEOFeePaidMutation(
        (response) => {
            setResult(response);   
            setLoader(false)     
        }
    );

    const feeCalculate =(fee)=>{
        let feeNew = 0
        fee.forEach(element => {
            feeNew += element.amount
        });

        return feeNew
    }

    const grandTotal =(fee)=> {
        let feeNew = 0
        fee?.forEach(element => {
            let feeApp = 0
            element?.feepaids?.forEach(e => {
                feeApp += e.amount
            });
            feeNew += feeApp
        })

        return feeNew
    }

    return(
        <div>   

        {loader ? 
            <div style={{display:'flex', justifyContent:'center', flexDirection:'column', marginTop:'15%'}}>
            <   Loading  />
                <span style={{marginTop:'10px', textAlign:'center', fontSize:'12px', color:'#888'}}>Loading...</span>
            </div>:
            <div className="">
				<div className="breadcrumb-and-otherbuttons">
					<nav aria-label="breadcrumb">
					  <ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
						<li className="breadcrumb-item active" aria-current="page">Fee Paid clients</li>
					  </ol>
					</nav>
				</div>
				
				<div className="add_clients">
					<div id="accordion" className="add_clients_accordion_form">
						<div  className="card" id="appointments_wrpr_employee">
                            <div className="card-header">
                                Fee Paid Report
                            </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
							<div className="card-body">

                            <div className="row mb-4 filter-report" style={{marginRight: '0px'}}>
                                
                                <div className="col-md-2 pr-0">
                                    <div className="form-group">
                                        <label htmlFor="spouse_name">Month/Week</label>
                                        <select onChange={dateRangePick} defaultValue={dateLimit} className="form-control">
                                            <option value="MONTH">Month</option>
                                            <option value="WEEK">Week</option>
                                            <option value="CUSTOM">Custom</option>
                                        </select>
                                    </div>
                                </div>
                                {
                                    dateRange && 
                                    <Fragment>
                                        <div className="col-md-2 pr-0">
                                            <div className="form-group">
                                                <label htmlFor="spouse_name">From</label>
                                                <input 
                                                defaultValue={moment(from).format('YYYY-MM-DD')} 
                                                onChange={(e)=>{setFrom(e.target.value)}} 
                                                type="date" 
                                                className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2 pr-0">
                                            <div className="form-group">
                                                <label htmlFor="spouse_name">To</label>
                                                <input defaultValue={moment(to).format('YYYY-MM-DD')} onChange={(e)=>{setTo(e.target.value)}} type="date" className="form-control" />
                                            </div>
                                        </div>
                                    </Fragment>
                                }

                            </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                        result?.data?.length > 0 ?
                                            <div className="table-list-search-wrpr">
                                                <div className="row">
                                                    <div className="col-md-6 text-right">
                                                    </div>
                                                </div>                                        
                                                <table border={1} className="table collageprogram">
                                                    <thead>
                                                        <tr>
                                                            <th>Visa Type</th>
                                                            <th>Name</th>
                                                            <th>Mobile</th>
                                                            <th>Email</th>
                                                            <th>Reffered By</th>
                                                            <th>Office</th>
                                                            <th>Status</th>
                                                            <th>Intake</th>
                                                            <th>Registered Date</th>
                                                            {/* <th>Amount</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody  className="ui-sortable">

                                                    {   
                                                        result?.data?.map((item, index) =>(
                                                            <tr className="ui-sortable-handle">
                                                                <td>{item?.visatype?.country?.icon} {item?.visatype?.description} </td>
                                                                <td>{item?.applicant_name} {item?.middlename} {item?.lastname}</td>
                                                                <td>{item?.mobile_no}</td>
                                                                <td>{item.email}</td>
                                                                <td>{item?.refferal?.refferal}</td>
                                                                <td>{item?.office?.name}</td>
                                                                <td>{item?.status_of_prospect?.name}</td>
                                                                <td>{item?.intake?.intake}</td>
                                                                <td>{item?.registered_date && moment(item?.registered_date).format('MMM Do, YYYY')}</td>
                                                                {/* <td>
                                                                    {feeCalculate(item?.feepaids)}
                                                                </td> */}
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                                    {/* <tr style={{border:'transparent'}} className="ui-sortable-handle">
                                                        <td></td>
                                                        <td style={{fontWeight:700, fontSize:'12px'}}>Total</td>
                                                        <td colSpan={7}></td>
                                                        <td style={{fontWeight:700, fontSize:'12px'}}>{grandTotal(result?.data)}</td>
                                                    </tr> */}
                                                </table>
                                            </div>:
                                            <div className="mt-12 mb-24">
                                                <Empty description="No Data found!" />
                                            </div>
                                        }
                                    </div>
								</div>
							</div>
						</div>
                    </div>
				</div>
			    </div>
            </div>
        }
    </div>
    )
    
}
export default FeePaidReport;